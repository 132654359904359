const { merge, mergeAll } = require('ramda');

const { MovieSearchActions } = require('../../shared/enums');

module.exports = (initialState = {
    isFetching: false,
    searchMode: false
}) => {
    return (state = initialState, action = {}) => {
        switch (action.type) {
        case MovieSearchActions.RECEIVE_MOVIE_DATA: {
            if (!action.data) {
                return mergeAll([state, { errors: ['No data received'] }, { isFetching: false }]);
            }

            if (action.data.errors) {
                return mergeAll([state, { errors: action.data.errors }, { isFetching: false }]);
            }

            const newTiles = action.data.tiles.map((tile, i) => {
                return merge(tile, {
                    size: 'extrasmall',
                    index: i
                });
            });

            const data = merge(action.data, { tiles: newTiles });
            return mergeAll([state, data, { isFetching: false }]);
        }
        case MovieSearchActions.ACTIVATE_SEARCH_MODE: {
            return merge(state, { searchMode: true });
        }
        case MovieSearchActions.DEACTIVATE_SEARCH_MODE: {
            return merge(state, { searchMode: false, tiles: [] });
        }
        case MovieSearchActions.REQUEST_MOVIE_DATA: {
            return merge(state, { isFetching: true });
        }
        default: return state;
        }
    };
};
