const R = require('ramda');
const config = require('../../../config');
const { MediaFormat } = require('../../../shared/enums');
const path = require('path');

const generateImagePlaceholder = ({ endpoint, section, alt, mediaFormat = 'cover', roundedWidth }) => {
    const formats = {
        [MediaFormat.COVER]: `${endpoint}/${section}/whiteworld/${alt}/${mediaFormat}/${roundedWidth}`,
        [MediaFormat.HERO]: `${endpoint}/${roundedWidth}`
    };

    return formats[mediaFormat] || `${endpoint}/${section}/whiteworld/${mediaFormat}/${roundedWidth}`;
};

const roundToHundred = number => Math.ceil(number / 100) * 100;

const getFormat = (format = 'jpg', url = '') => format === 'auto' ?
    path.extname(url).slice(path.extname(url).lastIndexOf('.') + 1) : format;

const sourceToHandler = format => {
    return {
        'editorial-pages': {
            initial: sources => `${sources.url}?downsize=320:*&output-format=${sources.format}`,
            resize: (sources, width) => {
                return width < 320 ? `${sources.url}?downsize=320:*&output-format=${sources.format}`
                    : `${sources.url}?downsize=${roundToHundred(width)}:*&output-format=${sources.format}`;
            }
        },
        'akamai-ic': {
            initial: sources => `${sources.url}?downsize=320:*&output-format=${getFormat(format, sources.url)}`,
            resize: (sources, width) => {
                return width < 320 ? `${sources.url}?downsize=320:*&output-format=${getFormat(format, sources.url)}`
                    : `${sources.url}?downsize=${roundToHundred(width)}:*&output-format=${getFormat(format, sources.url)}`;
            }
        },
        'akamai-im': {
            initial: sources => `${sources.url}?imwidth=320`,
            resize: (sources, width) => {
                return width < 320 ? `${sources.url}?imwidth=320` : `${sources.url}?imwidth=${roundToHundred(width)}`;
            }
        },
        'pd-image': {
            initial: sources => {
                return sources.url.indexOf('cover') !== -1 ? `${sources.url}/380` : `${sources.url}/320`;
            },
            resize: (sources, width) => {
                if (!width) {
                    return null;
                }
                if (sources.url.indexOf('cover') !== -1) {
                    const height = width / 0.75;
                    return `${sources.url}/${roundToHundred(height)}`;
                } else {
                    const imageWidth = width > 3100 ? 3100 : roundToHundred(width);
                    return `${sources.url}/${roundToHundred(imageWidth)}`;
                }
            }
        },
        'production-image-placeholder': {
            initial: (sources, skySection, alt, width, mediaFormat = 'cover') => {
                const maxmimumImageSize = 2000;
                const roundedWidth = Math.min(maxmimumImageSize, roundToHundred(width));
                const endpoint = sources.placeholderUrl ? sources.placeholderUrl : config.endpoints.placeHolder;
                return generateImagePlaceholder({
                    endpoint,
                    section: skySection,
                    alt: alt || 'unknown',
                    mediaFormat,
                    roundedWidth
                });
            }
        }
    };
};

function getInitialUrl(sources) {
    if (sources && sources.small) {
        return sources.small;
    } else if (sources && sources.source && sourceToHandler(sources.format)[sources.source]) {
        return sourceToHandler(sources.format)[sources.source].initial(sources);
    } else {
        return null;
    }
}

function getPlaceholderUrl({ sources, skySection, alt, effectiveWidth = document.documentElement.clientWidth, mediaFormat = 'cover' }) {
    return sourceToHandler()[sources.source].initial(sources, skySection, alt, effectiveWidth, mediaFormat);
}

function getResizedUrl(sources, breakpoints = {}, effectiveWidth = document.documentElement.clientWidth) {
    if (!sources || Object.keys(sources).length === 0) {
        return null;
    } else if (sources.source && sourceToHandler(sources.format)[sources.source]) {
        return sourceToHandler(sources.format)[sources.source].resize(sources, effectiveWidth);
    } else {
        const breakpointsArray = R.values(R.mapObjIndexed((breakpoint, size) => ({ breakpoint, size }), breakpoints));
        const sortedBreakpointsArray = R.sortBy(R.prop('breakpoint'))(breakpointsArray);
        const closestBreakpoint = R.find(({ breakpoint, size }) => effectiveWidth <= breakpoint && sources[size])(sortedBreakpointsArray);
        return closestBreakpoint ? sources[closestBreakpoint.size] : sources.large || sources.medium || sources.small;
    }
}

module.exports = {
    getInitialUrl,
    getPlaceholderUrl,
    getResizedUrl
};
