import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment';

import billSnippetActionCreator from 'shared/components/organisms/bill-snippet/actions/bill-snippet-action-creator';
import { getSplitBillingLabel, getBillURLByAccountType, getBillNameFromAccount } from 'shared/components/organisms/bill-snippet/helper';

class BillSnippetDashboard extends React.Component {
    componentDidMount() {
        const { billSnippetData, billSnippetActions } = this.props;
        if (!billSnippetData || !Array.isArray(billSnippetData)) {
            billSnippetActions.requestSplitBillSnippetData();
        }
    }

    renderAction(billingAccount) {
        return (
            <div className="u-margin-bottom-small">
                <a href={getBillURLByAccountType(billingAccount.type)}
                    className="c-link-external"
                    data-tracking-pod={`tile-${this.props.tileIndex}`}
                    data-tracking-context="account-dashboard"
                    data-tracking-theme="bill">
                    View your {getBillNameFromAccount(billingAccount)}
                </a>
            </div>
        );
    }

    renderBillingAccount(billingAccount) {
        const { paymentDueDate } = billingAccount;
        return (
            <React.Fragment>
                <p className="u-text-constrain split-bill__bill-item-name u-margin-bottom-tiny">{getSplitBillingLabel(billingAccount)}</p>
                <h4 className="c-heading-charlie split-bill__bill-item-value u-margin-bottom-small">{moment(paymentDueDate).format('Do MMMM')}</h4>
                {this.renderAction(billingAccount)}
            </React.Fragment>
        );
    }

    render() {
        const billingAccounts = this.props.billSnippetData;
        if (!Array.isArray(billingAccounts) || billingAccounts.length === 0) {
            return null;
        }

        return (
            <div className="u-margin-bottom">
                <h3 className="c-heading-delta qa-split-title">{this.props.title}</h3>
                { billingAccounts.map((billingAccount, idx) => {
                    return (
                        <div className="u-margin-bottom" key={`${billingAccount.type}-${idx}`}>
                            {this.renderBillingAccount(billingAccount)}
                        </div>
                    );
                })}
            </div>
        );
    }
}

const mapStateToProps = ({ billSnippetData }) => {
    return { billData: billSnippetData };
};

const mapDispatchToProps = dispatch => {
    return { billSnippetActions: billSnippetActionCreator(dispatch) };
};

export default connect(mapStateToProps, mapDispatchToProps)(BillSnippetDashboard);
