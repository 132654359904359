import React from 'react';
import { equals } from 'ramda';
import ReactDOM from 'react-dom';
import classNames from 'classnames';
import { ResponsiveImage as PagesLibResponsiveImage } from '@sky-uk/toolkit-react';

import imageSizing from './image-sizing';
import { AllBreakpoints } from 'shared/constants';
import WindowEvents from 'client/utils/window-events';

class ResponsiveImage extends React.Component {
    constructor(props) {
        super(props);

        const initialImageSrc = imageSizing.getInitialUrl(props.sources);

        this.state = {
            imageErrored: false,
            imageSrc: initialImageSrc,
            isUsingPlaceholderImage: false
        };
    }

    componentDidMount() {
        const { srcSet } = this.props;

        if (!this.shouldUseTrueResponsiveImage(srcSet)) {
            this.updateSize();
            WindowEvents.addResizeEndListener(this.resize);
        }
    }

    componentWillUnmount() {
        this.loadingImage = null;
        const { srcSet } = this.props;

        if (!this.shouldUseTrueResponsiveImage(srcSet)) {
            WindowEvents.removeResizeEndListener(this.resize);
        }
    }

    resize = () => {
        this.updateSize();
    };

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (!equals(nextProps.sources, this.props.sources)) {
            this.loadingImage = null;
            this.state.isUsingPlaceholderImage
                ? this.getPlaceholder(nextProps)
                : this.updateSize(nextProps);
        }
    }

    shouldComponentUpdate(nextProps, { imageSrc }) {
        return imageSrc !== '';
    }

    calculateImageSrc = (props) => {
        if (!props.sources) {
            return null;
        }

        const width = this.getEffectiveWidth();

        if (width === 0) {
            return { src: this.state.imageSrc, width: 320 };
        }

        return {
            src: imageSizing.getResizedUrl(
                props.sources,
                AllBreakpoints[props.type || 'tile'],
                width
            ),
            width
        };
    };

    getEffectiveWidth() {
        return ReactDOM.findDOMNode(this).offsetWidth * (window.devicePixelRatio || 1);
    }

    setNewImage(imageSrc, props = this.props, Image = (window && window.Image) || global.window.Image) {
        const isThereACurrentImage = Boolean(this.loadingImage || this.state.imageSrc);
        const isDifferentFromCurrentlyLoadingImage = Boolean(this.loadingImage && imageSrc !== this.loadingImage.src);
        const shouldImageBeReplaced = Boolean(!this.loadingImage) || isDifferentFromCurrentlyLoadingImage;

        if (!isThereACurrentImage) {
            this.setState({ imageSrc });
            return;
        }

        if (shouldImageBeReplaced) {
            this.loadingImage = null;

            const imageLoadedSuccessfully = () => {
                if (this.loadingImage) {
                    this.setState({ imageSrc });
                    this.updateSize(props);
                }
            };
            const imageFailedToLoad = () => {
                if (this.loadingImage) {
                    this.loadingImage = null;
                    this.setState({ imageSrc: '' });
                    this.getPlaceholder();
                }
            };

            this.loadingImage = new Image();
            this.loadingImage.addEventListener('load', imageLoadedSuccessfully);
            this.loadingImage.addEventListener('error', imageFailedToLoad);
            this.loadingImage.src = imageSrc;
        }
    }

    updateSize = (props = this.props) => {
        const newImage = this.calculateImageSrc(props);

        if (!newImage || this.loadingImage && this.loadingImage.width > newImage.width && newImage.width > 320) {
            return;
        }
        this.setNewImage(newImage.src, props);
    };

    getLogoId = ({ channel }) => {
        if (channel) {
            if (channel.logoId) {
                return channel.logoId;
            } else if (channel.id) {
                return channel.id.replace('-', '');
            }
        }
        return null;
    };

    getPlaceholder = (props = this.props) => {
        if (!props.sources) {
            return null;
        }

        const placeholderSource = {
            ...props.sources,
            source: 'production-image-placeholder'
        };
        const newSource = imageSizing.getPlaceholderUrl({
            sources: placeholderSource,
            skySection: this.getLogoId(props),
            alt: props.alt,
            breakpoints: AllBreakpoints[props.type || 'tile'],
            effectiveWidth: this.getEffectiveWidth(),
            mediaFormat: props.mediaFormat
        });

        this.setNewImage(newSource, props);
        this.setState({ isUsingPlaceholderImage: true });
    };

    getClassNames() {
        const { isUsingPlaceholderImage } = this.state;
        const { className, mediaTile = false } = this.props;

        return classNames(className, {
            'image-placeholder': isUsingPlaceholderImage,
            'u-fill-absolute': mediaTile
        });
    }

    shouldUseTrueResponsiveImage(srcSet) {
        return Array.isArray(srcSet);
    }

    renderUsingLegacy({ alt, presentational, onError }) {
        const { imageSrc } = this.state;

        return (
            <img src={imageSrc}
                key={imageSrc}
                alt={alt}
                className={this.getClassNames()}
                onError={onError}
                role={presentational ? 'presentation' : null}
            />
        );
    }

    renderUsingTrueResponsiveImage({ srcSet, src, alt, sizes, className, ariaHidden, fallbackSrc, fallbackSrcSet }) {
        const { imageErrored } = this.state;
        const imageSrc = src || (srcSet.length ? srcSet[srcSet.length - 1].src : '');

        return (
            <PagesLibResponsiveImage
                alt={alt}
                sizes={sizes}
                ariaHidden={ariaHidden}
                cssClassName={className}
                src={imageErrored ? fallbackSrc : imageSrc}
                srcSet={imageErrored ? fallbackSrcSet : srcSet}
                onError={() => this.setState({ imageErrored: true })}
            />
        );
    }

    render() {
        const {
            srcSet,
            onError = () => this.getPlaceholder()
        } = this.props;

        return this.shouldUseTrueResponsiveImage(srcSet) ? this.renderUsingTrueResponsiveImage(this.props) : this.renderUsingLegacy({ ...this.props, onError });
    }
}

export default ResponsiveImage;
