import * as R from 'ramda';
import React from 'react';
import ReactDOM from 'react-dom';
import { withRouter } from 'react-router';

import WindowEvents from 'client/utils/window-events';
import { TileAction, PanelStatus } from 'shared/enums';
import RouteHelper from 'shared/routes/helper';

import Tile from '../tile/tile';
import PanelTemplates from '../panel/templates';

class TileHandler extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            inView: props.panelStatus === PanelStatus.OPEN || props.routingInfo.area === 'search' || props.inView
        };

        this.checkTrigger = this.checkTrigger.bind(this);
        this.transitionToPanel = this.transitionToPanel.bind(this);
    }

    getTileData(sourceTile) {
        const tile = R.clone(sourceTile);

        if (tile.action === TileAction.PANEL) {
            tile.onClick = this.props.customOnClick || this.transitionToPanel;
            tile.url = this.getTileUrl();
        }

        return tile;
    }

    checkTrigger() {
        if (ReactDOM.findDOMNode(this).getBoundingClientRect().top < this.getWindowHeight()) {
            this.activateInView();
        }
    }

    getWindowHeight() {
        return window.innerHeight;
    }

    addActivationListeners() {
        WindowEvents.addContentLoadedListener(this.checkTrigger);
        WindowEvents.addScrollListener(this.checkTrigger);
        WindowEvents.addResizeEndListener(this.checkTrigger);
        WindowEvents.addOrientationChangeListener(this.checkTrigger);
    }

    removeActivationListeners() {
        WindowEvents.removeContentLoadedListener(this.checkTrigger);
        WindowEvents.removeScrollListener(this.checkTrigger);
        WindowEvents.removeResizeEndListener(this.checkTrigger);
        WindowEvents.removeOrientationChangeListener(this.checkTrigger);
    }

    componentDidMount() {
        if (!this.state.inView) {
            this.addActivationListeners();
        }

        const { tile } = this.props;

        if (tile.action === TileAction.PANEL && PanelTemplates[tile.panelTemplate]) {
            PanelTemplates[tile.panelTemplate].preload();
        }

        this.setFocusHandlers();
    }

    setFocusHandlers() {
        const node = ReactDOM.findDOMNode(this);
        const link = node.querySelector('.c-tile__link');
        const tile = node.querySelector('.c-tile');

        if (link) {
            link.focusDelegate = tile;
        }
    }

    componentWillUnmount() {
        this.removeActivationListeners();
    }

    activateInView() {
        this.removeActivationListeners();

        const node = ReactDOM.findDOMNode(this);
        const img = node.querySelector('.tile-media-bg');

        if (img && img.hasAttribute('data-src')) {
            img.setAttribute('src', img.getAttribute('data-src'));
            img.removeAttribute('data-src');
        }

        this.setState({
            inView: true
        });
    }

    transitionToPanel(event) {
        event.preventDefault();

        this.props.router.push(this.getTileUrl());
    }

    getTileUrl() {
        const subpath = RouteHelper.getSubPath(this.props.routingInfo);
        return RouteHelper.getPanelUrl(this.props.routingInfo, subpath, this.props.tile.slug, this.props.routingInfo.params.tab || this.props.tile.gallerySlug);
    }

    isCurrentRouterSlug() {
        return Boolean(this.props.routingInfo.params) && this.props.routingInfo.params.slug === this.props.tile.slug;
    }

    render() {
        return (
            <Tile
                tile={this.getTileData(this.props.tile)}
                index={this.props.index}
                viewMode={this.props.viewMode}
                showLogo={this.props.showLogo}
                inView={this.state.inView}
                noAnim={this.props.panelStatus === PanelStatus.OPEN}
                channelSponsor={this.props.channelSponsor}
                selected={(this.isCurrentRouterSlug() || this.props.selected) && this.props.panelStatus === PanelStatus.OPEN}
                routingInfo={this.props.routingInfo}
                userProfile={this.props.userProfile}
                resultClicked={this.props.resultClicked}
                location={this.props.location}
                experiments={this.props.experiments} />
        );
    }
}

TileHandler.defaultProps = {
    selected: false
};

export default withRouter(TileHandler);
