const get = require('lodash.get');

const config = require('../../../config');
const configHelpers = require('./helpers');
const defaultConfig = require('./default');

const showSkyQ = () => window.location.href.indexOf('/help/home/sky-q') !== -1;

function skyQSelector(classicOption, skyqOption) {
    return () => showSkyQ() ? skyqOption : classicOption;
}

const helpConfig = {
    adapters: {
        adobeAnalytics: {
            maps: {
                populators: {
                    trackingServer: () => get(config, 'endpoints.adobeAnalytics'),
                    trackingServerSecure: () => get(config, 'endpoints.secureAdobeAnalytics'),
                    account: event => configHelpers.helpAccount(get, event),
                    panelName: event => get(event, 'page.custom.panelName'),
                    pageUrl: () => 'D=referrer',
                    channel: () => 'sky/portal/helpcentre/home',
                    userInfo: () => document.cookie.indexOf('skySSO') !== -1 ? 'Logged In' : 'not logged-in',
                    headline: () => 'D=c3',
                    siteName: () => 'sky/portal/helpcentre',
                    contentId: () => 'D=c21',
                    contentType: () => 'home',
                    section0: () => 'sky/portal/helpcentre/home',
                    section1: () => 'sky/portal/helpcentre/home',
                    section2: () => 'sky/portal/helpcentre/home',
                    pageName:
                        skyQSelector('sky/portal/helpcentre/Classic-home-new',
                            'sky/portal/helpcentre/Sky-Q-home-new'),
                    pageConversion:
                        skyQSelector('helpcentre/Classic-home-new',
                            'helpcentre/Sky-Q-home-new'),
                    fullPageDescription:
                        skyQSelector('sky/portal/helpcentre/Classic-home-new',
                            'sky/portal/helpcentre/Sky-Q-home-new'),
                    abTestVariant: event => {
                        const { active } = get(event, 'page.custom.experiments');

                        if (active) {
                            return Object.keys(active).map(key => `${active[key].name}=${active[key].variant}`).join('|');
                        }

                        return false;
                    }
                },
                variables: {
                    audienceManagerBlob: ['audienceManagerBlob'],
                    pageConversion: ['eVar19'],
                    section0: ['prop25', 'eVar26'],
                    section1: ['prop27', 'eVar29'],
                    section2: ['prop31', 'eVar30'],
                    abTestVariant: ['eVar82', 'eVar18']
                }
            }
        }
    }
};

function newConfig() {
    defaultConfig.adapters.adobeAnalytics.maps.populators.contentType = () => 'home';
    return defaultConfig;
}

module.exports = config.featureFlags.newHelpAnalytics ? newConfig() : helpConfig;
