function cookieNoticeHeight() {
    const cookieNoticeElement = document.querySelector('#mast-notifications');
    return cookieNoticeElement ? cookieNoticeElement.offsetHeight : 0;
}

function viewportBottom() {
    return window.scrollY + window.innerHeight;
}

function pageBottom() {
    return document.body.offsetHeight;
}

function footerHeight() {
    const footerElements = document.getElementsByTagName('footer');
    const footer = footerElements.length ? footerElements[0] : null;
    return footer ? footer.clientHeight : 0;
}

function footerTop() {
    return pageBottom() - footerHeight();
}

function elementOffsetFromPageTop(element) {
    const elementProps = element.getBoundingClientRect();
    return elementProps.top + window.scrollY;
}

module.exports = {
    footerTop,
    pageBottom,
    viewportBottom,
    cookieNoticeHeight,
    elementOffsetFromPageTop
};
