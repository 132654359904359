const R = require('ramda');

const { PaymentActions, PanelActions } = require('../../shared/enums');
const MobileAccountStatuses = require('apps/mobile/constants/account-statuses').default;
const { MobileBillPaymentStates, MobileBillChangePaymentStates } = require('apps/bill/enums');

const MAKE_PAYMENT = 'inventory.take.payment';
const CHANGE_PAYMENT = 'inventory.change.paymentMethod';

const initialState = {};

function isInDebt(status, subStatus) {
    return status === MobileAccountStatuses.RESTRICTED && subStatus === MobileAccountStatuses.DEBT;
}

function getPaymentSuccessState({ status, subStatus, reinstateActions }) {
    if (isInDebt(status, subStatus)) {
        if (R.find(action => action.code === MAKE_PAYMENT, reinstateActions)) {
            return MobileBillPaymentStates.SUCCESS_EXTRA_PAYMENT;
        }

        if (R.find(action => action.code === CHANGE_PAYMENT, reinstateActions)) {
            return MobileBillPaymentStates.SUCCESS_CHANGE_PAYMENT;
        }

        return MobileBillPaymentStates.SUCCESS_REINSTATE;
    }

    return MobileBillPaymentStates.SUCCESS;
}

function getChangePaymentMethodSuccessState({ status, subStatus, reinstateActions }) {
    if (isInDebt(status, subStatus)) {
        if (R.find(action => action.code === MAKE_PAYMENT, reinstateActions)) {
            return MobileBillChangePaymentStates.SUCCESS_MAKE_PAYMENT;
        }

        if (R.find(action => action.code === CHANGE_PAYMENT, reinstateActions)) {
            return MobileBillChangePaymentStates.SHOW_FORM;
        }

        return MobileBillChangePaymentStates.SUCCESS_REINSTATE;
    }

    return MobileBillChangePaymentStates.SUCCESS;
}

function managePaymentResult(state, action) {
    const { data = {} } = action;
    const { makePayment = {}, amount = null, currency = 'GBP' } = data;

    if (!makePayment.paymentResult || makePayment.errors) {
        const { status, subStatus } = state;
        const currentState = isInDebt(status, subStatus) ? MobileBillPaymentStates.ERROR_REINSTATE : MobileBillPaymentStates.ERROR;

        return {
            currentState,
            makePayment: {
                success: false,
                amount,
                currency,
                warnings: null,
                errors: ['An error occurred']
            }
        };
    }

    const { status, subStatus, reinstateActions, warnings } = makePayment;

    return {
        currentState: getPaymentSuccessState(makePayment),
        makePayment: {
            success: true,
            amount,
            currency,
            errors: null,
            warnings,
            status,
            subStatus,
            reinstateActions,
            outstandingBalancePaid: R.pathOr(false, ['additionalData', 'fullPaymentMade'], action)
        }
    };
}

function handleRequestPaymentMethod(state, action) {
    if (!action.data || action.data.errors) {
        const { errors } = action.data || {};
        return {
            ...state,
            errors,
            currentState: MobileBillChangePaymentStates.ERROR
        };
    }

    const parties = R.pathOr([], ['data', 'me', 'parties'], action);
    const responseBillingAccounts = R.flatten(R.pluck('billingAccount')(parties));
    const responseBillingAccount = responseBillingAccounts[0];
    const { accountNumber, paymentMethod } = responseBillingAccount;

    return {
        ...state,
        accountNumber,
        paymentMethod,
        currentState: MobileBillChangePaymentStates.SHOW_FORM
    };
}

function handleCreateBankAccount(state, action) {
    if (!action.data || action.data.errors) {
        const { errors } = action.data || {};
        return {
            ...state,
            errors,
            currentState: MobileBillChangePaymentStates.ERROR
        };
    }

    const { currency = 'GBP' } = action.data;

    return {
        ...state,
        currentState: 'change-payment-method-verified-bank-details-state',
        bankAccountDetails: {
            token: action.data.createBankAccount.token,
            currency
        }
    };
}

function handleChangePaymentMethod(state, action) {
    if (!action.data || action.errors) {
        const { errors } = action || {};

        const { status, subStatus } = state;
        const currentState = isInDebt(status, subStatus) ? MobileBillChangePaymentStates.ERROR_REINSTATE : MobileBillChangePaymentStates.ERROR;

        return { ...state, errors, currentState };
    }
    const { data: { changePaymentMethod } = {} } = action || {};
    return {
        ...state,
        ...changePaymentMethod,
        currentState: getChangePaymentMethodSuccessState(changePaymentMethod)
    };
}

function handleRequestReinstateAccount(state, action) {
    const { errors } = action.data || {};

    return { ...state, errors };
}

module.exports = (state = initialState, action) => {
    switch (action.type) {
    case PanelActions.UPDATE_PANEL_STATUS:
        return initialState;
    case PaymentActions.SUBMIT_ONE_OFF_PAYMENT:
        return { ...state, ...managePaymentResult(state, action) };
    case PaymentActions.RESET_PAYMENT_PANEL:
        if (Object.values(MobileBillChangePaymentStates).includes(state.currentState)) {
            return { ...state, currentState: MobileBillChangePaymentStates.SHOW_FORM, errors: null };
        }
        return { ...state, currentState: MobileBillPaymentStates.SHOW_FORM };
    case PaymentActions.PAYMENT_ERROR:
        if (Object.values(MobileBillChangePaymentStates).includes(state.currentState)) {
            return { ...state, currentState: MobileBillChangePaymentStates.ERROR };
        }
        return { ...state, currentState: MobileBillPaymentStates.ERROR };
    case PaymentActions.REQUEST_PAYMENT_METHOD:
        return handleRequestPaymentMethod(state, action);
    case PaymentActions.RESET_CHANGE_PAYMENT_METHOD_STATE:
        return { ...state, currentState: MobileBillChangePaymentStates.SHOW_FORM, subState: MobileBillChangePaymentStates.LOAD_SKYPAY };
    case PaymentActions.CHANGE_PAYMENT_METHOD_UPDATING_STATE:
        return { ...state, currentState: MobileBillChangePaymentStates.SHOW_UPDATING };
    case PaymentActions.PAYMENT_REQUEST_CREATE_BANK_ACCOUNT:
        return handleCreateBankAccount(state, action);
    case PaymentActions.PAYMENT_REQUEST_CHANGE_PAYMENT_METHOD:
        return handleChangePaymentMethod(state, action);
    case PaymentActions.CHANGE_PAYMENT_METHOD_ERROR_STATE:
        return { ...state, currentState: MobileBillChangePaymentStates.ERROR };
    case PaymentActions.CHANGE_PAYMENT_METHOD_LOAD_SKYPAY:
        return { ...state, subState: MobileBillChangePaymentStates.LOAD_SKYPAY };
    case PaymentActions.CHANGE_PAYMENT_METHOD_SHOW_SKYPAY:
        return { ...state, subState: MobileBillChangePaymentStates.SHOW_SKYPAY };
    case PaymentActions.PAYMENT_REQUEST_REINSTATE_ACCOUNT:
        return handleRequestReinstateAccount(state, action);
    default:
        return state;
    }
};
