const R = require('ramda');

const { GenesysChatActions } = require('../../shared/enums');

const initialState = {
    loaded: false,
    failed: true,
    status: 'waiting',
    availabilityCheck: false
};

function handleChatLoading(state, action) {
    return R.merge(state, { loaded: action.status.loaded });
}

function handleChatLoadingFailure(state, action) {
    return R.merge(state, { loaded: action.status.loaded, failed: action.status.failed });
}

function handleChatSetStatus(state, action) {
    return R.merge(state, { status: action.status.status });
}

function handleAvailability(state, action) {
    return R.merge(state, { status: action.status.status, availabilityCheck: action.status.availabilityCheck });
}

module.exports = (state = initialState, action) => {
    switch (action.type) {
    case GenesysChatActions.LOADED:
        return handleChatLoading(state, action);
    case GenesysChatActions.LOADED_FAILED:
        return handleChatLoadingFailure(state, action);
    case GenesysChatActions.SET_STATUS:
        return handleChatSetStatus(state, action);
    case GenesysChatActions.AVAILABILITY:
        return handleAvailability(state, action);
    default:
        return state;
    }
};
