import { combineReducers } from 'redux';

// TODO: Remove when app bundling is introduced
import { getReducers as billSnippetReducers } from 'shared/components/organisms/bill-snippet';
import sharedTwitterReducer from 'shared/reducers/twitter';
import metaDataReducer from 'shared/reducers/meta-data';
import programmesReducer from 'shared/reducers/programmes-reducer';
import boxOfficeReducer from 'shared/reducers/boxoffice';
import { orderReducers } from '@sky-uk/organisms';

const broadbandReducer = require('./broadband-reducer');
const contentApiReducer = require('./content-api-reducer');
const customerAccountReducer = require('./customer-account-reducer');
const galleriesReducer = require('./galleries-reducer');
const genesysChatReducer = require('./genesys-chat-reducer');
const moviesSearchReducer = require('./movies-search-reducer');
const notificationReducer = require('./notification-reducer');
const panelReducer = require('./panel-reducer');
const passthroughReducer = require('./passthrough-reducer');
const paymentReducer = require('./payment-reducer');
const tilesReducer = require('./tiles-reducer');
const viewReducer = require('./view-reducer');
const widgetStateReducer = require('./widget-state-reducer');
const accountReducer = require('../../apps/mysky/reducers/account-reducer').default;

const PagesEngine = require('shared/pages').default;
const PageTemplates = require('../../shared/enums').Templates.Page;


export default state => {
    const {
        accordion = [],
        actionTiles = [],
        adobeTarget,
        banner,
        blackjackHero,
        canonical = '',
        components,
        contentApiData = {},
        customerAccountData = {},
        description = {},
        nosnippet = false,
        experiments,
        features,
        galleries = {},
        helpUserData = {},
        hero,
        footerText = {},
        layout = [],
        metaData = {},
        moviesSearchResults = {},
        page,
        pageTemplate = PageTemplates.DEFAULT,
        programmes = {},
        routeParams,
        search,
        showcases = [],
        staticRouter = false,
        tabs,
        tiles = [],
        tilesAlignment = '',
        title = {},
        config,
        userProfile,
        view = undefined,
        accountData = {},
        ppvEngagementSection = {},
        holding = [],
        watch = {},
        robots = undefined
    } = state;

    return PagesEngine.composeReducers(state, {
        accordion: passthroughReducer(accordion),
        actionTiles: passthroughReducer(actionTiles),
        adobeTarget: passthroughReducer(adobeTarget),
        banner: passthroughReducer(banner),
        ...billSnippetReducers(state),
        blackjackHero: passthroughReducer(blackjackHero),
        broadbandData: broadbandReducer,
        channel: passthroughReducer({ title, description, nosnippet, canonical, robots }),
        components: passthroughReducer(components),
        contentApiData: contentApiReducer(contentApiData),
        customerAccountData: customerAccountReducer(customerAccountData),
        experiments: passthroughReducer(experiments),
        features: passthroughReducer(features),
        galleries: galleriesReducer(galleries),
        genesysChat: genesysChatReducer,
        helpUserData: passthroughReducer(helpUserData),
        hero: passthroughReducer(hero),
        footerText: passthroughReducer(footerText),
        layout: passthroughReducer(layout),
        metaData: metaDataReducer(metaData),
        moviesSearchResults: moviesSearchReducer(moviesSearchResults),
        notification: notificationReducer,
        page: passthroughReducer(page),
        pageTemplate: passthroughReducer(pageTemplate),
        panel: panelReducer,
        paymentData: paymentReducer,
        programmes: programmesReducer(programmes),
        routeParams: passthroughReducer(routeParams),
        search: passthroughReducer(search),
        showcases: passthroughReducer(showcases),
        staticRouter: passthroughReducer(staticRouter),
        tabs: passthroughReducer(tabs),
        tiles: tilesReducer(tiles),
        tilesAlignment: passthroughReducer(tilesAlignment),
        twitter: sharedTwitterReducer,
        config: passthroughReducer(config),
        userProfile: passthroughReducer(userProfile),
        view: viewReducer(view),
        widgetStates: widgetStateReducer,
        accountData: accountReducer(accountData),
        ppvPage: boxOfficeReducer({ ppvEngagementSection, holding }),
        // Add additional reducers as needed, don't blend in the entire organisms
        // reducer unless you really need everything in organisms.
        organisms: combineReducers({
            order: orderReducers
        }),
        watch: passthroughReducer(watch)
    });
};
