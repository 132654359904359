import React from 'react';
import VideoPlayer from '../../../../../../../src/components/video-player/video-player';

export default (props) => {
    const { type, source, poster } = props;

    return (
        <div className="u-margin-bottom-small">
            <VideoPlayer
                id={source.uid}
                type="panel"
                poster={poster.url}
                videoSources={[
                    {
                        type,
                        url: source.url
                    }
                ]}
                controls
                autoplay={false}
                preload="none"
            />

            <p className="u-margin-top-small c-text-smallprint"><a href={source.url}>Play video in browser.</a></p>
        </div>
    );
};
