const utils = require('../utils');
const { easeOutCubic } = require('./easing');
const { cookieNoticeHeight } = require('./positions');

function updateDocumentScrollPosition(position) {
    document.body.scrollTop = document.documentElement.scrollTop = position;
}

module.exports = {
    animateDocumentScroll(from, to, duration) {
        let lastScrollTop = null;
        const initalCookieNoticeHeight = cookieNoticeHeight();
        let startTime;

        const tick = timestamp => {
            startTime = startTime || timestamp;
            const current = timestamp - startTime;

            if (!utils.isTouchDevice() && lastScrollTop !== null && document.body.scrollTop !== lastScrollTop) {
                return;
            }

            const cookieNoticeOffset = cookieNoticeHeight() - initalCookieNoticeHeight;

            if (current > duration) {
                updateDocumentScrollPosition(from + to + cookieNoticeOffset);
                return;
            }

            const position = easeOutCubic(current, from, to, duration);
            updateDocumentScrollPosition(position + cookieNoticeOffset);
            lastScrollTop = document.body.scrollTop;
            window.requestAnimationFrame(tick);
        };

        window.requestAnimationFrame(tick);
    },
    animateElementScroll(element, from, to, duration, callback) {
        let startTime;

        const tick = timestamp => {
            startTime = startTime || timestamp;
            const current = timestamp - startTime;

            if (current > duration) {
                element.scrollTop = from + to;
                return callback();
            }

            const position = easeOutCubic(current, from, to, duration);
            element.scrollTop = position;
            window.requestAnimationFrame(tick);
        };

        window.requestAnimationFrame(tick);
    }
};
