const { ViewModes } = require('../../shared/enums');
const { GridBreakpoints, ScrollLockClassName, MobileHeightBreakpoint } = require('../../shared/constants');

const notEqualTo = text => value => value !== text;

const addClass = (className, element) => {
    element.className = (element.className !== '' ? element.className.split(' ') : [])
        .filter(notEqualTo(className))
        .concat([className])
        .join(' ');
};

const removeClass = (className, element) => {
    element.className = element.className.split(' ')
        .filter(notEqualTo(className))
        .join(' ');
};

const underScoreToCamelCase = str => {
    const matcher = /([a-z]+)_([a-z])/g;
    return str.replace(matcher, (full, p1, p2) => `${p1}${p2.toUpperCase()}`);
};

module.exports = {
    isBrowser() {
        return typeof window !== 'undefined';
    },

    getSponsorToggleClass(channel, channelSponsor, showSponsor) {
        return (channelSponsor === showSponsor) ? ` hide-${channel}` : '';
    },

    postRender(func) {
        if (window.requestAnimationFrame) {
            window.requestAnimationFrame(func);
        } else {
            setTimeout(func, 0);
        }
    },

    getViewMode(window) {
        const width = window.innerWidth;
        const height = window.innerHeight;

        if (width <= GridBreakpoints.MOBILE || height <= MobileHeightBreakpoint) {
            return ViewModes.MOBILE;
        } else if (width <= GridBreakpoints.TABLET) {
            return ViewModes.TABLET;
        } else {
            return ViewModes.DESKTOP;
        }
    },

    isTouchDevice() {
        return ('ontouchstart' in window);
    },

    repeatWithInterval(fn, attempts, interval) {
        for (let i = 1; i <= attempts; i++) {
            setTimeout(fn, i * interval);
        }
    },

    normalizeDate(date) {
        return new Date(Date.parse(date) + new Date(date).getTimezoneOffset() * 60 * 1000);
    },

    addClass,

    removeClass,

    lockScrolling: () => addClass(ScrollLockClassName, document.documentElement),

    unlockScrolling: () => removeClass(ScrollLockClassName, document.documentElement),

    underScoreToCamelCase
};
