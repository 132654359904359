import * as R from 'ramda';

function isSameMonth(date, other) {
    return date.getMonth() === other.getMonth() &&
            date.getYear() === other.getYear();
}

function getToday() {
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    return today;
}

export function getBillURLByAccountType(accountType) {
    if (accountType === 'triplePlay') {
        return '/myaccount/bill';
    }
    return '/mobile-bill';
}

function isPackageActive(pack) {
    return pack && (pack.status === 'ACTIVE' || pack.status === 'AWAITING_ACTIVATION');
}

function getAccountTitleFromProductSet(billingAccounts) {
    const { broadband, talk, tv } = billingAccounts.inventory;
    const conditions = [
        {
            predicate: isPackageActive(broadband) && isPackageActive(talk) && isPackageActive(tv),
            value: 'TV, Broadband & Talk'
        },
        {
            predicate: isPackageActive(broadband) && isPackageActive(talk),
            value: 'Broadband & Talk'
        },
        {
            predicate: isPackageActive(broadband) && isPackageActive(tv),
            value: 'Broadband & TV'
        },
        {
            predicate: isPackageActive(talk) && isPackageActive(tv),
            value: 'TV & Talk'
        },
        {
            predicate: isPackageActive(broadband),
            value: 'Broadband'
        },
        {
            predicate: isPackageActive(talk),
            value: 'Talk'
        },
        {
            predicate: true,
            value: 'TV'
        }
    ];
    return R.find(cond => cond.predicate, conditions).value;
}

export function getPaymentDueLabel(paymentDueDate) {
    const paymentDate = new Date(paymentDueDate);
    const today = getToday();

    if (isSameMonth(today, paymentDate) && today <= paymentDate) {
        return 'Payment date this month';
    }
    return 'Your next bill is due';
}

export function getBillNameFromAccount(billingAccount) {
    if (!billingAccount) {
        return 'Your first bill is due:';
    }

    if (billingAccount.type === 'mobile') {
        return 'Mobile bill';
    }

    return `${getAccountTitleFromProductSet(billingAccount)} bill`;
}


export function getSplitBillingLabel(billingAccount) {
    if (billingAccount.type === 'triplePlay') {
        return `Your ${getBillNameFromAccount(billingAccount)} is due`;
    }
    return (
        `Your ${getBillNameFromAccount(billingAccount)} is due`
    );
}

