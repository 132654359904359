import * as R from 'ramda';
import RouteHelper from 'shared/routes/helper';


const validUuidPattern = /(series|programme):[a-f0-9]{8}-[a-f0-9]{4}-4[a-f0-9]{3}-[89aAbB][a-f0-9]{3}-[a-f0-9]{12}/;
const domain = 'https://www.sky.com';

const getSelfReferentialUrl = (location) => {
    if (location && location.pathname) {
        return `${domain}${location.pathname}`;
    }
};

const getCanonicalUrlForWatchContentTile = ({ season, episode, tile, uuid }) => {
    const area = 'title';
    const location = { pathname: `/watch/title/${uuid.replace(':', '/')}` };
    const hasSeasonAndEpisode = !(R.isNil(season) || R.isNil(episode));
    let pathname;

    if (hasSeasonAndEpisode) {
        pathname = RouteHelper.getIndividualEpisodeUrl({ area, location }, tile.slug, season, episode);
    } else {
        pathname = RouteHelper.getPanelUrl({ area, location }, null, tile.slug);
    }

    return pathname ? `${domain}${pathname}` : null;
};

const getCanonicalUrlForWatchSectionTile = ({ area, season, episode, tile }) => {
    if (season && episode) {
        return RouteHelper.getIndividualEpisodeUrl({ area, channel: tile.brand.id }, tile.slug, season, episode);
    }

    return RouteHelper.getPanelUrl({ area, channel: tile.brand.id }, tile.brand.id, tile.slug);
};

const getCanonicalUrlForFlow = (rootPath, requestPath) => {
    return `${rootPath}/${requestPath.replace(/^\//, '')}`;
};

const isWatchOrHomepage = location => {
    return location && (location.pathname.includes('/watch') || location.pathname === '/');
};

const SEO = {
    getTitle(title, tile, episode = null) {
        let suffix = 'Sky.com';
        const hasValidTile = tile && tile.title && typeof tile.title === 'string';
        if (hasValidTile) {
            if (title) {
                if (title.match(/^Sky Box Sets/)) {
                    suffix = 'Sky Box Sets';
                } else if (title.match(/^Sky Store/)) {
                    suffix = 'Sky Store';
                } else {
                    suffix = 'Sky.com';
                }
            }
            const tileTitle = tile.title.replace(/<[^>]*>/ig, '');
            if (episode) {
                return `${tileTitle} | ${episode} | ${suffix}`;
            } else {
                return `${tileTitle} | ${suffix}`;
            }
        } else {
            return title ? `${title} | Sky.com` : 'Sky.com';
        }
    },

    getCanonicalUrl(requestPath, routerParams, tile, location = '') {
        const { flow, step, season, episode } = routerParams;
        const uuid = tile && tile.uuid;

        if (uuid && validUuidPattern.test(uuid)) {
            return getCanonicalUrlForWatchContentTile({ season, episode, tile, uuid });
        }

        if (tile && tile.area !== 'boxoffice' && tile.brand && tile.brand.id) {
            return getCanonicalUrlForWatchSectionTile({ area: 'watch', season, episode, tile, uuid });
        }

        if (flow && step && requestPath) {
            return getCanonicalUrlForFlow('/help/diagnostics', requestPath);
        }

        if (!requestPath && isWatchOrHomepage(location)) {
            return getSelfReferentialUrl(location) || null;
        }

        return requestPath || null;
    }
};

export default SEO;
