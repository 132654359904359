import * as R from 'ramda';

const findInGallery = (gallery, slug) => {
    return slug ? R.find(tile => tile.slug === slug, gallery.collectionTiles) : null;
};

const findInCollection = (galleries, slug, subSlug) => {
    let collection;

    galleries.forEach(gal => {
        if (gal.collectionList) {
            collection = gal.collectionList.find(panelCollection => panelCollection.slug === slug);
        }
    });

    return collection && subSlug ? findInGallery(collection, subSlug) : collection;
};

const findInGalleries = (page, slug, subSlug) => {
    if (page.galleries && slug) {
        const gallery = page.galleries.find(g => g.slug === slug);

        if (gallery) {
            return findInGallery(gallery, subSlug);
        } else {
            return findInCollection(page.galleries, slug, subSlug);
        }
    } else {
        return null;
    }
};

const findInMovieSearchResults = (page, slug) => {
    if (page.moviesSearchResults.tiles && page.moviesSearchResults.tiles.length > 0) {
        const searchTile = page.moviesSearchResults.tiles.find(tile => tile.slug === slug);

        if (searchTile) {
            return searchTile;
        }
    }
};

const findTile = (page, { slug, subSlug }) => {
    const tile = page.tiles.find(_tile => _tile.slug === slug);

    if (!tile) {
        if (page.id === 'sky-cinema' && page.moviesSearchResults && page.moviesSearchResults.tiles && page.moviesSearchResults.tiles.length > 0) {
            return findInMovieSearchResults(page, slug);
        }

        return findInGalleries(page, slug, subSlug);
    }

    return tile;
};

export {
    findTile
};
