import Loadable from 'react-loadable';
import { Templates } from 'shared/enums';
import DefaultLoadingComponent from './default-loading-component';
import { featureFlags } from 'config';

const { Panel } = Templates;

const templateMap = {
    [Panel.MEDIA]: Loadable({
        loader: () => import(/* webpackChunkName: 'panels-watch' */ './panel-media-template'),
        loading: DefaultLoadingComponent
    }),
    [Panel.BUTTONGROUP]: Loadable({
        loader: () => import(/* webpackChunkName: 'panel-button-group' */ './panel-button-group-template'),
        loading: DefaultLoadingComponent
    }),
    [Panel.ENGAGEMENT]: Loadable({
        loader: () => import(/* webpackChunkName: 'panels-watch' */ './panel-engagement-template'),
        loading: DefaultLoadingComponent
    }),
    [Panel.PIGGYBANK]: Loadable({
        loader: () => import(/* webpackChunkName: 'panels-mobile' */ 'apps/mobile/components/panel/templates/piggybank/panel'),
        loading: DefaultLoadingComponent
    }),
    [Panel.MOBILEACCOUNT]: Loadable({
        loader: () => import(/* webpackChunkName: 'panels-mobile' */ 'apps/mobile/components/panel/templates/account/account'),
        loading: DefaultLoadingComponent
    }),
    [Panel.MOBILEDISABLED]: Loadable({
        loader: () => import(/* webpackChunkName: 'panels-mobile' */ 'apps/mobile/components/panel/templates/disabled'),
        loading: DefaultLoadingComponent
    }),
    [Panel.MOBILEMANAGEDATAMIX]: Loadable({
        loader: () => import(/* webpackChunkName: 'panels-mobile' */ 'apps/mobile/components/panel/templates/manage-data/managedatamix'),
        loading: DefaultLoadingComponent
    }),
    [Panel.MOBILENUMBERPORTING]: Loadable({
        loader: () => import(/* webpackChunkName: 'panels-mobile' */ 'apps/mobile/components/panel/templates/number-porting/numberporting'),
        loading: DefaultLoadingComponent
    }),
    [Panel.MOBILEPIGGYBANK]: Loadable({
        loader: () => import(/* webpackChunkName: 'panels-mobile' */ 'apps/mobile/components/panel/templates/piggybank/piggybank'),
        loading: DefaultLoadingComponent
    }),
    [Panel.MOBILESETTINGS]: Loadable({
        loader: () => import(/* webpackChunkName: 'panels-mobile' */ 'apps/mobile/components/panel/templates/settings/settings'),
        loading: DefaultLoadingComponent
    }),
    [Panel.MOBILEORDERTRACKING]: Loadable({
        loader: () => import(/* webpackChunkName: 'panels-mobile' */ 'apps/mobile/components/panel/templates/order-tracking/ordertracking'),
        loading: DefaultLoadingComponent
    }),
    [Panel.MOBILEDEVICES]: Loadable({
        loader: () =>
            featureFlags.mobile.swapTwo
                ? import(/* webpackChunkName: 'panels-mobile' */ 'apps/mobile/components/panel/templates/devices/panel')
                : import(/* webpackChunkName: 'panels-mobile' */ 'apps/mobile/components/panel/templates/devices-old/panel'),
        loading: DefaultLoadingComponent
    }),
    [Panel.MOVIE]: Loadable({
        loader: () => import(/* webpackChunkName: 'panels-watch' */ './panel-movie-template'),
        loading: DefaultLoadingComponent
    }),
    [Panel.COLLECTION]: Loadable({
        loader: () => import(/* webpackChunkName: 'panels-watch' */ './panel-collection-template'),
        loading: DefaultLoadingComponent
    }),
    [Panel.OFFERS]: Loadable({
        loader: () => import(/* webpackChunkName: 'panel-offers' */ './panel-offers-template'),
        loading: DefaultLoadingComponent
    }),
    [Panel.VIDEOCONTENT]: Loadable({
        loader: () => import(/* webpackChunkName: 'panels-watch' */ './panel-video-content-template'),
        loading: DefaultLoadingComponent
    }),
    [Panel.SPLIT]: Loadable({
        loader: () => import(/* webpackChunkName: 'panel-split' */ './panel-split-template'),
        loading: DefaultLoadingComponent
    }),
    [Panel.ANNOUNCEMENT]: Loadable({
        loader: () => import(/* webpackChunkName: 'panel-announcement' */ './panel-announcement-template'),
        loading: DefaultLoadingComponent
    }),
    [Panel.ACCOUNTDASHBOARD]: Loadable({
        loader: () => import(/* webpackChunkName: 'panels-watch' */ './panel-account-dashboard-template'),
        loading: DefaultLoadingComponent
    }),
    [Panel.CONTENT]: Loadable({
        loader: () => import(/* webpackChunkName: 'panel-content' */ './panel-content-template'),
        loading: DefaultLoadingComponent
    }),
    [Panel.PAYMENT]: Loadable({
        loader: () => import(/* webpackChunkName: 'panels-mobile' */ 'apps/mobile-bill/mobile-bill-old/components/mobile/panel/payment/panel-payment-template'),
        loading: DefaultLoadingComponent
    }),
    [Panel.CHANGEPAYMENT]: Loadable({
        loader: () => import(/* webpackChunkName: 'panels-mobile' */ 'apps/mobile-bill/mobile-bill-old/components/mobile/panel/change-payment-method/panel-change-payment-method-template'),
        loading: DefaultLoadingComponent
    }),
    [Panel.CHANGEDUEDAY]: Loadable({
        loader: () => import(/* webpackChunkName: 'panels-mobile' */ 'apps/mobile-bill/mobile-bill-old/components/mobile/panel/change-payment-due-day/panel-change-payment-due-day-template'),
        loading: DefaultLoadingComponent
    }),
    [Panel.MOBILEBGLINK]: Loadable({
        loader: () => import(/* webpackChunkName: 'panels-mobile' */ './panel-mobile-bg-link-template'),
        loading: DefaultLoadingComponent
    }),
    [Panel.TALKSHIELD]: Loadable({
        loader: () => import(/* webpackChunkName: 'panels-mysky' */ './panel-talkshield-template'),
        loading: DefaultLoadingComponent
    }),
    [Panel.CARDPAIRING]: Loadable({
        loader: () => import(/* webpackChunkName: 'panels-mysky' */ 'apps/mysky/card-pairing/components'),
        loading: DefaultLoadingComponent
    }),
    [Panel.VIEWINGCARDS]: Loadable({
        loader: () => import(/* webpackChunkName: 'panels-mysky' */ 'apps/mysky/tv-pin/components'),
        loading: DefaultLoadingComponent
    }),
    [Panel.PPVEVENTS]: Loadable({
        loader: () => import(/* webpackChunkName: 'panel-ppv-event' */ 'apps/boxoffice/containers/panel'),
        loading: DefaultLoadingComponent
    }),
    [Panel.DEVICEMANAGEMENT]: Loadable({
        loader: () => import(/* webpackChunkName: 'panels-mysky' */ 'apps/mysky/device-management/containers'),
        loading: DefaultLoadingComponent
    })
};

export default templateMap;
