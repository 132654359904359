const helpProductionSuites = [
    'bskybdtmskycomprod',
    'bskybskyhcprod',
    'bskybeexperienceglobal',
    'bskybnetworkglobal',
    'bskybskyhcincl'
].join(',');

const helpDevSuite = 'bskybskyhcincldev';

module.exports = {
    pageName(get, event) {
        const pageBreadcrumb = get(event, 'page.breadcrumb');
        const pageName = get(event, 'page.name');

        if (Array.isArray(pageBreadcrumb) && pageName) {
            return pageBreadcrumb.concat(pageName);
        }

        return [];
    },

    hasPackage(get, pkg) {
        return event => (get(event, 'user.custom.packages') || []).indexOf(pkg) > -1;
    },

    helpAccount(get, event, indexedUrl = 'www.sky.com') {
        const url = get(event, 'page.url.canonical');

        if (url && url.indexOf(indexedUrl) !== -1) {
            return helpProductionSuites;
        } else {
            return helpDevSuite;
        }
    }
};
