import MobileService from '../services/graphql';
import { MobileDeviceActions } from '../constants/action-types';
import SwapActions from '../constants/device-swap-process-actions';
import { endpoints } from 'config';

const { shopUrl } = endpoints;

const mobileDeviceActionCreator = (dispatch, Service = MobileService) => {
    const mobileService = new Service(dispatch);

    const makeMobileServiceCall = (endpointUrl, method, type, additionalRequestData, additionalResponseData) => {
        return mobileService[method](endpointUrl, additionalRequestData).then(data => {
            dispatch({
                type,
                data: { ...data, ...additionalResponseData }
            });
        });
    };

    return {
        requestHandsetsAndAccessories(endpointUrl) {
            return makeMobileServiceCall(endpointUrl, 'getDevices', MobileDeviceActions.REQUEST_DEVICES);
        },
        requestPaymentPlanStatement(endpointUrl, agreementRef) {
            return makeMobileServiceCall(endpointUrl, 'getPaymentPlanStatement', MobileDeviceActions.REQUEST_PAYMENT_PLAN_STATEMENT, agreementRef);
        },
        setHandsetListState() {
            dispatch({ type: MobileDeviceActions.SET_HANDSET_LIST_STATE });
        },
        setAccessoryListState() {
            dispatch({ type: MobileDeviceActions.SET_ACCESSORY_LIST_STATE });
        },
        setCCASummaryState(product) {
            dispatch({ type: MobileDeviceActions.SET_CCA_SUMMARY_STATE, product });
        },
        setAccessoryDetailsState(product) {
            dispatch({ type: MobileDeviceActions.SET_ACCESSORY_DETAILS_STATE, product });
        },
        setHandsetOverviewSummaryState(product) {
            dispatch({ type: MobileDeviceActions.SET_HANDSET_OVERVIEW_SUMMARY_STATE, product });
        },
        setDevicesLoadingState() {
            dispatch({ type: MobileDeviceActions.SET_DEVICES_LOADING_STATE });
        },
        setHandsetsLoadingState() {
            dispatch({ type: MobileDeviceActions.SET_HANDSETS_LOADING_STATE });
        },
        setAccessoriesLoadingState() {
            dispatch({ type: MobileDeviceActions.SET_ACCESSORIES_LOADING_STATE });
        },
        setTCAState(TCAState) {
            dispatch({ type: MobileDeviceActions.SET_TCA_STATE, TCAState });
        },
        reset() {
            dispatch({ type: MobileDeviceActions.RESET });
        },
        signCCA(endpointUrl, agreementNumber, sessionId, agreeToAffordabilityQuestion) {
            return makeMobileServiceCall(endpointUrl, 'postSignCCARequest', MobileDeviceActions.SIGN_CCA, { agreementNumber, threatMetrixSessionId: sessionId, agreeToAffordabilityQuestion });
        },
        requestCCAStatementEmail(endpointUrl, agreementDetails) {
            return makeMobileServiceCall(endpointUrl, 'postRequestAdHocCCAStatement', MobileDeviceActions.REQUEST_CCA_STATEMENT_EMAIL, agreementDetails);
        },
        setCCAStatementEmailLoadingState() {
            dispatch({ type: MobileDeviceActions.SET_CCA_STATEMENT_EMAIL_LOADING_STATE });
        },
        setSwapAnimationState() {
            dispatch({ type: MobileDeviceActions.SET_SWAP_ANIMATION_STATE });
        },
        setSwapWalkthroughState() {
            dispatch({ type: MobileDeviceActions.SET_SWAP_WALKTHROUGH_STATE });
        },
        setSwapAdditionalDamage() {
            dispatch({ type: MobileDeviceActions.SET_SWAP_ADDITIONAL_DAMAGE_STATE });
        },
        setSwapConfirmRejectionState() {
            dispatch({ type: MobileDeviceActions.SET_SWAP_CONFIRMATION_STATE });
        },
        setAccessoriesRefundFormState() {
            dispatch({ type: MobileDeviceActions.SET_ACCESSORIES_REFUND_FORM_STATE });
        },
        setAccessoriesReplaceFormState() {
            dispatch({ type: MobileDeviceActions.SET_ACCESSORIES_REPLACE_FORM_STATE });
        },
        requestNextActions(endpointUrl, returnType, accountNumber, portfolioProductId) {
            return makeMobileServiceCall(endpointUrl, 'requestNextActions', MobileDeviceActions.REQUEST_NEXT_ACTIONS, { accountNumber, portfolioProductId }, { returnType });
        },
        submitAccessoryForReturn(endpointUrl, returnDetails) {
            return makeMobileServiceCall(endpointUrl, 'submitAccessoryForReturn', MobileDeviceActions.SUBMIT_ACCESSORY_FOR_RETURN, returnDetails);
        },
        setAccessoryHelpState() {
            dispatch({ type: MobileDeviceActions.SET_ACCESSORY_HELP_STATE });
        },
        setSwapValuationScheduleState() {
            dispatch({ type: MobileDeviceActions.SET_SWAP_VALUATION_SCHEDULE_STATE });
        },
        swapHandover(portfolioProductId) {
            window.location.assign(`${shopUrl}/mobile/phones?swapphone=${portfolioProductId}`);
        },
        submitDeviceSwapAction(endpointUrl, params) {
            return makeMobileServiceCall(endpointUrl, 'submitDeviceSwapAction', MobileDeviceActions.SUBMIT_DEVICE_SWAP_TO_REDEEM, params);
        },
        setSwapICloudDeviceLockedState() {
            dispatch({ type: MobileDeviceActions.SET_SWAP_ICLOUD_LOCKED_STATE });
        },
        setSwapICloudConfirmUnlockedState() {
            dispatch({ type: MobileDeviceActions.SET_SWAP_ICLOUD_CONFIRM_UNLOCKED_STATE });
        },
        setSwapICloudReprocessingState(endpointUrl, customerProductElementId) {
            return makeMobileServiceCall(endpointUrl, 'submitDeviceSwapAction', MobileDeviceActions.SET_SWAP_ICLOUD_REPROCESSING_STATE, { customerProductElementId, action: SwapActions.REPROCESS });
        },
        setSwapICloudDeviceReturnedState(endpointUrl, customerProductElementId) {
            return makeMobileServiceCall(endpointUrl, 'submitDeviceSwapAction', MobileDeviceActions.SET_SWAP_DEVICE_RETURNED_STATE, { customerProductElementId, action: SwapActions.RETURN });
        },
        setSwapFailedInitialChecks() {
            dispatch({ type: MobileDeviceActions.SET_SWAP_FAILED_INITIAL_CHECKS });
        },
        setSwapFailedInitialChecksReprocess() {
            dispatch({ type: MobileDeviceActions.SET_SWAP_FAILED_INITIAL_CHECKS_REPROCESS });
        },
        setCurrentSwapState(currentSwapState) {
            dispatch({ type: MobileDeviceActions.SET_CURRENT_SWAP_STATE, currentSwapState });
        },
        requestResendPackaging(endpointUrl, accountNumber, customerProductElementId) {
            return makeMobileServiceCall(endpointUrl, 'requestResendPackaging', MobileDeviceActions.REQUEST_RESEND_PACKAGING, { accountNumber, customerProductElementId });
        }
    };
};

export default mobileDeviceActionCreator;
