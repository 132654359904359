import React from 'react';
import { values, map, filter } from 'ramda';

import PagesApplication from 'shared/pages/application';
import PagesApp from 'shared/components/layouts/pages-app';
import { loadApplications } from 'apps';

import { panelRoute } from 'shared/routes';
import { findTile } from 'shared/routes/finders';

class PagesEngine {
    constructor({ manifest = [], apps = {} } = {}) {
        this.manifest = manifest;
        this.apps = apps;

        if (!process.env.WEBPACK_BUILD) {
            this.ensureLoaded();
        }
    }

    ensureLoaded(appNames) {
        if (this.loaded) {
            if (process.env.WEBPACK_BUILD) {
                return Promise.resolve(this);
            } else {
                return this;
            }
        }

        if (!Object.keys(this.apps).length) {
            if (appNames) {
                this.manifest = this.manifest.filter((appName) => {
                    return appNames.includes(appName);
                });
            }

            this.apps = loadApplications(this.manifest);
        }

        if (process.env.WEBPACK_BUILD) {
            return Promise.all(values(this.apps)).then((loadedApps) => {
                Object.keys(this.apps).forEach((appName, index) => {
                    this.apps[appName] = new PagesApplication(appName, loadedApps[index]);
                });

                this.loaded = true;
            });
        } else {
            Object.keys(this.apps).forEach((appName) => {
                this.apps[appName] = new PagesApplication(appName, this.apps[appName]);
            });

            this.loaded = true;
        }
    }

    getApplication(name) {
        if (this.apps[name]) {
            return this.apps[name];
        } else {
            throw new Error(`Application "${name}" was not found in ${Object.keys(this.apps)}`);
        }
    }

    // TODO: Deprecate areas. They are an old concept that need to die.
    getArea(area) {
        if (this.routes[area]) {
            return this.routes[area];
        } else {
            console.warn(`Area "${area}" was not found in ${Object.keys(this.routes)}`);

            return false;
        }
    }

    composeReducers(state, reducers) {
        return Object.keys(this.apps).reduce((reducersList, name) => {
            const app = this.apps[name];

            if (app.reducers) {
                return {
                    ...reducersList,
                    ...app.reducers(state)
                };
            }

            return reducersList;
        }, reducers);
    }

    composeLayouts(layoutsAccumulator) {
        return Object.keys(this.apps).reduce((layouts, name) => {
            const config = this.apps[name].config;

            return {
                ...layouts,
                ...config.layouts
            };
        }, layoutsAccumulator);
    }

    composeRoutes() {
        return Object.keys(this.apps).reduce((allRoutes, appName) => {
            const config = this.apps[appName].config;
            const routesMap = config.routes || {};

            const appRoutes = Object.keys(routesMap).reduce((routes, routeKey) => {
                if (!routes[routeKey].findTile) {
                    routes[routeKey].findTile = findTile;
                }

                return routes;
            }, routesMap);

            return {
                ...allRoutes,
                ...appRoutes
            };
        }, {});
    }

    get routes() {
        if (this._routes) {
            return this._routes;
        }

        this._routes = filter(route => {
            return route.routeComponent || route.app;
        }, this.composeRoutes());

        return this._routes;
    }

    reactRoutes(state) {
        if (this._reactRoutes) {
            return this._reactRoutes;
        }

        this._reactRoutes = values(map(route => {
            const app = this.getApplication(route.app);
            const routeComponent = route.routeComponent || app.getRouteComponent.bind(app);
            const reactRoute = routeComponent(route.path, PagesApp, panelRoute, state);

            return React.cloneElement(reactRoute, {
                app: route.app,
                area: route.area,
                hosts: app.hosts
            });
        }, this.routes));

        return this._reactRoutes;
    }

    getReactRoutesForApps(appNames, hostname, state) {
        const appRoutes = this.reactRoutes(state).filter(route => {
            return appNames.find(name => name === route.props.app);
        });

        return appRoutes.filter(route => {
            if (route.props.hosts.length) {
                return route.props.hosts.find(host => host === hostname);
            }

            return true;
        });
    }

    getPreReqRedirects() {
        return Object.keys(this.apps).map(name => {
            return this.apps[name].getPreReqRedirects();
        }).filter(redirects => redirects);
    }

    getPostReqRedirects() {
        return Object.keys(this.apps).map(name => {
            return this.apps[name].getPostReqRedirects();
        }).filter(redirects => redirects);
    }
}

export default PagesEngine;

