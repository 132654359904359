import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import mapping from './templates';
import templateProvider from '../shared/template-provider';
import { ViewModes, Templates } from 'shared/enums';
import config from 'config';
import utils from 'client/utils';
import WindowEvents from 'client/utils/window-events';

class Hero extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            shown: props.viewMode !== ViewModes.MOBILE
        };
    }

    componentDidMount() {
        this.setState({
            shown: true
        });
    }

    hideHero() {
        this.setState({
            hidden: true
        });
        this.recalculateDomHeight();
        this.recalculatePanelPosition();
    }

    recalculateDomHeight() {
        utils.postRender(WindowEvents.emitContentLoaded.bind(WindowEvents));
    }

    recalculatePanelPosition() {
        utils.postRender(WindowEvents.emitHeroHidden.bind(WindowEvents));
    }

    render() {
        const { heroData = {}, hideOnImageError, routingInfo, viewMode, template, noAnim } = this.props;
        const heroTheme = heroData.theme ? `hero-theme--${heroData.theme}` : null;
        const heroClassNames = classNames('hero', heroTheme, {
            show: this.state.shown,
            'hero-help': routingInfo.area === 'help',
            'hero-mobile-bill': routingInfo.area === 'mobilebill',
            'no-anim': noAnim,
            'sky-cinema': routingInfo.channel === 'sky-cinema' && config.featureFlags.movieSearch,
            'sky-sports': routingInfo.channel === 'sky-sports'
        });

        if (template === Templates.Hero.DEFAULT
            && routingInfo.area !== 'help'
            && viewMode === ViewModes.MOBILE
            && !(this.props.heroData && this.props.heroData.mobile)
            || this.state.hidden) {
            return null;
        }

        const Template = templateProvider(mapping, template);

        if (Template) {
            return (
                <Template
                    {...this.props}
                    onImageError={ hideOnImageError ? this.hideHero.bind(this) : undefined }
                    heroClassNames={heroClassNames}
                />
            );
        }

        return null;
    }
}

Hero.propTypes = {
    template: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.func
    ])
};

export default Hero;
