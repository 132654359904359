import React from 'react';
import { connect } from 'react-redux';

import MobileActionCreator from '../../../actions/mobile-action-creator';
import MobileDeviceActionCreator from '../../../actions/devices-action-creator';
import MobileSimSelector from '../../sim-selector';
import MobileHubNav from './hub-nav';
import config from 'config';
import linearSmartphone from '@sky-uk/skycons/dist/linearSmartphone';
import linearCalendar from '@sky-uk/skycons/dist/linearCalendar';
import simCardIcon from '../../icons/sim-card';
import AccountInfoBlock from 'apps/mysky/components/account-info-block';
import AccountInfoItem from 'apps/mysky/components/account-info-block/account-info-item';

import { BillSnippetManageHeader } from 'shared/components/organisms/bill-snippet';


if (process.env.WEBPACK_BUILD) {
    require('../../../stylesheets/_app.scss');
}

class Mobile extends React.Component {
    componentDidMount() {
        this.props.mobileActions.requestMobileRewardsBalance(config.endpoints.graphql);
    }

    getGreetingData(firstName) {
        return {
            customerName: { value: firstName },
            today: new Date()
        };
    }

    mySkyNavFeatureToggle(showRedesign) {
        return config.featureFlags.mobileMySkyNavigation ? <MobileHubNav showRedesign={showRedesign} /> : null;
    }

    renderRedesignedHeroContent() {
        const { mobileSummaryData, insideMysky, features: { manageBillSnippet = {} } = {} } = this.props;

        const billSnippetEnabled = manageBillSnippet.enabled && manageBillSnippet.showInMobileHeader;

        return (
            <div className="u-margin-bottom">
                <div className="hero-content">
                    <div className="hero-content-inner">
                        { !insideMysky && <div className="c-hero-mysky">
                            <div className="o-container">
                                <p className="u-margin-bottom-none u-text-left">
                                    <span className="c-heading-bravo PII">Welcome {mobileSummaryData.firstName}</span> <br />
                                    <span className="c-heading-charlie">Here is your account summary</span>
                                </p>
                            </div>
                        </div>}
                        { !insideMysky && this.mySkyNavFeatureToggle(true) }

                        <div className="u-text-left">
                            <AccountInfoBlock>
                                {billSnippetEnabled &&
                                    <AccountInfoItem className="account-info-block__unit" iconSource={linearCalendar}>
                                        <BillSnippetManageHeader
                                            accountType="mobile"
                                            showTotal
                                        />
                                    </AccountInfoItem>
                                }
                                <div className="account-info-block__double-unit">
                                    <AccountInfoItem className="account-info-block__unit" numberTypeName="Mobile account number" numbers={[mobileSummaryData.accountNumber]} iconSource={linearSmartphone} />
                                    <AccountInfoItem className="account-info-block__unit" numberTypeName="You’re viewing" suppressPluralisation={true} iconSource={simCardIcon}>
                                        <MobileSimSelector suppressLabels mobileClass="u-margin-bottom-none" />
                                    </AccountInfoItem>
                                </div>
                            </AccountInfoBlock>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    render() {
        if (this.props.isAppView) {
            return (
                <div className="u-text-center u-margin-top-small">
                    <MobileSimSelector className="u-margin-bottom-none" />
                </div>
            );
        }

        return (
            <section className={`hero show hero-mobile ${this.props.insideMysky ? 'inside-mysky' : ''}`} data-tracking-pod="hero">
                {this.renderRedesignedHeroContent()}
            </section>
        );
    }
}

function mapStateToProps({ mobileSummaryData, features }) {
    return {
        mobileSummaryData,
        features
    };
}

function mapDispatchToProps(dispatch) {
    return {
        mobileActions: MobileActionCreator(dispatch),
        mobileDeviceActions: MobileDeviceActionCreator(dispatch)
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Mobile);
