import MobileService from '../services/graphql';
import { MobileDataActions } from 'apps/mobile/constants/action-types';
import MobilePanels from 'apps/mobile/constants/panels';

const MobileActionCreator = (dispatch, Service = MobileService) => {
    const mobileService = new Service(dispatch);

    const makeMobileServiceCall = (endpointUrl, method, type, additionalData) => {
        return mobileService[method](endpointUrl, additionalData).then(data => {
            dispatch({
                type,
                data
            });
        });
    };

    return {
        requestMobilePortfolioAndCatalogue(endpointUrl, serviceInstanceIdentifier, panelId) {
            return mobileService.getMobilePortfolioAndCatalogue(endpointUrl)
                .then(data => {
                    dispatch({
                        type: MobileDataActions.REQUEST_MOBILE_PORTFOLIO_AND_CATALOGUE,
                        data,
                        serviceInstanceIdentifier,
                        panelId
                    });
                });
        },
        requestMobilePortfolioAndCatalogueAndAddMobileProductToBasket(endpointUrl, productId, serviceInstanceIdentifier) {
            this.resetPanel(MobilePanels.MOBILEACCOUNT);
            this.beginPanelLoadingState(MobilePanels.MOBILEACCOUNT);

            return Promise.all([
                mobileService.getMobilePortfolioAndCatalogue(endpointUrl),
                mobileService.postAddMobileProductToBasket(endpointUrl, productId, serviceInstanceIdentifier)])
                .then(([queryData, mutationData]) => {
                    dispatch({
                        type: MobileDataActions.REQUEST_MOBILE_PORTFOLIO_AND_CATALOGUE_AND_ADD_PRODUCT_TO_BASKET,
                        queryData,
                        mutationData,
                        productId,
                        serviceInstanceIdentifier
                    });
                });
        },
        requestMobilePortfolioAndCatalogueWithPiggybank(endpointUrl, serviceInstanceIdentifier, mobileRewardsData, panelId) {
            this.beginPanelLoadingState(MobilePanels.MOBILEMANAGEDATAMIX);

            return mobileService.getMobilePortfolioAndCatalogueWithPiggybank(endpointUrl)
                .then(data => {
                    dispatch({
                        type: MobileDataActions.REQUEST_MOBILE_PORTFOLIO_AND_CATALOGUE_WITH_PIGGYBANK,
                        data,
                        serviceInstanceIdentifier,
                        panelId,
                        mobileRewardsData
                    });
                });
        },
        requestProductCount(endpointUrl, serviceInstanceIdentifier, catalogueProductId) {
            return mobileService.getProductCount(endpointUrl, serviceInstanceIdentifier, catalogueProductId)
                .then(data => {
                    dispatch({
                        type: MobileDataActions.REQUEST_PRODUCT_COUNT,
                        data
                    });
                });
        },
        addMobileProductToBasket(endpointUrl, product, serviceInstanceIdentifier, panelId) {
            return mobileService.postAddMobileProductToBasket(endpointUrl, product.planIdentifier, serviceInstanceIdentifier)
                .then(data => {
                    dispatch({ type: MobileDataActions.ADD_MOBILE_PRODUCT_TO_BASKET, product, panelId, data });
                });
        },
        ceaseMobileMixProduct(endpointUrl, serviceInstanceIdentifier, productIdToCease) {
            return mobileService.postCeaseMobileMixProduct(endpointUrl, serviceInstanceIdentifier, productIdToCease)
                .then(data => {
                    dispatch({ type: MobileDataActions.CEASE_MOBILE_MIX_PRODUCT, data });
                });
        },
        requestMobileSummary(endpointUrl) {
            return makeMobileServiceCall(endpointUrl, 'getMobileSummary', MobileDataActions.REQUEST_MOBILE_SUMMARY);
        },
        requestMobileInventory(endpointUrl) {
            return makeMobileServiceCall(endpointUrl, 'getMobileInventory', MobileDataActions.REQUEST_MOBILE_ACCOUNT_INVENTORY);
        },
        requestMobileOrderTracking(endpointUrl) {
            return makeMobileServiceCall(endpointUrl, 'getMobileOrderTracking', MobileDataActions.REQUEST_MOBILE_ORDER_TRACKING);
        },
        simChanged(sim) {
            dispatch({ type: MobileDataActions.MOBILE_SIM_CHANGED, sim });
        },
        requestMobileRewardsBalance(endpointUrl) {
            return makeMobileServiceCall(endpointUrl, 'getMobileRewardsBalance', MobileDataActions.REQUEST_MOBILE_REWARDS_BALANCE);
        },
        requestMobileRewardsTransactions(endpointUrl) {
            return makeMobileServiceCall(endpointUrl, 'getMobileRewardsTransactions', MobileDataActions.REQUEST_MOBILE_REWARDS_TRANSACTIONS);
        },
        requestMobileRewardsAmountCarriedOver(endpointUrl) {
            return makeMobileServiceCall(endpointUrl, 'getMobileRewardsAmountCarriedOver', MobileDataActions.REQUEST_MOBILE_REWARDS_AMOUNT_CARRIED_OVER);
        },
        selectMobileMixJourney(journey) {
            dispatch({ type: MobileDataActions.SELECT_MOBILE_MIX_JOURNEY, journey });
        },
        confirmProductOrder(endpointUrl, interactionReference, panelId) {
            return mobileService.postConfirmProductOrder(endpointUrl, interactionReference).then(confirmProductOrderData => {
                return mobileService.getNotifications(endpointUrl).then((notificationsData) => {
                    dispatch({ type: MobileDataActions.GET_NOTIFICATIONS, data: notificationsData });
                    dispatch({ type: MobileDataActions.CONFIRM_PRODUCT_ORDER, panelId, data: confirmProductOrderData });
                });
            });
        },
        markMobileMixProductIdToCease(product, productIdToCease) {
            dispatch({ type: MobileDataActions.MARK_MOBILE_MIX_PRODUCT_ID_TO_CEASE, product, productIdToCease });
        },
        resetMobileMix() {
            dispatch({ type: MobileDataActions.RESET_MOBILE_MIX });
        },
        toggleMobileMixOverlay(overlay) {
            dispatch({ type: MobileDataActions.TOGGLE_MOBILE_MIX_OVERLAY, overlay });
        },
        returnToMobileMixProducts() {
            dispatch({ type: MobileDataActions.RETURN_TO_MOBILE_MIX_PRODUCTS });
        },
        returnToMobileMixJourneys() {
            dispatch({ type: MobileDataActions.RETURN_TO_MOBILE_MIX_JOURNEYS });
        },
        beginPanelLoadingState(panelId) {
            dispatch({ type: MobileDataActions.BEGIN_PANEL_LOADING_STATE, panelId });
        },
        beginTileLoadingState(tileId) {
            dispatch({ type: MobileDataActions.BEGIN_TILE_LOADING_STATE, tileId });
        },
        resetPanel(panelId) {
            dispatch({ type: MobileDataActions.MOBILE_RESET_PANEL, panelId });
        },
        mobileWindowResized() {
            dispatch({ type: MobileDataActions.MOBILE_WINDOW_RESIZED });
        },
        viewPiggybankStatement() {
            dispatch({ type: MobileDataActions.VIEW_PIGGYBANK_STATEMENT });
        },
        viewPiggybankSummary() {
            dispatch({ type: MobileDataActions.VIEW_PIGGYBANK_SUMMARY });
        },
        navigatePiggybankStatementRows(direction) {
            dispatch({ type: MobileDataActions.NAVIGATE_PIGGYBANK_STATEMENT_ROWS, direction });
        },
        requestMobileBillNextMobileBill(endpointUrl) {
            return makeMobileServiceCall(endpointUrl, 'requestMobileBillNextMobileBill', MobileDataActions.REQUEST_MOBILE_BILL_NEXT_MOBILE_BILL);
        },
        requestMobileUsage(endpointUrl) {
            return makeMobileServiceCall(endpointUrl, 'getMobileUsage', MobileDataActions.REQUEST_MOBILE_USAGE);
        }
    };
};

export default MobileActionCreator;
