export const StatementActions = {
    NAVIGATE_STATEMENT_ROWS: 'NAVIGATE_STATEMENT_ROWS',
    RECEIVE_STATEMENT_LIST: 'RECEIVE_STATEMENT_LIST'
};
export const NotificationsActions = {
    GET_NOTIFICATIONS: 'GET_NOTIFICATIONS',
    NAVIGATE_NOTIFICATIONS: 'NAVIGATE_NOTIFICATIONS',
    SELECT_SIM: 'SELECT_SIM'
};
export const MobileDataActions = {
    ADD_MOBILE_PRODUCT_TO_BASKET: 'ADD_MOBILE_PRODUCT_TO_BASKET',
    BEGIN_PANEL_LOADING_STATE: 'BEGIN_PANEL_LOADING_STATE',
    BEGIN_TILE_LOADING_STATE: 'BEGIN_TILE_LOADING_STATE',
    CEASE_MOBILE_MIX_PRODUCT: 'CEASE_MOBILE_MIX_PRODUCT',
    CONFIRM_PRODUCT: 'CONFIRM_PRODUCT',
    CONFIRM_PRODUCT_ORDER: 'CONFIRM_PRODUCT_ORDER',
    GET_NOTIFICATIONS: 'GET_NOTIFICATIONS',
    MARK_MOBILE_MIX_PRODUCT_ID_TO_CEASE: 'MARK_MOBILE_MIX_PRODUCT_ID_TO_CEASE',
    MOBILE_BILLING_ID_CHANGED: 'MOBILE_BILLING_ID_CHANGED',
    MOBILE_BUY_ADDON: 'MOBILE_BUY_ADDON',
    MOBILE_CONFIRM_STATE: 'MOBILE_CONFIRM_STATE',
    MOBILE_DEFAULT_STATE: 'MOBILE_DEFAULT_STATE',
    MOBILE_LOADING_STATE: 'MOBILE_LOADING_STATE',
    MOBILE_ORDER_MOBILE_PRODUCT: 'MOBILE_ORDER_MOBILE_PRODUCT',
    MOBILE_RESET_BASKET: 'MOBILE_RESET_BASKET',
    MOBILE_RESET_PANEL: 'MOBILE_RESET_PANEL',
    MOBILE_SIM_CHANGED: 'MOBILE_SIM_CHANGED',
    MOBILE_WINDOW_RESIZED: 'MOBILE_WINDOW_RESIZED',
    ORDER_MOBILE_PRODUCT_COMPLETE: 'ORDER_MOBILE_PRODUCT_COMPLETE',
    ORDER_MOBILE_PRODUCT_FAILED: 'ORDER_MOBILE_PRODUCT_FAILED',
    ORDER_REPLACEMENT_SIM: 'ORDER_REPLACEMENT_SIM',
    REQUEST_MOBILE_ACCOUNT_INVENTORY: 'REQUEST_MOBILE_ACCOUNT_INVENTORY',
    REQUEST_MOBILE_BILL_NEXT_MOBILE_BILL: 'REQUEST_MOBILE_BILL_NEXT_MOBILE_BILL',
    REQUEST_MOBILE_BILL_SUMMARY: 'REQUEST_MOBILE_BILL_SUMMARY',
    REQUEST_MOBILE_HANDSET_ORDER: 'REQUEST_MOBILE_HANDSET_ORDER',
    REQUEST_MOBILE_ORDER_TRACKING: 'REQUEST_MOBILE_ORDER_TRACKING',
    REQUEST_MOBILE_PORTFOLIO_AND_CATALOGUE: 'REQUEST_MOBILE_PORTFOLIO_AND_CATALOGUE',
    REQUEST_MOBILE_PORTFOLIO_AND_CATALOGUE_AND_ADD_PRODUCT_TO_BASKET: 'REQUEST_MOBILE_PORTFOLIO_AND_CATALOGUE_AND_ADD_PRODUCT_TO_BASKET',
    REQUEST_MOBILE_PORTFOLIO_AND_CATALOGUE_WITH_PIGGYBANK: 'REQUEST_MOBILE_PORTFOLIO_AND_CATALOGUE_WITH_PIGGYBANK',
    REQUEST_MOBILE_REWARDS: 'REQUEST_MOBILE_REWARDS',
    REQUEST_MOBILE_REWARDS_AMOUNT_CARRIED_OVER: 'REQUEST_MOBILE_REWARDS_AMOUNT_CARRIED_OVER',
    REQUEST_MOBILE_REWARDS_BALANCE: 'REQUEST_MOBILE_REWARDS_BALANCE',
    REQUEST_MOBILE_REWARDS_TRANSACTIONS: 'REQUEST_MOBILE_REWARDS_TRANSACTIONS',
    REQUEST_MOBILE_SUMMARY: 'REQUEST_MOBILE_SUMMARY',
    REQUEST_MOBILE_USAGE: 'REQUEST_MOBILE_USAGE',
    REQUEST_PRODUCT_COUNT: 'REQUEST_PRODUCT_COUNT',
    RESET_MOBILE_MIX: 'RESET_MOBILE_MIX',
    RETURN_TO_MOBILE_MIX_PRODUCTS: 'RETURN_TO_MOBILE_MIX_PRODUCTS',
    RETURN_TO_MOBILE_MIX_JOURNEYS: 'RETURN_TO_MOBILE_MIX_JOURNEYS',
    SELECT_MOBILE_MIX_JOURNEY: 'SELECT_MOBILE_MIX_JOURNEY',
    TOGGLE_MOBILE_MIX_OVERLAY: 'TOGGLE_MOBILE_MIX_OVERLAY',
    VIEW_PIGGYBANK_STATEMENT: 'VIEW_PIGGYBANK_STATEMENT',
    VIEW_PIGGYBANK_SUMMARY: 'VIEW_PIGGYBANK_SUMMARY'
};
export const MobileNumberPortingActions = {
    REQUEST_MOBILE_NUMBER_PORTING: 'REQUEST_MOBILE_NUMBER_PORTING',
    MOBILE_NUMBER_PORTING_LOADING: 'MOBILE_NUMBER_PORTING_LOADING',
    MOBILE_NUMBER_PORTING_STORE_DETAILS: 'MOBILE_NUMBER_PORTING_STORE_DETAILS',
    MOBILE_NUMBER_PORTING_CONFIRMATION: 'MOBILE_NUMBER_PORTING_CONFIRMATION'
};
export const MobileHandsetActions = {
    REQUEST_HANDSETS: 'REQUEST_HANDSETS',
    REQUEST_PAYMENT_PLAN_STATEMENT: 'REQUEST_PAYMENT_PLAN_STATEMENT',
    SET_ACCESSORY_LIST_STATE: 'SET_ACCESSORY_LIST_STATE',
    SET_HANDSET_LIST_STATE: 'SET_HANDSET_LIST_STATE',
    SET_CCA_SUMMARY_STATE: 'SET_CCA_SUMMARY_STATE',
    SET_OVERVIEW_SUMMARY_STATE: 'SET_OVERVIEW_SUMMARY_STATE',
    SET_LOADING_STATE: 'SET_LOADING_STATE',
    SET_TCA_STATE: 'SET_TCA_STATE',
    SIGN_CCA: 'SIGN_CCA',
    RESET: 'RESET',
    MOBILE_RESET_PANEL: 'MOBILE_RESET_PANEL',
    REQUEST_CCA_STATEMENT_EMAIL: 'REQUEST_CCA_STATEMENT_EMAIL',
    REQUEST_HANDSETS_AND_ACCESSORIES: 'REQUEST_HANDSETS_AND_ACCESSORIES',
    SET_CCA_STATEMENT_EMAIL_LOADING_STATE: 'SET_CCA_STATEMENT_EMAIL_LOADING_STATE',
    SET_SWAP_ANIMATION_STATE: 'SET_SWAP_ANIMATION_STATE'
};
export const MobileDeviceActions = {
    REQUEST_DEVICES: 'REQUEST_DEVICES',
    REQUEST_PAYMENT_PLAN_STATEMENT: 'REQUEST_PAYMENT_PLAN_STATEMENT',
    SET_ACCESSORY_LIST_STATE: 'SET_ACCESSORY_LIST_STATE',
    SET_HANDSET_LIST_STATE: 'SET_HANDSET_LIST_STATE',
    SET_CCA_SUMMARY_STATE: 'SET_CCA_SUMMARY_STATE',
    SET_HANDSET_OVERVIEW_SUMMARY_STATE: 'SET_HANDSET_OVERVIEW_SUMMARY_STATE',
    SET_DEVICES_LOADING_STATE: 'SET_DEVICES_LOADING_STATE',
    SET_HANDSETS_LOADING_STATE: 'SET_HANDSETS_LOADING_STATE',
    SET_ACCESSORIES_LOADING_STATE: 'SET_ACCESSORIES_LOADING_STATE',
    SET_ACCESSORY_DETAILS_STATE: 'SET_ACCESSORY_DETAILS_STATE',
    SET_TCA_STATE: 'SET_TCA_STATE',
    SIGN_CCA: 'SIGN_CCA',
    RESET: 'RESET',
    MOBILE_RESET_PANEL: 'MOBILE_RESET_PANEL',
    REQUEST_CCA_STATEMENT_EMAIL: 'REQUEST_CCA_STATEMENT_EMAIL',
    REQUEST_HANDSETS_AND_ACCESSORIES: 'REQUEST_HANDSETS_AND_ACCESSORIES',
    SET_CCA_STATEMENT_EMAIL_LOADING_STATE: 'SET_CCA_STATEMENT_EMAIL_LOADING_STATE',
    SET_SWAP_ANIMATION_STATE: 'SET_SWAP_ANIMATION_STATE',
    SET_SWAP_WALKTHROUGH_STATE: 'SET_SWAP_WALKTHROUGH_STATE',
    SET_SWAP_ADDITIONAL_DAMAGE_STATE: 'SET_SWAP_ADDITIONAL_DAMAGE_STATE',
    SET_SWAP_CONFIRMATION_STATE: 'SET_SWAP_CONFIRMATION_STATE',
    SET_ACCESSORIES_REFUND_FORM_STATE: 'SET_ACCESSORIES_REFUND_FORM_STATE',
    SET_ACCESSORIES_REPLACE_FORM_STATE: 'SET_ACCESSORIES_REPLACE_FORM_STATE',
    REQUEST_NEXT_ACTIONS: 'REQUEST_NEXT_ACTIONS',
    SUBMIT_ACCESSORY_FOR_RETURN: 'SUBMIT_ACCESSORY_FOR_RETURN',
    SET_ACCESSORY_HELP_STATE: 'SET_ACCESSORY_HELP_STATE',
    SUBMIT_DEVICE_SWAP_TO_REDEEM: 'SUBMIT_DEVICE_SWAP_TO_REDEEM',
    SET_SWAP_ICLOUD_LOCKED_STATE: 'SET_SWAP_ICLOUD_LOCKED_STATE',
    SET_SWAP_ICLOUD_CONFIRM_UNLOCKED_STATE: 'SET_SWAP_ICLOUD_CONFIRM_UNLOCKED_STATE',
    SET_SWAP_ICLOUD_REPROCESSING_STATE: 'SET_SWAP_ICLOUD_REPROCESSING_STATE',
    SET_SWAP_DEVICE_RETURNED_STATE: 'SET_SWAP_DEVICE_RETURNED_STATE',
    SET_SWAP_VALUATION_SCHEDULE_STATE: 'SET_SWAP_VALUATION_SCHEDULE_STATE',
    SET_SWAP_FAILED_INITIAL_CHECKS: 'SET_SWAP_FAILED_INITIAL_CHECKS',
    SET_SWAP_FAILED_INITIAL_CHECKS_REPROCESS: 'SET_SWAP_FAILED_INITIAL_CHECKS_REPROCESS',
    SET_CURRENT_SWAP_STATE: 'SET_CURRENT_SWAP_STATE',
    REQUEST_RESEND_PACKAGING: 'REQUEST_RESEND_PACKAGING',
    SET_SWAP_FEATURED_PRODUCTS_STATE: 'SET_SWAP_FEATURED_PRODUCTS_STATE'
};
export const MobileSettingsActions = {
    REQUEST_MOBILE_SETTINGS: 'REQUEST_MOBILE_SETTINGS',
    MOBILE_SETTINGS_ADULT_CONTENT_FILTER_TOGGLE: 'MOBILE_SETTINGS_ADULT_CONTENT_FILTER_TOGGLE',
    TOGGLE_DATA_ROAMING_CAP: 'TOGGLE_DATA_ROAMING_CAP',
    TOGGLE_PAPER_BILLING: 'TOGGLE_PAPER_BILLING',
    MANAGE_SETTINGS_STATE: 'MANAGE_SETTINGS_STATE',
    MOBILE_SETTINGS_VERIFY_PAYMENT: 'MOBILE_SETTINGS_VERIFY_PAYMENT',
    MOBILE_SETTINGS_VERIFY_PAYMENT_ERROR: 'MOBILE_SETTINGS_VERIFY_PAYMENT_ERROR',
    MOBILE_SETTINGS_VERIFIED_PAYMENT: 'MOBILE_SETTINGS_VERIFIED_PAYMENT',
    UPDATING_SETTINGS_STATE: 'UPDATING_SETTINGS_STATE',
    FETCHING_DATA_STATE: 'FETCHING_DATA_STATE',
    CLIENT_ERROR_STATE: 'CLIENT_ERROR_STATE',
    SHOW_SKY_PAY: 'SHOW_SKY_PAY',
    VERIFYING_PAYMENT: 'VERIFYING_PAYMENT',
    CANCELLING_PAYMENT: 'CANCELLING_PAYMENT',
    REQUEST_APN_SETTINGS: 'REQUEST_APN_SETTINGS',
    RESTRICTED_APN_REQUESTS: 'RESTRICTED_APN_REQUESTS',
    SKYPAY_INVALID: 'SKYPAY_INVALID',
    SKYPAY_CLEAR_ERRORS: 'SKYPAY_CLEAR_ERRORS',
    VIEW_EDIT_SPEND_CAP_PANEL: 'VIEW_EDIT_SPEND_CAP_PANEL',
    VIEW_TURN_OFF_SPEND_CAP_PANEL: 'VIEW_TURN_OFF_SPEND_CAP_PANEL',
    SUBMIT_EDIT_SPEND_CAP_REQUEST: 'SUBMIT_EDIT_SPEND_CAP_REQUEST',
    HANDLE_EDIT_SPEND_CAP_REQUEST: 'HANDLE_EDIT_SPEND_CAP_REQUEST',
    SHOW_SPEND_CAP_LOADING_STATE: 'SHOW_SPEND_CAP_LOADING_STATE',
    UPDATE_SPEND_CAP_VALUE: 'UPDATE_SPEND_CAP_VALUE',
    FRIENDLY_NAME_HANDLE_REQUEST: 'FRIENDLY_NAME_HANDLE_REQUEST',
    FRIENDLY_NAME_HANDLE_CHANGE: 'FRIENDLY_NAME_HANDLE_CHANGE',
    FRIENDLY_NAME_SET_STEP: 'FRIENDLY_NAME_SET_STEP',
    FRIENDLY_NAME_SET_VALIDATION: 'FRIENDLY_NAME_SET_VALIDATION',
    FRIENDLY_NAME_RESET_FORM: 'FRIENDLY_NAME_RESET_FORM'
};
export const PiggybankActions = {
    SET_PANEL_STATE: 'SET_PANEL_STATE',
    REQUEST_REWARDS_BALANCE: 'REQUEST_REWARDS_BALANCE',
    REQUEST_REWARDS_TRANSACTIONS: 'REQUEST_REWARDS_TRANSACTIONS',
    POST_MOBILE_ROLL_DATA: 'POST_MOBILE_ROLL_DATA',
    REQUEST_MOBILE_REWARDS_BALANCE_REDEMPTIONS_ROLL_ADDON: 'REQUEST_MOBILE_REWARDS_BALANCE_REDEMPTIONS_ROLL_ADDON',
    REQUEST_MOBILE_PORTFOLIO_AND_CATALOGUE_WITH_PIGGYBANK: 'REQUEST_MOBILE_PORTFOLIO_AND_CATALOGUE_WITH_PIGGYBANK',
    SET_ROLL_STATE: 'SET_ROLL_STATE',
    SET_ROLL_LOADING_STATE: 'SET_ROLL_LOADING_STATE',
    HANDLE_GET_REWARDS_REDEMPTIONS: 'HANDLE_GET_REWARDS_REDEMPTIONS',
    MOBILE_RESET_PANEL: 'MOBILE_RESET_PANEL',
    REQUEST_GET_BALANCE: 'REQUEST_GET_BALANCE',
    HANDLE_GET_BALANCE: 'HANDLE_GET_BALANCE',
    REQUEST_POST_ROLL: 'REQUEST_POST_ROLL',
    HANDLE_POST_ROLL: 'HANDLE_POST_ROLL',
    HANDLE_GET_ROLL_PRODUCT_ID: 'HANDLE_GET_ROLL_PRODUCT_ID',
    SET_CURRENT_STEP: 'SET_CURRENT_STEP'
};
