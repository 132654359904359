/* eslint-disable no-restricted-modules */
// Please refactor this file to use axios instead of any other HTTP request libraries when you next get the chance to change this file.

const optimizely = require('@optimizely/optimizely-sdk');
const { ConsoleLogHandler } = require('@optimizely/js-sdk-logging');
const request = process.env.WEBPACK_BUILD ? require('xhr') : require('request-promise-native');
const axios = require('axios');

const { removeIdentifiableData } = require('../user-profile-utils');
const optimizelyCache = process.env.WEBPACK_BUILD ? null : require('../../server/services/optimizely-cache');
const config = require('../../config');

module.exports = {
    instance: undefined,
    dataFile: undefined,
    reapplyTimerIdle: undefined,
    pollingTimerId: undefined,

    init() {
        if (process.env.WEBPACK_BUILD) {
            return Promise.resolve();
        }

        return optimizelyCache.read()
            .then(dataFile => {
                this.dataFile = dataFile;
                this.getInstance(true);
            });
    },

    updateFromJson(dataFile) {
        this.dataFile = dataFile;
        return this.getInstance(true);
    },

    update(dataFileUrl) {
        return this.getDataFile(dataFileUrl)
            .then(() => this.getInstance(true));
    },

    getInstance(forceNewInstance = false) {
        if (this.instance && !forceNewInstance) {
            return this.instance;
        }

        return this.instance = optimizely.createInstance({
            datafile: this.dataFile,
            skipJSONValidation: true,
            logger: new ConsoleLogHandler({
                logLevel: config.settings.optimizelyLogLevel
            })
        });
    },

    activateAll(experiments, userProfile) {
        if (!userProfile || !userProfile.uuid) {
            return false;
        }

        const cleanUserProfile = removeIdentifiableData(userProfile);
        const optimizelyInstance = this.getInstance();
        return experiments.reduce((acc, exp) => {
            const variant = optimizelyInstance.activate(exp, cleanUserProfile.uuid, cleanUserProfile);
            return variant !== null ? { ...acc, [exp]: variant } : acc;
        }, {});
    },

    activate(experiment, userProfile) {
        if (!userProfile || !userProfile.uuid) {
            return false;
        }

        return this.getInstance().activate(
            experiment,
            userProfile.uuid,
            removeIdentifiableData(userProfile)
        );
    },

    track(metric, userProfile, value) {
        if (!userProfile || !userProfile.uuid) {
            return false;
        }

        return this.getInstance().track(
            metric,
            userProfile.uuid,
            removeIdentifiableData(userProfile),
            value
        );
    },

    getDataFile(url) {
        if (!url) {
            return Promise.resolve();
        }

        if (process.env.WEBPACK_BUILD) {
            return new Promise((resolve, reject) => {
                request(url, (err, res, body) => {
                    if (err) {
                        return reject(err);
                    }
                    this.dataFile = JSON.parse(body);
                    return resolve(this.dataFile);
                });
            });
        } else {
            return request(url)
                .then(json => {
                    this.dataFile = json;
                    return this.dataFile;
                });
        }
    },

    pushEvent(event) {
        if (window === undefined) {
            throw new Error('The Optmizely util should not be used on the server side.');
        }

        if (event && event.type) {
            window.optimizely = window.optimizely || [];
            window.optimizely.push(event);
        }
    },

    pollAndUpdate({ url, lastModifiedTime = '', logger = () => {} } = {}) {
        let newModifiedTime = lastModifiedTime;
        logger(`Optimizely data file polled - ${new Date()}`, { key: 'app.optimizely.datafile.poll' });

        if (this.pollingTimerId) {
            clearTimeout(this.pollingTimerId);
        }

        return axios.get(url, {
            headers: {
                'If-Modified-Since': lastModifiedTime
            },
            timeout: config.defaultTimeout
        }).catch((err) => {
            if (err && err.response && err.response.status === 304) return;
            logger('Optimizely data file poll failed', { key: 'app.optimizely.datafile.poll' });
        }).then((response) => {
            if (response && response.status === 200) {
                logger('Optimizely data file updated due to change', { key: 'app.optimizely.datafile.poll' });
                newModifiedTime = response.headers['last-modified'];
                this.updateFromJson(response.data);
            }
            return response;
        }).finally(() => {
            this.pollingTimerId = setTimeout(() => {
                this.pollAndUpdate({ url, lastModifiedTime: newModifiedTime, logger });
            }, config.optimizelyRefreshInterval);
        });
    },

    reapply(interval = 10) {
        if (this.reapplyTimerIdle) {
            window.clearTimeout(this.reapplyTimerIdle);
        }

        this.reapplyTimerIdle = window.setTimeout(
            () => this.pushEvent({ type: 'activate' }),
            interval
        );
    }
};
