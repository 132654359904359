const { PanelStatus } = require('../../../shared/enums');
const { PanelOffsetInPixels } = require('../../../shared/constants');
const { animateDocumentScroll } = require('../../../client/animation/scroll');
const animateHeight = require('../../../client/animation/height');

const PanelAnimation = {
    get(panel, callback) {
        const animation = {
            updateStatus(status, keepPreviousSiblingVisible) {
                switch (status) {
                case PanelStatus.OPEN:
                    return animation.openPanel(keepPreviousSiblingVisible);
                case PanelStatus.CLOSED:
                    return animation.closePanel();
                default:
                    break;
                }
            },
            openPanel(keepPreviousSiblingVisible) {
                const height = panel.querySelector('.panel-inner').clientHeight;

                animateHeight(panel, 0, height, 750, callback);

                const to = keepPreviousSiblingVisible ?
                    panel.previousSibling.getBoundingClientRect().top : panel.getBoundingClientRect().top - PanelOffsetInPixels;

                animateDocumentScroll(window.pageYOffset, to, 1000);
            },
            closePanel() {
                const height = panel.querySelector('.panel-inner').clientHeight;

                animateHeight(panel, height, -height, 750, callback);
                const to = panel.previousSibling ? (panel.previousSibling.getBoundingClientRect().top - 20) : 0;
                animateDocumentScroll(window.pageYOffset, to, 1000);
            }
        };

        return animation;
    }
};

module.exports = PanelAnimation;
