const { CustomerAccountActions } = require('../../shared/enums');

module.exports = initialState => (state = initialState, action) => {
    switch (action.type) {
    case CustomerAccountActions.REQUEST_PACKAGE_DETAILS:
        if (action.data instanceof Error) {
            return { ...state, packageData: { error: true } };
        }
        return { ...state, packageData: action.data };
    case CustomerAccountActions.REQUEST_OVERVIEW:
        if (action.data instanceof Error) {
            return { ...state, overviewData: { error: true } };
        }
        return { ...state, overviewData: action.data };
    case CustomerAccountActions.CHANGE_VIEW:
        return { ...state, currentView: action.data.currentView };
    case CustomerAccountActions.REQUEST_REGIONAL_BOUQUETS:
        if (action.data instanceof Error) {
            return { ...state, regionalBouquets: { error: true } };
        }
        return { ...state, regionalBouquets: action.data };
    default:
        return state;
    }
};
