const R = require('ramda');

module.exports = (initialData = {}) => (state = initialData, { type, changedFields, tileIndex, relatedTiles }) => {
    let newState = state;

    if (type === 'UPDATE_TILE' && changedFields && R.not(R.isNil(tileIndex))) {
        const newTile = { ...state[tileIndex], ...changedFields };
        newState = R.update(tileIndex, newTile, state);
    }

    if (type === 'REPLACE_TITLE_PAGE_RELATED_TILES' && relatedTiles) {
        const mainTile = newState[0];
        const formattedTiles = relatedTiles.map((tile, index) => R.merge({ size: 'small', index: index + 1 })(tile));
        newState = [mainTile, ...formattedTiles];
    }

    return newState;
};
