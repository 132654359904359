const jscookie = require('js-cookie');
const R = require('ramda');
const { Countries, Cookies } = require('../../shared/constants');

const parseGeoCookie = (geoCookie, validCountries) => {
    return geoCookie && R.find(country => geoCookie.indexOf(country) !== -1, validCountries);
};

const getCookieValue = (cookies, cookieName) => {
    if (typeof cookies.get === 'function') {
        return cookies.get(cookieName);
    }

    return cookies[cookieName];
};

const cookieList = jscookie.withConverter({
    write: encodeURIComponent
});

module.exports = {
    setClientCountry(country) {
        // Geo cookie is set as '0|GB' in masthead, so we will do the same
        // Customer mathead sets cookie on '.sky.com' - this regex gets the top-level domain to emulate this.
        const domain = window.location.hostname.replace(/(?:.+?\.)?(\w+\.\w+)$/, '.$1');
        domain === '.herokuapp.com' ?
            cookieList.set(Cookies.GEO, `0|${country}`) :
            cookieList.set(Cookies.GEO, `0|${country}`, { domain });
    },
    getCountry(cookies, validCountries = [Countries.GB, Countries.IE]) {
        return cookies &&
            (parseGeoCookie(getCookieValue(cookies, Cookies.GEO), validCountries) ||
             parseGeoCookie(getCookieValue(cookies, Cookies.COUNTRY_AKA), validCountries));
    },
    getAuthToken(cookies) {
        return getCookieValue(cookies, Cookies.SSO_SECURE_TOKEN) ||
               getCookieValue(cookies, Cookies.SSO_LONG_TOKEN);
    },
    getExtendedSessionToken: cookies => getCookieValue(cookies, Cookies.SSO_LONG_TOKEN),
    getSecureSessionToken: cookies => getCookieValue(cookies, Cookies.SSO_SECURE_TOKEN),
    getOAuthToken: cookies => getCookieValue(cookies, Cookies.OAUTH_TOKEN)
};
