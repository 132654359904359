import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';

import billSnippetActionCreator from 'shared/components/organisms/bill-snippet/actions/bill-snippet-action-creator';
import { getBillURLByAccountType, getBillNameFromAccount } from 'shared/components/organisms/bill-snippet/helper';
import { formatCurrency } from 'shared/utils/formatters';
import FirstBill from 'apps/mysky/components/account-info-block/first-bill';

class BillSnippetManageHeader extends React.Component {
    static propTypes = {
        accountType: PropTypes.string
    }

    static defaultProps = {
        accountType: 'triplePlay'
    }

    componentDidMount() {
        const { billSnippetData, billSnippetActions } = this.props;
        if (!billSnippetData || !Array.isArray(billSnippetData)) {
            billSnippetActions.requestBillSnippetData();
        }
    }

    render() {
        const accountInfo = Array.isArray(this.props.billSnippetData) && this.props.billSnippetData.find(billingAccount => billingAccount.type === this.props.accountType);

        if (!accountInfo) {
            return <FirstBill link="/bill" />;
        }

        if (accountInfo.type === 'mobile' && !accountInfo.firstBillIssued) {
            return (<article className="bill-snippet bill-snippet--header">
                <div className="qa-payment-due c-text-body">
                    <p className="u-margin-bottom-none">We're working on your first bill. It will be available soon</p>
                </div>
            </article>);
        }

        const paymentDueDay = moment(accountInfo.paymentDueDate);
        const { tab } = this.props;
        if (tab && tab.slug === 'tv' || (tab && tab.slug !== 'mobile')) {
            return (
                <article className="bill-snippet bill-snippet--header">
                    <div className="qa-bill-wrapper icon-content-pair--bill hide-link-styling c-text-body">
                        <h2 className="qa-bill-snippet-title c-heading-charlie hide-until-medium">Your {getBillNameFromAccount(accountInfo)}</h2>
                        <a href="/bill" className="toggle-element"><h2 className="c-heading-charlie u-margin-right-small">Your {getBillNameFromAccount(accountInfo)}<span><i className="bill-right-arrow-icon c-heading-bravo skycon2-arrow-right"></i></span></h2></a>
                        <p className="qa-payment-due c-text-lead hide-until-medium u-margin-bottom-none">Your next bill is due on: <span className="u-text-bold">{paymentDueDay.format('D')}&nbsp;{paymentDueDay.format('MMM')}</span></p>
                        <p className="c-text-lead toggle-element">Next bill due: <span className="u-text-bold">{paymentDueDay.format('D')}&nbsp;{paymentDueDay.format('MMM')}</span></p>
                        {this.props.showTotal && accountInfo.total >= 0 && <p className="u-margin-bottom-none">Total this month: <strong>{formatCurrency(accountInfo.total, accountInfo.currency)}</strong></p>}
                    </div>
                    <a href="/bill" className="c-btn c-btn--primary hide-until-medium qa-link-to-view-bill u-margin-bottom u-margin-top u-padding-left-large u-padding-right-large">
                        View bill
                    </a>
                </article>
            );
        }

        return (
            <article className="bill-snippet bill-snippet--header">
                <div className="qa-payment-due-section c-text-body">
                    <p className="c-text-lead u-margin-bottom-none">Your next bill is due {paymentDueDay.format('D')}&nbsp;{paymentDueDay.format('MMM')}</p>
                    {this.props.showTotal && accountInfo.total >= 0 && <p className="u-margin-bottom-none">Total this month: <strong>{formatCurrency(accountInfo.total, accountInfo.currency)}</strong></p>}
                </div>
                <a href={getBillURLByAccountType(accountInfo.type)} className="qa-link-to-view-bill">View your bill</a>
            </article>
        );
    }
}

const mapStateToProps = ({ billSnippetData, features }) => {
    return {
        billSnippetData,
        features
    };
};

const mapDispatchToProps = dispatch => {
    return { billSnippetActions: billSnippetActionCreator(dispatch) };
};

export default connect(mapStateToProps, mapDispatchToProps)(BillSnippetManageHeader);
