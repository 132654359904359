const R = require('ramda');

const { PanelStatus, PanelActions } = require('../../shared/enums');

const initialState = {
    status: PanelStatus.CLOSED,
    doResize: false
};

module.exports = (state = initialState, action) => {
    switch (action.type) {
    case PanelActions.UPDATE_PANEL_STATUS:
        return R.merge(state, {
            status: action.status,
            doResize: false
        });

    case PanelActions.PANEL_RESIZE_COMPLETE:
        return R.merge(state, {
            doResize: false
        });
    default:
        return R.merge(state, {
            doResize: true
        });
    }
};
