import * as R from 'ramda';
import { MetaDataActions } from 'shared/enums';

export default (initialState = {}) => (state = initialState, action = { type: undefined, payload: undefined }) => {
    const { type, payload } = action;
    switch (type) {
    case MetaDataActions.UPDATE:
        return R.merge(state, payload);
    default:
        return state;
    }
};
