import * as R from 'ramda';

import { RoutePaths } from 'shared/enums';

// avoid circular dependencies
// if an application requires this file, it would be a circular dependency
// because routes calls the PagesEngine which calls applications,
// which calls this file, which then calls routes,
// so routes calls itself before it has been defined
const getRoutes = () => require('shared/pages').default.routes;

/* gets the subpath contained between the home of the page and the tile(slug) if this is open*/
const getSubPathFromRoutes = routes => {
    if (!routes || routes.length < 2) {
        return false;
    }
    const data = routes.slice(1);
    const tileSlugPosition = R.findIndex(R.propEq('path', ':slug'))(data);

    return data
        .slice(0, tileSlugPosition > -1 ? tileSlugPosition : data.length)
        .filter(item => item.path.indexOf(':') === -1)
        .map(r => r.path).join('/');
};

export default {

    isEpisodesRoute(route) {
        return route.path === RoutePaths.EPISODES;
    },

    isMoreLikeThisRoute(route) {
        return route.path === RoutePaths.MORE_LIKE_THIS;
    },

    determineSelectedTab(routes) {
        if (routes && Array.isArray(routes)) {
            if (routes.some(this.isEpisodesRoute)) {
                return RoutePaths.EPISODES;
            } else if (routes.some(this.isMoreLikeThisRoute)) {
                return RoutePaths.MORE_LIKE_THIS;
            }
        }
        return 'default';
    },

    activePage(routingInfo) {
        const selectedTab = this.determineSelectedTab(routingInfo.routes);
        if (routingInfo.params.subSlug && selectedTab === 'default') {
            return routingInfo.params.subSlug;
        }
        return selectedTab;
    },

    isThresholdRoute(route) {
        return route.path === RoutePaths.THRESHOLD;
    },

    getPanelUrl(routingInfo, subpath, slug, tab) {
        const routes = getRoutes();
        const route = routes[routingInfo.area];

        if (!route || !route.panelUrl) {
            return null;
        }

        return route.panelUrl(routingInfo, subpath, slug, tab);
    },

    getRelatedUrl(routingInfo, slug, tab) {
        return this.getSubSlugUrl(routingInfo, slug, 'more-like-this', tab);
    },

    getSubSlugUrl(routingInfo, slug, page, tab) {
        let slugData = slug;

        if (page) {
            slugData = `${slug}/${page}`;
        }

        return `${this.getPanelUrl(routingInfo, routingInfo.channel, slugData, tab)}`;
    },

    getEpisodesPanelUrl(routingInfo, slug, tab) {
        return this.getSubSlugUrl(routingInfo, slug, 'episodes', tab);
    },

    getIndividualEpisodeUrl(routingInfo, slug, season, episode, tab) {
        return this.getSubSlugUrl(routingInfo, slug, `episodes/season-${season}/episode-${episode}`, tab);
    },

    getFirstPanelPageUrl(routingInfo, slug, pages) {
        if (!Array.isArray(pages) || pages.length <= 0 || !pages[0].tab) {
            return false;
        }

        return this.getSubSlugUrl(routingInfo, slug, pages[0].tab.slug);
    },

    getFirstEpisodeUrl(routingInfo, slug, seasons, tab) {
        if (!Array.isArray(seasons) || seasons.length <= 0) {
            return false;
        }

        const season = seasons[0];

        if (!season.episodes || season.episodes.length <= 0) {
            return false;
        }

        return this.getIndividualEpisodeUrl(routingInfo, slug, season.seasonNumber, season.episodes[0].episode, tab);
    },

    getArea(routerProps) {
        if (routerProps.router && routerProps.router.routes[0].area) {
            return routerProps.router.routes[0].area;
        }

        const routes = getRoutes();
        const routeWithPath = R.find(R.has('path'))(routerProps.routes);

        const matchedRoute = routeWithPath && R.find(R.propEq('path', routeWithPath.path))(R.values(routes));
        return matchedRoute ? matchedRoute.area : null;
    },

    getUrl(paths, params) {
        return Object.keys(params).reduce((accumulator, current) => {
            return accumulator.replace(`:${current}`, params[current]);
        }, paths.join('/')).replace('//', '/').replace(/[()]/gi, '');
    },

    getSubPath(routingInfo) {
        if (routingInfo.area === 'help-articles') {
            return routingInfo.helpArticleSlug;
        }

        return routingInfo.channel || getSubPathFromRoutes(routingInfo.routes);
    },

    getPanelClosePaths(routingInfo) {
        return routingInfo.area !== 'corporate' ?
            [routingInfo.routes[0].path] :
            routingInfo.routes.reduce((accumulator, current, index) => {
                return current.path.indexOf(':slug') === -1 && accumulator.length === index ?
                    accumulator.concat(current.path) : accumulator;
            }, []);
    },

    getProductPanelPackageUrl({ area }, tab, productSlug, packageSlug) {
        return `/${area}/${tab}/${productSlug}/${packageSlug}`;
    }
};
