const parsePolarisDmp = require('@sky-uk/polaris-dmp');
const get = require('lodash.get');
const { merge } = require('ramda');
const defaultConfig = require('./default');
const config = require('../../../config');

const polarisDmp = parsePolarisDmp.parseDocumentCookie(document.cookie);
const mySkyConfig = {
    adapters: {
        adobeAnalytics: {
            maps: {
                populators: merge(defaultConfig.adapters.adobeAnalytics.maps.populators, {
                    trackingServer: () => get(config, 'endpoints.adobeAnalytics'),
                    trackingServerSecure: () => get(config, 'endpoints.secureAdobeAnalytics'),
                    account: () => get(config, 'adobeAnalyticsAccounts.mysky'),
                    siteName: () => 'sky/portal/myaccount',
                    section0: () => 'sky/portal/myaccount',
                    section1: () => 'sky/portal/myaccount/myaccount-homepage',
                    section2: () => 'sky/portal/myaccount/myaccount-homepage',
                    contentType: () => 'home',
                    pageName: event => {
                        if (event.page.name !== 'unknown') {
                            return `myaccount:${event.page.breadcrumb.map(el => el === 'mysky' ? 'myaccount' : el).join(':')}:${event.page.name === 'mysky' ? 'myaccount' : event.page.name}`;
                        } else {
                            return `myaccount:${event.page.breadcrumb.map(el => el === 'mysky' ? 'myaccount' : el).join(':')}`;
                        }
                    },
                    channel: () => 'sky/portal/myaccount/myaccount-homepage',
                    fullPageDescription: () => 'sky/portal/myaccount/myaccount-homepage-supercharge',
                    ethan: () => polarisDmp.getEthanStatus(),
                    visitNumber: () => 'D=c69',
                    newOrRepeat: () => 'D=c70',
                    itemClickedVariableName: event => get(event, 'element.custom.itemClickedVariableName'),
                    offerList: event => get(event, 'page.custom.offerList'),
                    upsellTab: event => {
                        if (event.type === 'elementRendered' && event.page.custom.elementData.config && event.page.custom.elementData.config.upsell) {
                            return `upsell-${event.page.custom.elementData.slug}`;
                        }
                    }
                }),
                variables: merge(defaultConfig.adapters.adobeAnalytics.maps.variables, {
                    section0: ['prop25', 'eVar26'],
                    section1: ['prop27', 'eVar29'],
                    section2: ['prop31', 'eVar30'],
                    ethan: ['eVar54'],
                    visitNumber: ['prop69', 'eVar69'],
                    newOrRepeat: ['prop70', 'eVar70'],
                    offerList: ['list2'],
                    upsellTab: ['eVar53'],
                    itemClickedVariableName: ['prop53']
                }),
                events: merge(defaultConfig.adapters.adobeAnalytics.maps.events, {})
            }
        }
    }
};

module.exports = mySkyConfig;
