export default {
    SHOW_FORM: 'change-payment-method-show-form-state',
    SUCCESS_MAKE_PAYMENT: 'change-payment-method-success-make-payment-state',
    SUCCESS_REINSTATE: 'change-payment-method-success-reinstate-state',
    SUCCESS: 'change-payment-method-success-state',
    ERROR: 'change-payment-method-error-state',
    ERROR_REINSTATE: 'change-payment-method-error-reinstate-state',
    SHOW_UPDATING: 'change-payment-method-show-updating-state',
    LOAD_SKYPAY: 'change-payment-method-load-skypay',
    SHOW_SKYPAY: 'change-payment-method-show-skypay',
    VERIFIED_BANK_DETAILS: 'change-payment-method-verified-bank-details-state'
};
