import graphqlService from 'apps/mobile-bill/mobile-bill-old/services/mobile-graphql-service';
import * as triplePlayBillQueries from 'apps/mobile-bill/mobile-bill-old/services/queries/triple-play-bill-queries';
import mobileBillQueries from 'apps/mobile-bill/mobile-bill-old/services/queries/mobile-bill-queries';
import sharedQueries from 'apps/mobile-bill/mobile-bill-old/services/queries/shared-queries';

import config from 'config';

const BILL_GRAPHQL_TOKEN = config.tokens.bill.graphql;

const BillService = {
    getMobileBillListAndNextMobileBill(service = graphqlService) {
        return service.executePost(mobileBillQueries.mobileBillListAndNextMobileBill, BILL_GRAPHQL_TOKEN, 'MobileBillListAndNext');
    },

    getMobileBillListAndLastMobileBill(service = graphqlService) {
        return service.executePost(mobileBillQueries.mobileBillListAndLastMobileBill, BILL_GRAPHQL_TOKEN, 'MobilBillListAndLast');
    },

    getMobileBill(billingId, service = graphqlService) {
        return service.executePost(mobileBillQueries.mobileBill, BILL_GRAPHQL_TOKEN, 'MobileBill', { billingId });
    },

    getMobileAccountStatus(service = graphqlService) {
        return service.executePost(mobileBillQueries.mobileAccountStatus, BILL_GRAPHQL_TOKEN, 'MobileBillAccountStatus');
    },

    getCurrentPaymentDueDay({ accountNumber }, service = graphqlService) {
        return service.executePost(mobileBillQueries.currentPaymentDueDay, BILL_GRAPHQL_TOKEN, 'getCurrentPaymentDueDate', { accountNumber });
    },

    postChangePaymentDueDay({ accountNumber, eTag, dueDay }, service = graphqlService) {
        return service.executePost(mobileBillQueries.changePaymentDueDay, BILL_GRAPHQL_TOKEN, 'ChangePaymentDueDay', { accountNumber, eTag, dueDay });
    },

    toggleMobilePaperBilling({ eTag, accountNumber, paperBillingEnabled }, service = graphqlService) {
        const variables = {
            eTag,
            account: {
                accountNumber,
                billingPreferences: {
                    sendPaperBill: paperBillingEnabled
                }
            }
        };
        return service.executePost(triplePlayBillQueries.changeBillingAccountMutation, BILL_GRAPHQL_TOKEN, 'MobileChangePaperBilling', variables);
    },

    getTriplePlayBill(service = graphqlService) {
        return service.executePost(triplePlayBillQueries.initialFetchQuery, BILL_GRAPHQL_TOKEN);
    },

    getTripleplayBillForId({ accountNumber, billingId }, service = graphqlService) {
        return service.executePost(triplePlayBillQueries.tripleplayBillForId, BILL_GRAPHQL_TOKEN, 'TripleplayBillForId', { accountNumber, billingId });
    },

    getPredictedBills({ accountNumber }, service = graphqlService) {
        return service.executePost(triplePlayBillQueries.getPredictedBills, BILL_GRAPHQL_TOKEN, 'getPredictedBills', { accountNumber });
    },

    changeBillingAccountAlias({ eTag, accountNumber, alias }, service = graphqlService) {
        const variables = {
            eTag,
            account: {
                accountNumber,
                alias
            }
        };
        return service.executePost(triplePlayBillQueries.changeBillingAccountMutation, BILL_GRAPHQL_TOKEN, 'updateCustomerAccount', variables);
    },

    checkLogin(service = graphqlService) {
        return service.executePost(triplePlayBillQueries.checkLoggedInQuery, BILL_GRAPHQL_TOKEN);
    },

    getPaperlessBillingSettings({ accountNumber }, service = graphqlService) {
        const variables = {
            accountNumber
        };
        return service.executePost(triplePlayBillQueries.getPaperlessBillSettings, BILL_GRAPHQL_TOKEN, 'getPaperlessBillSettings', variables);
    },

    setPaperlessBillingSettings({ eTag, accountNumber, paperBillingEnabled }, service = graphqlService) {
        const variables = {
            eTag,
            account: {
                accountNumber,
                billingPreferences: {
                    sendPaperBill: paperBillingEnabled
                }
            }
        };
        return service.executePost(triplePlayBillQueries.changeBillingAccountMutation, BILL_GRAPHQL_TOKEN, 'updateCustomerAccount', variables);
    },

    getCallsForBillingId({ accountNumber, billingId }, service = graphqlService) {
        return service.executePost(triplePlayBillQueries.getCallsForBillingId, BILL_GRAPHQL_TOKEN, 'getCallsForBillingId', { accountNumber, billingId });
    },
    getMobileBillNextMobileBill(service = graphqlService) {
        return service.executePost(mobileBillQueries.mobileBillNextMobileBill, BILL_GRAPHQL_TOKEN, 'MobileBillNext');
    },

    getCurrentPaymentMethod({ accountNumber }, service = graphqlService) {
        return service.executePost(sharedQueries.getCurrentPaymentMethod, BILL_GRAPHQL_TOKEN, 'getCurrentPaymentMethod', { accountNumber });
    },
    postMakePayment(variables, service = graphqlService) {
        return service.executePost(sharedQueries.makePayment, BILL_GRAPHQL_TOKEN, 'MakePayment', variables)
            .then(data => ({ data: { ...data.data, amount: variables.amount, currency: variables.currency } }));
    },
    postCreateBankAccount(bankDetails, service = graphqlService) {
        const variables = {
            bankDetails
        };

        return service.executePost(sharedQueries.createBankAccount, BILL_GRAPHQL_TOKEN, 'CreateBankAccount', variables);
    },
    postChangePaymentMethod(paymentMethodDetails, service = graphqlService) {
        const { accountNumber, token: paymentMethodId, accountType } = paymentMethodDetails;
        const variables = {
            accountNumber,
            paymentMethodId,
            accountType
        };

        return service.executePost(sharedQueries.changePaymentMethod, BILL_GRAPHQL_TOKEN, 'ChangePaymentMethod', variables);
    },
    postReinstateAccount({ accountNumber, restrictionType }, service = graphqlService) {
        const variables = {
            accountNumber,
            restrictionType
        };

        return service.executePost(sharedQueries.reinstateAccount, BILL_GRAPHQL_TOKEN, 'ReinstateAccount', variables);
    },

    getCallsForPredictedBill({ accountNumber }, service = graphqlService) {
        return service.executePost(triplePlayBillQueries.getCallsForPredictedBill, BILL_GRAPHQL_TOKEN, 'getCallsForPredictedBill', { accountNumber });
    },

    getReinstateActions({ accountNumber }, service = graphqlService) {
        return service.executePost(triplePlayBillQueries.getReinstateActions, BILL_GRAPHQL_TOKEN, 'getReinstateActions', { accountNumber });
    },

    attemptReinstateAccount({ accountNumber }, service = graphqlService) {
        return service.executePost(triplePlayBillQueries.attemptReinstateCustomer, BILL_GRAPHQL_TOKEN, 'reinstateAccount', { accountNumber });
    },

    getCallChargeAlertThreshold({ accountNumber }, service = graphqlService) {
        return service.executePost(triplePlayBillQueries.getCallChargeAlertThreshold, BILL_GRAPHQL_TOKEN, 'getCallChargeAlertThreshold', { accountNumber });
    },

    updateCallChargeAlertThreshold({ accountNumber, amount }, service = graphqlService) {
        const variables = {
            accountNumber,
            amount
        };

        return service.executePost(triplePlayBillQueries.updateCallChargeAlertThreshold, BILL_GRAPHQL_TOKEN, 'updateCallChargeAlertThreshold', variables);
    }
};

export { BillService as default };
