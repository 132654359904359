/* eslint-disable no-restricted-modules */
// Please refactor this file to use axios instead of any other HTTP request libraries when you next get the chance to change this file.

const requestLibrary = process.env.WEBPACK_BUILD ? require('axios') : require('request-promise-native');

module.exports = request => {
    return new Promise((resolve, reject) => {
        requestLibrary(request).then((response) => {
            return resolve(process.env.WEBPACK_BUILD ? response.data : response);
        }).catch(err => {
            return reject(new Error(err));
        });
    });
};
