/* eslint-disable no-restricted-modules */
// Please refactor this file to use axios instead of any other HTTP request libraries when you next get the chance to change this file.

import graphQLService from 'client/services/graphql-service';

const xmlHttpReq = require('xhr');

const mobileGraphQLService = {
    ...graphQLService,

    query(request, xhr = xmlHttpReq) {
        return new Promise((resolve, reject) => { // eslint-disable-line promise/avoid-new
            xhr(request, (error, response, body) => {
                if (response.statusCode === 401) {
                    if (window && window.location) {
                        window.location.href = `https://www.sky.com/signin?area=mysky&successUrl=${encodeURIComponent(window.location.href)}`;
                        return reject(error);
                    }
                }

                if (error || !(response.statusCode >= 200 && response.statusCode <= 299) || !body) {
                    const errorToReturn = new Error(`GraphQL Request failed - response code ${response && response.statusCode}, response body: ${(response && response.body) && response.body}`);
                    errorToReturn.details = response;
                    return reject(error || errorToReturn);
                }

                resolve(JSON.parse(body));
            });
        });
    }
};

export default mobileGraphQLService;
