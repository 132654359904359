import React from 'react';
import { Route } from 'react-router';

import { RoutePaths } from 'shared/enums';
import Panel from '../../src/components/panel/panel';

// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Object/watch
if (Object.prototype.watch) {
    delete Object.prototype.watch;
}

const getEpisodes = () => (
    <Route path={RoutePaths.EPISODES}>
        <Route path={RoutePaths.SEASON}>
            <Route path={RoutePaths.EPISODE} />
        </Route>
    </Route>
);

const panelRoute = (
    <Route path={RoutePaths.PANEL} component={Panel}>
        <Route path={RoutePaths.SUB_SLUG}>
            <Route path={RoutePaths.MORE_LIKE_THIS} />
            <Route path={RoutePaths.MORE_INFO} />
            { getEpisodes() }
        </Route>
        { getEpisodes() }
        <Route path={RoutePaths.THRESHOLD} />
    </Route>
);

export {
    panelRoute
};
