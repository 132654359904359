import * as R from 'ramda';
import { BillSnippetActions } from 'shared/enums';

function getToday() {
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    return today;
}

function getCorrectedPaymentDueDateFromBill(latestBill, billingAccount) {
    const paymentDate = new Date(latestBill.paymentDueDate);
    const statementDate = new Date(latestBill.statementDate);
    const today = getToday();
    const waitingForStatement = statementDate < today && paymentDate < today;

    if (waitingForStatement) {
        paymentDate.setMonth(paymentDate.getMonth() + 1);
        paymentDate.setDate(R.pathOr(paymentDate.getDate(), ['preferences', 'paymentDueDay', 'currentDay'], billingAccount));
    }

    return paymentDate.toISOString();
}

function handleRequestBillSnippetData(state, action) {
    const billingAccounts = [].concat(
        R.pathOr([], ['data', 'data', 'me', 'billSnippetAccounts'], action),
        R.pathOr([], ['data', 'data', 'me', 'mobileSnippetAccounts'], action),
    );

    return billingAccounts
        .filter((item, pos, self) => self.findIndex(billingAccount => billingAccount.type === item.type) === pos)
        .reverse()
        .map((billingAccount) => {
            const latestBill = R.pathOr(null, ['bills', 0], billingAccount);
            if (!latestBill) {
                return {};
            }
            return {
                type: billingAccount.type,
                currency: billingAccount.currency,
                paymentDueDate: billingAccount.type === 'mobile' ? latestBill.paymentDueDate : getCorrectedPaymentDueDateFromBill(latestBill),
                statementDate: latestBill.statementDate,
                total: latestBill.total,
                firstBillIssued: latestBill.firstBillIssued,
                // The below is only set when using REQUEST_SPLIT_BILL_SNIPPET_DATA
                inventory: billingAccount.inventory
            };
        });
}

export default (initialState = null) => (state = initialState, action) => {
    switch (action.type) {
    case BillSnippetActions.REQUEST_BILL_SNIPPET_DATA:
    case BillSnippetActions.REQUEST_SPLIT_BILL_SNIPPET_DATA:
        return handleRequestBillSnippetData(state, action);
    default:
        return state;
    }
};
