const R = require('ramda');

function getEvent(event) {
    return event.nativeEvent ? event.nativeEvent : event;
}

function isEscapeKey(event) {
    const evt = getEvent(event);
    return evt.keyCode === 27 || evt.key === 'Escape';
}

function isEnterKey(event) {
    const evt = getEvent(event);
    return evt.keyCode === 13 || evt.key === 'Enter';
}

function isSpaceBar(event) {
    const evt = getEvent(event);
    return evt.keyCode === 32 || evt.key === ' ';
}

function onEnterKey(wrappedFunction) {
    return event => {
        if (this.isEnterKey(event)) {
            wrappedFunction();
        }
    };
}

const modifiers = ['Alt', 'Control', 'Meta', 'Shift'];

function isModified(event) {
    return R.any(mod => event.getModifierState(mod), modifiers);
}

const KeypressEvents = {
    isEscapeKey,
    isSpaceBar,
    isEnterKey,
    onEnterKey,
    isModified
};

module.exports = KeypressEvents;
