const defaultConfig = require('./default');

module.exports = {
    adapters: {
        adobeAnalytics: {
            maps: {
                populators: {
                    ...defaultConfig.adapters.adobeAnalytics.maps.populators,
                    notification(event) {
                        if (event.type === 'elementRendered' && event.page.custom.elementType === 'mobileNotification') {
                            return `mobile-notification-${event.page.custom.elementData}`;
                        }
                    },
                    elementRendered(event) {
                        if (event.type === 'elementRendered' && event.page.custom.elementType === 'mobileSwapState') {
                            return `mobile-swap-state-${event.page.custom.elementData}`;
                        } else if (event.type === 'elementRendered' && event.page.custom.elementType === 'PBR') {
                            return `PBR-${event.page.custom.elementData}`;
                        }
                    },
                    errors(event) {
                        if (event.type === 'elementRendered' && event.page.custom.elementType === 'errors') {
                            return `error-message-${event.page.custom.elementData}`;
                        }
                    }
                },
                variables: {
                    notification: ['eVar86'],
                    elementRendered: ['eVar82'],
                    errors: ['eVar2']
                }
            }
        }
    }
};
