import React from 'react';

export default ({ link }) => (
    <article className="bill-snippet bill-snippet--header">
        <div className="qa-bill-wrapper icon-content-pair--bill hide-link-styling c-text-body">
            <h2 className="qa-bill-snippet-title c-heading-charlie hide-until-medium">Your first bill is due: </h2>
            <a href={link} className="toggle-element"><h2 className="c-heading-charlie u-margin-right-small">Your first bill is due: <span><i className="bill-right-arrow-icon c-heading-bravo skycon2-arrow-right"></i></span></h2></a>
            <p className="qa-payment-due c-text-lead hide-until-medium u-margin-bottom-none u-text-bold">14 days after installation</p>
            <p className="c-text-lead toggle-element u-text-bold">14 days after installation</p>
        </div>
        <a href={link} className="c-btn c-btn--primary qa-link-to-view-bill u-margin-bottom u-margin-top u-padding-left-large u-padding-right-large">
            How your first bill works
        </a>
    </article>
);
