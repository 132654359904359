const { omit, reject, isNil } = require('ramda');

const removeData = (userProfile, keysToRemove = []) => {
    return reject(isNil, omit(keysToRemove, userProfile));
};

const removeAuthenticationData = userProfile => {
    return removeData(userProfile, [
        'sessionToken',
        'secureSessionToken',
        'extendedSessionToken',
        'oAuthToken'
    ]);
};

const removeIdentifiableData = userProfile => {
    return removeData(removeAuthenticationData(userProfile), [
        'accountId',
        'userName'
    ]);
};

module.exports = {
    removeAuthenticationData,
    removeIdentifiableData
};
