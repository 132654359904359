const { TileActions, GalleryActions } = require('../../shared/enums');

module.exports = {
    updateTile({ changedFields, tileIndex, gallerySlug, tileSlug }) {
        if (gallerySlug && tileSlug) {
            return {
                type: GalleryActions.UPDATE_GALLERY_TILE,
                changedFields,
                gallerySlug,
                tileSlug
            };
        }
        return {
            type: TileActions.UPDATE_TILE,
            changedFields,
            tileIndex
        };
    },

    replaceTitlePageRelatedTiles({ relatedTiles }) {
        return {
            type: TileActions.REPLACE_TITLE_PAGE_RELATED_TILES,
            relatedTiles
        };
    }
};
