class PagesApplication {
    constructor(name, app) {
        this.name = name;
        this.app = app;

        this.validate();
        this.validateServer();
    }

    get config() {
        if (this.app && this.app.getConfig) {
            return this.app.getConfig();
        }

        return {};
    }

    get hosts() {
        if (this.config.hosts) {
            return this.config.hosts;
        }

        return false;
    }

    get reducers() {
        return this.app.getReducers;
    }

    set server(server) {
        this.app.server = server;
        this.validateServer();
    }

    getRouteComponent(path, component, panel, state) {
        return this.app.getRouteComponent(path, component, panel, { state });
    }

    getStoreEnhancer() {
        if (this.app.storeEnhancer) {
            return this.app.storeEnhancer();
        }

        return createStore => createStore;
    }

    getMiddleware() {
        if (this.app && this.app.middleware) {
            return this.app.middleware;
        }

        return [];
    }

    composeQueryToPPP(ctx, query, routerParams) {
        if (this.app.server && this.app.server.composeQueryToPPP) {
            return this.app.server.composeQueryToPPP(ctx, query, routerParams);
        }

        return query;
    }

    getPreReqRedirects() {
        if (this.app.server && this.app.server.redirects && this.app.server.redirects.preRequest) {
            return this.app.server.redirects.preRequest;
        }

        return false;
    }

    getPostReqRedirects() {
        if (this.app.server && this.app.server.redirects && this.app.server.redirects.postRequest) {
            return this.app.server.redirects.postRequest;
        }

        return false;
    }

    getPageData(ctx) {
        if (this.app.server && this.app.server.getPageData) {
            return this.app.server.getPageData(ctx);
        }

        return false;
    }

    validate() {
        if (!this.name) {
            throw new Error('No name provided');
        }

        if (!this.app) {
            throw new Error(`The application "${this.name}" doesn't exist`);
        }

        if (!this.app.getConfig) {
            throw new Error(`The application "${this.name}" does not expose a getConfig function`);
        }

        if (this.app.getRouteComponent && typeof this.app.getRouteComponent !== 'function') {
            throw new Error(`The application "${this.name}" is not valid: getRouteComponent has to be a function`);
        }

        if (this.app.getReducers && typeof this.app.getReducers !== 'function') {
            throw new Error(`The application "${this.name}" is not valid: getReducers has to be a function`);
        }
    }

    validateServer() {
        if (!this.app.server) {
            return false;
        }

        if (this.app.redirects) {
            throw new Error(`The application "${this.name}" is not valid: redirects can't be exported in index.js.
            Use server.js instead.`);
        }

        if (this.app.composeQueryToPPP) {
            throw new Error(`The application "${this.name}" is not valid: composeQueryToPPP can't be exported in index.js.
            Use server.js instead.`);
        }

        if (this.app.server.redirects && typeof this.app.server.redirects !== 'object') {
            throw new Error(`The application "${this.name}" is not valid: redirects has to be an object`);
        }

        if (this.app.server.redirects && typeof this.app.server.redirects === 'object') {
            if (
                !((this.app.server.redirects.preRequest && typeof this.app.server.redirects.preRequest === 'function') ||
              (this.app.server.redirects.postRequest && typeof this.app.server.redirects.postRequest === 'function'))
            ) {
                throw new Error(`The application "${this.name}" is not valid: redirects object must contain at least one of "preRequest" or "postRequest" functions`);
            }
        }

        if (this.app.server.composeQueryToPPP && typeof this.app.server.composeQueryToPPP !== 'function') {
            throw new Error(`The application "${this.name}" is not valid: composeQueryToPPP has to be a function`);
        }

        if (this.app.server.getPageData && typeof this.app.server.getPageData !== 'function') {
            throw new Error(`The application "${this.name}" is not valid: getPageData has to be a function`);
        }

        if (this.app.server.getPageData) {
            console.warn(`[Sky Pages] WARNING: The application "${this.name}" is using "getPageData()" to fetch data on the server. This is deprecated. Please consider using server side redux actions instead.`);
        }
    }
}

export default PagesApplication;

