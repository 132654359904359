import classNames from 'classnames';
import React from 'react';
import ResponsiveImage from '../../../../../src/components/responsive-images/responsive-image';

class HeroMessage extends React.Component {
    render() {
        const { hero, title, textLines, error } = this.props.content;
        const viewMode = this.props.viewMode;

        return (
            <div>
                {error ? this.renderIcon(hero) : this.renderImage(hero, viewMode)}
                <section className="o-container">
                    {viewMode === 'desktop' ? this.renderTile(title, textLines) : this.renderBody(title, textLines)}
                </section>
            </div>
        );
    }

    renderIcon(Icon) {
        return (
            <div className="o-layout u-margin-all-large u-text-center">
                <Icon />
            </div>
        );
    }

    renderImage(hero, viewMode) {
        return (
            <div className={classNames('c-hero c-hero--fit-content', { 'c-hero--overlap': viewMode !== 'mobile' })}>
                <ResponsiveImage sources={hero} className="c-hero__video" type="tile" />
            </div>
        );
    }


    renderBody = (title, textLines) => (
        <div className="u-margin-all-large u-text-center">
            <h2 className="hero-message-title c-heading-bravo">{title}</h2>
            {textLines.map((line, index) => <p key={index} className="hero-message-line u-margin-bottom-none">{line}</p>)}
        </div>
    )

    renderTile = (title, textLines) => (
        <article className="c-tile in-view">
            <div className="c-tile__content">
                <div className="c-tile__body">{this.renderBody(title, textLines)}</div>
            </div>
        </article>
    )
}

export default HeroMessage;
