import { combineReducers } from 'redux';

import passthroughReducer from 'client/reducers/passthrough-reducer';

import spinnerReducer from './spinner';
import userDataReducer from './user-data';

export default ({ ppvEngagementSection = {}, holding = [] }) => combineReducers({
    ppvEngagementSection: passthroughReducer(ppvEngagementSection),
    spinner: spinnerReducer,
    userData: userDataReducer,
    holding: passthroughReducer(holding)
});
