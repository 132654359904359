import { isObject } from './checks';

const cautious = evaluate => {
    return (args) => {
        if (!args) return;
        try {
            const res = evaluate(args);
            return res;
        } catch (e) {
            console.warn(`traverse error: ${e.message}`); // eslint-disable-line no-console
            return args;
        }
    };
};


const transformArr = (evaluate, arr) => arr.map(evaluate);

const transformObj = (evaluate, obj) => Object.entries(obj)
    .reduce((newObj, [key, value]) => ({
        ...newObj, [key]: evaluate(value)
    }), {});

const apply = func => value => {
    if (isObject(value)) {
        return transformObj(apply(func), value);
    } else if (Array.isArray(value)) {
        return transformArr(apply(func), value);
    }

    return func(value);
};

// takes a function and applies it to any leaf inside an object or array combination
// if called without a withFunction, will return a deep copy of the obj/array/value
export default (toLeafValue, func = (x) => x) => apply(cautious(func))(toLeafValue);
