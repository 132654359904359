export const MobileUsageTypes = {
    MESSAGES: {
        EXTRA_TEXT_CHARGES: 'Extra Text Charges',
        ROAMING: 'roaming'
    },
    VOICE: {
        ROAMING: 'roaming'
    }
};

export const MobileCallDirection = {
    INCOMING: 'MT',
    OUTGOING: 'MO'
};
