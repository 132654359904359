import React from 'react';
import HeroMessage from './';

const content = {
    hero: {
        large: 'https://images.contentstack.io/v3/assets/blt43f30e2d1473571a/blt8af080cb59824d1a/59b9144ec0eddd140d5a596e/download',
        medium: 'https://images.contentstack.io/v3/assets/blt43f30e2d1473571a/blt8af080cb59824d1a/59b9144ec0eddd140d5a596e/download',
        small: 'https://www.sky.com/contentstack/assets/blt43f30e2d1473571a/blt7f9384bf7547c1c9/59b0072875d9f7760dfc0de9/download'
    },
    title: "We're taking a pit stop.",
    textLines: [
        'Our site is currently down for planned maintenance.',
        'Sorry for any inconvenience, please come back later.']
};

export default props => (<HeroMessage content={content} viewMode={props.viewMode || 'desktop'} />);
