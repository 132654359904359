const React = require('react');
const utils = require('../../../client/utils');

module.exports = ({ img, type, channel, channelSponsor, id, href, theme }) => {
    if (img) {
        const toggle = (type === 'tile') ? utils.getSponsorToggleClass(channel, channelSponsor, id) : '';
        const themeClass = theme ? `t-${theme}` : '';
        const wrapperClasses = `${type}-sponsor${toggle} ${themeClass}`;
        const imgClasses = `sponsor-image ${type}-logo-sponsor${toggle}`;
        const sponsorImage = (
            <img src={img} className={imgClasses} alt={id} />
        );

        if (href) {
            const trackingLabel = `Sponsored By ${id}`;

            return (
                <div className={wrapperClasses}>
                    <a className="sponsor-link" href={href} target="_blank" data-tracking-label={trackingLabel}>
                        <span className="sponsor-label">Sponsored by</span>
                        {sponsorImage}
                    </a>
                </div>
            );
        } else {
            return (
                <div className={wrapperClasses}>
                    <span className="sponsor-label">Sponsored by</span>
                    {sponsorImage}
                </div>
            );
        }
    }

    return null;
};
