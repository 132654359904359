const React = require('react');

function renderLinks(links) {
    return (
        links.map(({ dataTracking, href, image, text }) => {
            return (
                <a key={dataTracking} className="u-margin-right footer-text-link" data-tracking-other={dataTracking}
                    href={href}>
                    {image ? <img src={image} /> : null}
                    {text || null}
                </a>
            );
        })
    );
}

class DefaultFooterText extends React.Component {
    render() {
        const { footerData: { text = '', subheading = '', links = [], closingText = '', legal = '' } } = this.props;

        return (
            <div className="o-layout">
                <div
                    className="o-layout__item footer-text u-width-1/2@legacy-medium u-width-1@legacy-small u-push-1/4@legacy-medium u-margin-top">
                    <div>
                        <p className="c-text-lead u-text-center" dangerouslySetInnerHTML={{ __html: text }}></p>
                        <h3 className="c-heading-charlie u-text-center">{subheading}</h3>
                        <div className="u-text-center">
                            {renderLinks(links)}
                            <p className="u-margin-top" dangerouslySetInnerHTML={{ __html: closingText }}></p>
                        </div>
                        <p className="c-text-smallprint u-text-center" dangerouslySetInnerHTML={{ __html: legal }}></p>
                    </div>
                </div>
            </div>
        );
    }
}

module.exports = DefaultFooterText;
