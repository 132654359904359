import { BoxOfficeActions } from 'shared/enums';

const initialState = { loggedIn: false, data: {}, customEmail: null };

export default (state = initialState, action) => {
    switch (action.type) {
    case BoxOfficeActions.POPULATE_USER_DATA:
        return { ...state, data: action.data };
    case BoxOfficeActions.PPV_RESET:
        return initialState;
    case BoxOfficeActions.LOGGED_IN:
        return { ...state, loggedIn: true };
    case BoxOfficeActions.UPDATE_EMAIL_ADDRESS:
        return { ...state, customEmail: action.data };
    default:
        return state;
    }
};
