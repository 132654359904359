import graphQLService from 'client/services/graphql-service';
import { BillSnippetActions } from 'shared/enums';
import { billSnippetQuery, splitBillSnippetQuery } from '../graphql-queries/bill-snippet-queries';
import config from 'config';

export default function billSnippetActionCreator(dispatch, service = graphQLService) {
    const makeBillSnippetCall = async (type, query, injectedData = null) => {
        const result = injectedData || await service.executePost(query, config.tokens.billSnippet.graphql);
        try {
            dispatch({
                type,
                data: result,
                errors: result.error || null
            });
        } catch (err) {
            console.error(err);
        }
    };
    return {
        requestBillSnippetData(injectedData) {
            return makeBillSnippetCall(BillSnippetActions.REQUEST_BILL_SNIPPET_DATA, billSnippetQuery, injectedData);
        },
        requestSplitBillSnippetData() {
            return makeBillSnippetCall(BillSnippetActions.REQUEST_SPLIT_BILL_SNIPPET_DATA, splitBillSnippetQuery);
        }
    };
}
