const R = require('ramda');
const striptags = require('striptags');

const episodesUtils = require('../utils/episodes');
const { endpoints: { imagePlaceholder } } = require('../../config');

const SEO = require('./seo').default;

function strip(input) {
    return typeof input === 'string' ? striptags(input) : input;
}

function getTile({ tiles }, params) {
    const slug = params.slug || params.identifier;
    return slug && R.find(tile => tile.slug === slug, tiles);
}

function getFirstWatchTile({ tiles = [] }) {
    return R.find(tile => tile.area === 'watch', tiles);
}

function getEpisode({ tile, seasonNumber, episodeNumber }) {
    return tile ? episodesUtils(tile.episodes).getEpisodeData(seasonNumber, episodeNumber) : null;
}

function getMetaDataFromEpisode({ title, synopsis, imageUrl: image } = {}) {
    return R.reject(R.isNil, {
        title: strip(title),
        description: strip(synopsis),
        image
    });
}

function getMetaDataFromTile({ title, synopsis, tileImage, videos, canonical }) {
    const { url, small, medium, large } = tileImage || {};
    const [video] = videos || [];
    return R.reject(R.isNil, {
        title: strip(title),
        image: url || small || medium || large,
        video,
        description: strip(synopsis && (synopsis.medium || synopsis.small)),
        canonical
    });
}

function getMetaDataFromPage({ channel: { title, description, nosnippet, robots } = {}, tiles, hero }) {
    return R.reject(R.isNil, {
        title: strip(title),
        image: R.path(['image', 'url'], hero),
        video: R.path([0, 'videos', 0], tiles),
        description: strip(description),
        nosnippet,
        robots
    });
}

function getMergedProperty(property, objects) {
    return objects.reduce((result, object) => {
        const value = object && object[property];
        return R.isNil(value) ? result : value;
    }, undefined);
}

module.exports = {
    getTile,
    getFirstWatchTile,
    getEpisode,
    getMetaDataFromEpisode,
    getMetaDataFromPage,
    getMetaDataFromTile,
    getMergedProperty,

    generateMetaData(state, { params }) {
        const { metaData = {}, channel = {} } = state;

        const tile = getTile(state, params) || {};
        const firstWatchTile = getFirstWatchTile(state) || {};
        const seasonNumber = parseInt(params.season, 10);
        const episodeNumber = parseInt(params.episode, 10);
        const episode = getEpisode({ tile, seasonNumber, episodeNumber }) || {};
        const seasonAndEpisode = R.not(R.isEmpty(episode) || R.isNil(seasonNumber) || R.isNil(episodeNumber)) && `Season ${seasonNumber} Episode ${episodeNumber}`;
        const isLandingPage = state.pageTemplate === 'title';

        const pageData = getMetaDataFromPage(state);
        const tileData = R.isEmpty(tile) ? {} : getMetaDataFromTile(tile);
        const firstWatchTileData = R.isEmpty(firstWatchTile) ? {} : getMetaDataFromTile(firstWatchTile);
        const episodeData = R.isEmpty(episode) ? {} : getMetaDataFromEpisode(episode);
        const fallbackData = { image: imagePlaceholder };

        let title;
        let description;
        let overriddenCanonical;

        if (isLandingPage) {
            overriddenCanonical = firstWatchTileData.canonical;
            title = getMergedProperty('title', [pageData, firstWatchTileData, tileData]);
            description = getMergedProperty('description', [pageData, firstWatchTileData, tileData, episodeData]);
        } else {
            overriddenCanonical = tileData.canonical;
            title = getMergedProperty('title', [pageData, tileData]);
            description = getMergedProperty('description', [pageData, tileData, episodeData]);
        }

        const nosnippet = pageData.nosnippet;
        const robots = pageData.robots;

        const image = getMergedProperty('image', [fallbackData, pageData, firstWatchTileData, tileData, episodeData]);
        const video = getMergedProperty('video', [pageData, firstWatchTileData, tileData]);
        const canonicalUrl = overriddenCanonical || SEO.getCanonicalUrl(channel.canonical, params, tile, state.location);
        const seoFormattedTitle = SEO.getTitle(pageData.title, { title }, seasonAndEpisode);

        let titleOverride;
        let descriptionOverride;
        if (state.metaData) {
            titleOverride = state.metaData.title;
            descriptionOverride = state.metaData.description;
        }

        return {
            title: titleOverride || seoFormattedTitle,
            image,
            video,
            description: descriptionOverride || description,
            ...(metaData.keywords ? { keywords: metaData.keywords } : {}),
            canonicalUrl,
            nosnippet,
            robots
        };
    },

    setMetaTags({ title, canonicalUrl }) {
        document.querySelector('title').innerHTML = title;

        if (canonicalUrl) {
            const link = document.querySelector("link[rel='canonical']") ? document.querySelector("link[rel='canonical']") : document.createElement('link');
            link.setAttribute('rel', 'canonical');
            link.setAttribute('href', canonicalUrl);
            document.head.appendChild(link);
        }
    }
};
