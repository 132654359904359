const billService = require('apps/mobile-bill/mobile-bill-old/services/bill-service').default;
const { MobileDataActions } = require('apps/mobile-bill/enums');

const MobileBillActionCreator = (dispatch, service = billService) => {
    const makeMobileServiceCall = (method, type, additionalData) => {
        const dispatchLoadAction = data => {
            return dispatch({
                type,
                data
            });
        };

        return method.call(service, additionalData)
            .then(dispatchLoadAction)
            .catch(() => dispatchLoadAction());
    };

    return {
        requestMobileBillNextMobileBill() {
            return makeMobileServiceCall(service.getMobileBillNextMobileBill, MobileDataActions.REQUEST_MOBILE_BILL_NEXT_MOBILE_BILL);
        },
        requestMobileBillListAndNextMobileBill() {
            return makeMobileServiceCall(service.getMobileBillListAndNextMobileBill, MobileDataActions.REQUEST_MOBILE_BILL_LIST_AND_NEXT_MOBILE_BILL);
        },
        requestMobileBillListAndLastMobileBill() {
            return makeMobileServiceCall(service.getMobileBillListAndLastMobileBill, MobileDataActions.REQUEST_MOBILE_BILL_LIST_AND_LAST_MOBILE_BILL);
        },
        mobileBillingIdChanged({ billingId, lastBillingId }) {
            dispatch({ type: MobileDataActions.MOBILE_BILLING_ID_CHANGED, billingId, lastBillingId });
        },
        setMobileBillLoading() {
            dispatch({ type: MobileDataActions.SET_MOBILE_BILL_LOADING });
        },
        savePaperBilling({ accountNumber, etag: eTag, enabled: paperBillingEnabled }) {
            return makeMobileServiceCall(service.setPaperlessBillingSettings, MobileDataActions.SAVE_PAPER_BILLING, { eTag, accountNumber, paperBillingEnabled });
        },
        setPaperBilling(enabled) {
            dispatch({
                type: MobileDataActions.SET_PAPER_BILLING,
                data: { enabled }
            });
        },
        requestMobileBill({ billingId, lastBillingId }) {
            return service.getMobileBill(billingId)
                .then(data => dispatch({ type: MobileDataActions.REQUEST_MOBILE_BILL, data, billingId, lastBillingId }));
        },
        requestMobileAccountStatus() {
            return makeMobileServiceCall(service.getMobileAccountStatus, MobileDataActions.REQUEST_MOBILE_ACCOUNT_STATUS);
        }
    };
};

module.exports = MobileBillActionCreator;
