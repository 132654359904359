const get = require('lodash.get');
const pathOr = require('ramda/src/pathOr');
const { helpAccount } = require('./helpers');
const defaultConfig = require('./default');

const R = { pathOr };

const extractEvents = propEvents => {
    const joined = propEvents.filter(Boolean).join(':');
    return `diagnostics:${joined}`;
};

const diagnosticsLinkDetails = event => {
    const { flow, step, currentSlide } = event.page.custom;
    const text = get(event, 'element.custom.linkDetails.text');
    return [
        get(event, 'element.custom.linkDetails.module'),
        get(event, 'custom.video') ? 'video' : get(event, 'element.custom.linkDetails.pod'),
        'click',
        text,
        currentSlide ? extractEvents([flow, step, currentSlide]) : extractEvents([flow, step])
    ];
};

const diagnosticsConfig = {
    ...defaultConfig,
    adapters: {
        adobeAnalytics: {
            maps: {
                populators: {
                    account: event => helpAccount(get, event, 'www.sky.com/help/diagnostics/'),
                    pageName: event => {
                        const { flow = null, step = null, currentSlide = null } = event.page.custom;
                        return extractEvents([flow, step, currentSlide]);
                    },
                    channel: () => 'D=c24',
                    section0: event => {
                        const { flow = null } = event.page.custom;
                        return extractEvents([flow]);
                    },
                    section1: event => {
                        const { flow = null, step = null } = event.page.custom;
                        return extractEvents([flow, step]);
                    },
                    section2: event => {
                        const { flow = null, step = null, currentSlide = null } = event.page.custom;
                        return extractEvents([flow, step, currentSlide]);
                    },
                    linkDetails: event => {
                        if (event.type && event.type === 'click') {
                            return diagnosticsLinkDetails(event).join('|');
                        }
                    },
                    lhcRagStatus: event => {
                        return R.pathOr(null, ['metaData', 'rag'], event.page.custom);
                    },
                    tileList: event => {
                        const result = get(event, 'element.custom.tileList') || get(event, 'page.custom.tileList');

                        if (Array.isArray(result)) {
                            return result.join('|');
                        }

                        return result;
                    },
                    abTestVariant: event => {
                        const experiments = get(event, 'page.custom.experiments');

                        if (experiments) {
                            return Object.keys(experiments).map(key => `${key}=${experiments[key]}`).join('|');
                        }

                        return false;
                    }
                },
                variables: {
                    abTestVariant: ['eVar18'],
                    channel: ['eVar24'],
                    section0: ['prop25', 'eVar26'],
                    section1: ['prop27', 'eVar29'],
                    section2: ['prop31', 'eVar30'],
                    lhcRagStatus: ['prop41'],
                    tileList: ['list3']
                }
            }
        }
    }
};

module.exports = diagnosticsConfig;
