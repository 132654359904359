const ValidLocaleStrings = [
    'en-gb',
    'de-de',
    'it-it',
    'en-us',
    'en-ie'
];

const MastheadVariants = {
    EXISTING: 'existing',
    PROSPECT: 'prospect'
};

const GridBreakpoints = {
    MOBILE: 640,
    TABLET: 1024
};

const HeroBreakpoints = {
    SMALL: 800,
    MEDIUM: 1280
};

const CarouselBreakpoints = {
    MEDIUM: 740
};

const MobileBreakpoint = 740;

const MobileHeightBreakpoint = 414;

const PanelOffsetInPixels = 40;

const ScrollLockClassName = 'u-lock-scroll';

const AllBreakpoints = {
    tile: {
        small: GridBreakpoints.MOBILE,
        medium: GridBreakpoints.TABLET
    },
    hero: {
        small: HeroBreakpoints.SMALL,
        medium: HeroBreakpoints.MEDIUM
    }
};

const Cookies = {
    SSO_TOKEN: 'skySSO',
    SSO_SECURE_TOKEN: 'skyCEsidsso01',
    NEW_SSO_SECURE_TOKEN: 'skyCEsidmesso01',
    SSO_LONG_TOKEN: 'skyCEsidexsso01',
    OAUTH_TOKEN: 'skyCEsa01',
    MOBILE_APP_ID: 'skyCAdt01',
    MY_SKY_APP_VERSION: 'mySkyAppVersion',
    MY_SKY_APP_ENABLE_CMP: 'enableMySkyAppCMP',
    HIDE_MASTHEAD: 'hideMasthead',
    CTA_PAGE_LOAD: 'ctaPageLoad',
    POLARIS_SPLIT: 'polarisSplit',
    COUNTRY_AKA: 'countryAKA',
    GEO: 'geo',
    ADOBE_MARKETING_COOKIE: 'AMCV_0ABA4673527831C00A490D45%40AdobeOrg',
    USER_NAME: 'uifd',
    UUID: 'uuid',
    ADOBE_TARGET_SESSION_ID: 'adobeTargetSession',
    TRACKING_ID: 'skyCEsidtrk01',
    TRACKING_ID_EXTRA: 'skyCEsidextrk01',
    POLARIS_ENGINE_TEST: 'polaris-engine-test',
    HELP_PRODUCT_TYPE: 'sky_help_product_type',
    ASSURANCE_DIAGNOSTICS_AB_GROUP: 'assuranceDiagnosticsABSplit',
    FAV_ASSURANCE_COMPONENT_GROUP: 'favAssuranceComponentGroup',
    STS: 'aam_tnt'
};

const Countries = {
    GB: 'GB',
    IE: 'IE',
    DE: 'DE',
    IT: 'IT'
};

const CountryCodes = {
    IRL: 'IRL',
    GBR: 'GBR'
};

const TileBrandUrlMappings = {
    'sky-1': 'sky-one',
    'sky-atlantic': 'sky-atlantic',
    'sky-atlantic-got': 'sky-atlantic',
    'sky-arts': 'sky-arts',
    'sky-living': 'sky-living',
    'sky-witness': 'sky-witness',
    'sky-box-sets': 'sky-box-sets',
    'sky-store': 'sky-store',
    'sky-movies': 'sky-movies',
    'sky-cinema': 'sky-cinema',
    'sky-kids': 'sky-kids',
    'ultimate-on-demand': 'ultimate-on-demand',
    netflix: 'ultimate-on-demand',
    challenge: 'challenge',
    pick: 'pick',
    'sky-crime': 'sky-crime',
    'sky-comedy': 'sky-comedy',
    'sky-documentaries': 'sky-documentaries',
    'sky-nature': 'sky-nature'
};

const ExcludedAreaPathsForHeader = [
    'asm-services'
];

const ExcludedAreaPathsForFooter = ['asm-services'];

const ExcludedAreaPaths = ExcludedAreaPathsForHeader.map(pathForHeader => {
    if (ExcludedAreaPathsForFooter.includes(pathForHeader)) {
        return pathForHeader;
    }
    return null;
}).filter(Boolean);

const ServiceContactNumber = '03442 414141';
const SwapUpgradeContactNumber = '0333 759 4530';

const maintenancePageData = {
    pageTemplate: 'maintenance',
    layout: []
};

const helpApps = {
    diagnostics: 'diagnostics',
    helpNew: 'help-new'
};

const ContentApiRealms = Object.freeze({
    help: 'help'
});

module.exports = {
    maintenancePageData,
    Cookies,
    Countries,
    CountryCodes,
    ValidLocaleStrings,
    MastheadVariants,

    ScrollLockClassName,
    PanelOffsetInPixels,
    CarouselBreakpoints,
    GridBreakpoints,
    HeroBreakpoints,
    MobileBreakpoint,
    MobileHeightBreakpoint,
    AllBreakpoints,

    TileBrandUrlMappings,
    ExcludedAreaPaths,
    ExcludedAreaPathsForHeader,
    ExcludedAreaPathsForFooter,

    ServiceContactNumber,
    SwapUpgradeContactNumber,
    helpApps,
    HelpDefaultTitle: 'Sky Help | Sky.com',
    HelpTitleSuffix: ' | Sky Help | Sky.com',
    ContentApiRealms
};
