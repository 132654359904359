const React = require('react');

function getPosition(coords) {
    if (isFinite(coords.x) && isFinite(coords.y)) {
        return `${coords.x}% ${coords.y}%`;
    }
}

function getCoords(x, y) {
    return {
        x,
        y
    };
}

class Shine extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            top: getCoords(),
            bottom: getCoords()
        };
    }

    updateTopPosition(x, y) {
        this.setState({
            top: getCoords(x, y)
        });
    }

    updateBottomPosition(x, y) {
        this.setState({
            bottom: getCoords(x, y)
        });
    }

    render() {
        return (
            <section>
                <div className="shine top" ref="top" style={{ backgroundPosition: getPosition(this.state.top) }} />
                <div className="shine bottom" ref="bottom" style={{ backgroundPosition: getPosition(this.state.bottom) }} />
            </section>
        );
    }
}

module.exports = Shine;
