const R = require('ramda');

const { WidgetStateActions } = require('../../shared/enums');

const initialState = {};

module.exports = (state = initialState, action) => {
    const uuid = action.uuid;
    const setState = data => R.set(R.lensProp(uuid), data, state);

    switch (action.type) {
    case WidgetStateActions.UPDATE:
        return setState(R.merge(state[uuid], action.data));
    case WidgetStateActions.INITIALISE:
        if (!state[uuid]) {
            return setState({});
        }

        return state;
    default:
        return state;
    }
};
