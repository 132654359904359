const get = require('lodash.get');
const { helpAccount } = require('./helpers');

const sections = (event = {}) => {
    const slug = get(event, 'page.custom.helpArticleSlug');
    return slug ? `help:articles:${slug}` : 'help:articles';
};

const helpArticlesConfig = {
    adapters: {
        adobeAnalytics: {
            maps: {
                populators: {
                    account: event => helpAccount(get, event, 'www.sky.com/help/articles/'),
                    pageName: event => sections(event),
                    channel: () => 'D=c24',
                    section0: event => sections(event),
                    section1: event => sections(event),
                    section3: event => sections(event),
                    abTestVariant: event => {
                        const experiments = get(event, 'page.custom.experiments');

                        if (experiments) {
                            return Object.keys(experiments).map(key => `${key}=${experiments[key]}`).join('|');
                        }

                        return false;
                    }
                },
                variables: {
                    abTestVariant: ['eVar18'],
                    channel: ['eVar24'],
                    section0: ['prop25', 'eVar26'],
                    section1: ['prop27', 'eVar29'],
                    section2: ['prop31', 'eVar30']
                }
            }
        }
    }
};

module.exports = helpArticlesConfig;
