import React from 'react';
import { findDOMNode } from 'react-dom';

import ResponsiveImage from '../../responsive-images/responsive-image';
import Video from '../../video/video';
import utils from '../../../../client/utils';
import imageSizing from '../../responsive-images/image-sizing';
import { PanelStatus, ViewModes } from '../../../../shared/enums';
import { AllBreakpoints } from '../../../../shared/constants';

class HeroMedia extends React.Component {
    state = {
        renderVideo: false
    };

    playVideo() {
        if (this.refs.video) {
            this.refs.video.play();
        }
    }

    pauseVideo() {
        if (this.refs.video) {
            this.refs.video.pause();
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.panelStatus !== this.props.panelStatus) {
            if (this.props.panelStatus === PanelStatus.OPEN) {
                this.pauseVideo();
            } else if (this.props.panelStatus === PanelStatus.CLOSED) {
                this.playVideo();
            }
        }
    }

    componentDidMount() {
        const { data } = this.props;

        const renderVideo = data.video && !utils.isTouchDevice();
        if (renderVideo) {
            this.setState({
                renderVideo: true
            });
        }
        if (this.props.hasMedia) {
            this.props.hasMedia();
        }
    }

    audioToggle = (event) => {
        event.preventDefault();
        this.refs.video.audioToggle();

        const control = findDOMNode(this).querySelector('.hero-video__audio-toggle i');
        const container = findDOMNode(this).querySelector('.hero-video__audio-toggle');

        const isMuted = this.refs.video.muted();
        control.className = isMuted ? 'skycon2-volume-mute' : 'skycon2-volume-up';
        container.setAttribute('data-tracking-label', isMuted ? 'speaker' : 'mute');
    };

    renderAudioToggle(showAudioToggle) {
        return showAudioToggle
            ? (<a className="hero-video__audio-toggle" data-tracking-label="speaker"
                role="button"
                onClick={this.audioToggle.bind(this)}>
                <i ref="audiotoggle" className="skycon2-volume-mute" />
            </a>)
            : null;
    }

    render() {
        const { video, image, alt = '' } = this.props.data || {};
        const { onImageError, viewMode } = this.props;
        const sources = (video && video.placeholder) || image;
        const altText = (image && image.alt) || alt;

        if (this.state.renderVideo && viewMode !== ViewModes.MOBILE) {
            return (<div className="hero-video">
                {this.renderAudioToggle(video.showAudioToggle)}
                <Video
                    ref="video"
                    controls={false}
                    autoPlay={this.props.panelStatus !== PanelStatus.OPEN}
                    muted={true}
                    loop={video.loop}
                    preload="auto"
                    poster={imageSizing.getResizedUrl(video.placeholder, AllBreakpoints.hero, 1200)}
                    videoSources={video.sources} />
            </div>);
        } else {
            return (
                <ResponsiveImage
                    sources={sources}
                    alt={altText || alt}
                    className="hero-image"
                    channel={this.props.channel}
                    mediaFormat="hero"
                    type="hero"
                    onError={ onImageError }
                />
            );
        }
    }
}

export default HeroMedia;


