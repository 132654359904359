const jscookie = require('js-cookie');
const { Cookies } = require('../../shared/constants');
const config = require('../../config');
const query = require('./query');

const CustomerAccountService = {
    getToken(cookie) {
        return cookie.get(Cookies.SSO_SECURE_TOKEN);
    },

    getTokenIncludingLongLived(cookie) {
        return cookie.get(Cookies.OAUTH_TOKEN) || cookie.get(Cookies.SSO_SECURE_TOKEN) || cookie.get(Cookies.SSO_TOKEN) || cookie.get(Cookies.SSO_LONG_TOKEN);
    },

    getOverview(queryRequest = query, cookie = jscookie) {
        return queryRequest({
            url: `${config.endpoints.baggie}/account/overview?token=${this.getTokenIncludingLongLived(cookie)}`,
            method: 'GET'
        });
    },

    getPackageDetails(queryRequest = query, cookie = jscookie) {
        return queryRequest({
            url: `${config.endpoints.baggie}/account/inventory?token=${this.getToken(cookie)}`,
            method: 'GET'
        });
    },

    getRegionalBouquetInfo(queryRequest = query, cookie = jscookie) {
        return queryRequest({
            url: `${config.endpoints.baggie}/account/region?token=${this.getTokenIncludingLongLived(cookie)}`,
            method: 'GET',
            timeout: config.endpoints.regionalInfoTimeout
        });
    }
};

module.exports = CustomerAccountService;
