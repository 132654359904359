const R = require('ramda');

const episodes = seasonsData => {
    return {

        getSeasonData(selectedSeasonNumber) {
            if (seasonsData) {
                const seasonNumbers = R.pluck('seasonNumber', seasonsData);
                const index = seasonNumbers.indexOf(selectedSeasonNumber);

                if (index > -1) {
                    return seasonsData[index];
                }
            }
        },

        getEpisodeData(selectedSeason, selectedEpisode) {
            const selectedSeasonData = this.getSeasonData(selectedSeason);
            if (selectedSeasonData) {
                const episodeNumbers = R.pluck('episode', selectedSeasonData.episodes);
                const index = episodeNumbers.indexOf(selectedEpisode);

                if (index > -1) {
                    return selectedSeasonData.episodes[index];
                }
            }
        }
    };
};

module.exports = episodes;
