import React, { PureComponent } from 'react';

export default class AccountInfoBlock extends PureComponent {
    render() {
        return (
            <div className="account-info-block u-margin-bottom">
                {this.props.children}
            </div>
        );
    }
}
