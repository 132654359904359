import { featureFlags } from 'config';

const trim = strings => strings.map(str => str.replace(/\s+/g, ' ')).join('');

const mobilePortfolioAndCatalogue = trim`query MobilePortfolioAndCatalogue {
    me {
        mobile {
            dueDebt
            sims(sortBy: STATUS) {
                friendlyName
                dataPlans {
                    code
                    state
                }
                phoneNumber
                serviceInstanceIdentifier
                portfolio {
                    products {
                        code
                        charges {
                            currency
                            amount
                            recurrence
                        }
                        actions {
                            type
                            available
                        }
                    }
                }
            }
        }
    }
    mobileCatalogue {
        callsAndTextsProducts {
            id
            name
            price {
                currency
                amount
                recurrence
            }
            smsAllowance {
                amount
            }
            callsAllowance {
                minutes
            }
            upgradeProductIds
            downgradeProductIds
        }
        dataProducts(salesStatus: ALL) {
            id
            name
            price {
                currency
                amount
                recurrence
            }
            upgradeProductIds
            downgradeProductIds
            salesStatus
        }
        addonsProducts {
            id
            name
            type
            paymentType
            price {
                currency
                amount
                recurrence
            }
            upgradeProductIds
            downgradeProductIds
            redemptionPoints
        }
        simProductId
    }
}`;

const mobileInventory = trim`query MobileInventory {
    me {
        mobile {
            sims(sortBy: STATUS) {
                friendlyName
                phoneNumber
                serviceInstanceIdentifier
                dataPlans {
                    description
                    state
                    subState
                }
                recurringAddons {
                    description
                }
                status
                subStatus
            }
        }
    }
}`;

const mobileRewardsBalance = trim`query MobileRewardsBalance {
  me {
      billingAccount(type: mobile) {
       piggybank {
        canRoll
        currentBalance {
            data
        }
      }
    }
  }
}`;

const mobileRewardsRollId = trim`query MobileRewardsRollId {
  mobileCatalogue {
    addonsProducts(paymentType: PIGGYBANK) {
      id
      name
    }
  }
}`;

const mobileRewardsRedemptions = trim`query MobileRewardsRedemptions {
  me {
    billingAccount(type: mobile) {
      piggybank {
        redemptionOptions {
          id
          name
          description
          amount
          interval
          maxAmount
          min
          max
        }
        hasRedemptionOptions
        maxRedemptionAmount
      }
    }
  }
}`;

const mobileRewardsAmountCarriedOverFromLastMonth = trim`query MobileRewardsAmountCarriedOverFromLastMonth {
  me {
      billingAccount(type: mobile) {
       piggybank {
        dataCarriedOver
      }
    }
  }
}`;

const mobileRewardsTransactions = trim`query MobileRewardsTransactions {
  me {
    billingAccount(type: mobile) {
      piggybank {
        transactionHistory {
          closingBalanceValue(unit: GB, decimalPlaces: 2)
          transactions {
            serviceInstanceId
            date
            activityType
            data(unit: GB, decimalPlaces: 2)
            newBalanceValue(unit: GB, decimalPlaces: 2)
          }
        }
      }
    }
  }
}`;

const mobileSummary = trim`query MobileSummary {
    me {
        personalDetails {
            firstName
        }
        mobile {
            status
            subStatus
            reinstateActions {
              code
              description
              amount
              currency
            }
            accountNumber
            sims(sortBy: STATUS) {
                friendlyName
                phoneNumber
                serviceInstanceIdentifier
                status
                subStatus
            }
        }
    }
}`;

const getMobileSettings = () => {
    if (featureFlags.mobile.showSpendCap) {
        return trim`query MobileSettings($serviceInstanceIdentifier: String!) {
            me {
                mobile {
                    accountNumber
                    billCapRules {
                        capMinimum
                        capMaximum
                        capIncrement
                    }
                    sims(serviceInstanceIdentifier: $serviceInstanceIdentifier) {
                        friendlyName
                        phoneNumber
                        serviceInstanceIdentifier
                        roamingCap {
                            currencyId
                            value
                            capEnabled
                            lockToken
                        }
                        billCapSettings {
                            capEnabled
                            capValue
                            alertEnabled
                        }
                        adultContentFiltered
                    }
                }
                billingAccount(type: mobile) {
                    preferences {
                        etag
                        paperBillEnabled {
                            enabled
                        }
                    }
                }
                confirmedOver18
            }
        }`;
    }

    return trim`query MobileSettings($serviceInstanceIdentifier: String!) {
        me {
            mobile {
                accountNumber
                sims(serviceInstanceIdentifier: $serviceInstanceIdentifier) {
                    friendlyName
                    phoneNumber
                    serviceInstanceIdentifier
                    roamingCap {
                        currencyId
                        value
                        capEnabled
                        lockToken
                    }
                    adultContentFiltered
                }
            }
            billingAccount(type: mobile) {
                preferences {
                    etag
                    paperBillEnabled {
                        enabled
                    }
                }
            }
            confirmedOver18
        }
    }`;
};

const mobileSettings = getMobileSettings();

const simInventoryProductCount = trim`query SimInventoryProductCount($serviceInstanceIdentifier: String!, $catalogueProductId: String!) {
    me {
        mobile {
            sims(serviceInstanceIdentifier: $serviceInstanceIdentifier) {
                productCount(catalogueProductId: $catalogueProductId)
            }
        }
    }
}`;

const paymentPlanStatement = trim`query PaymentPlanStatement($agreementRef: String!) {
  me {
    mobile {
      paymentPlan(agreementRef: $agreementRef) {
        status
        contract {
          planRepayments
        }
        summary {
          unbilledRepayments
          amountLeftToPay
          schedulingSummary {
            sequence
            numberOfRepayments
            value
          }
        }
        schedule {
          installments {
            scheduledDate
            paymentStatus
            planValue
            amountPaid
          }
        }
      }
    }
  }
}`;


const devices = trim([`query MobileDevices {
    me {
        mobile {
            accountNumber
            handsets {
                manufacturer
                model
                size
                colour
                agreementNumber
                primaryImage
                paymentPlan {
                    status
                    summary {
                        initialRepayDate
                        finalRepayDate
                        schedulingSummary {
                            sequence
                            numberOfRepayments
                            value
                        }
                    }
                    contract {
                      statusReason
                      contractSignedDate
                      closedDate
                    }
                }
                swapValue
                state
                subState
                swap {
                    startDate
                    endDate
                    planNumber
                    planName
                    value
                    currentSwapState
                    customerProductElementId
                    calculatedValues {
                        conditionReducedValue
                        conditionReductionValue
                        cutOffDate
                        netValue
                        paymentPlanUnallocatedValue
                        swapValue
                    }
                    order {
                        estimatedSwapValues {
                            conditionReducedValue
                            conditionReductionValue
                            cutOffDate
                            netValue
                            paymentPlanUnallocatedValue
                            swapValue
                        }
                        actualDiagnostics {
                            answer
                            percentageReduction
                            question
                            questionCode
                        }
                    }
                    planNumber
                    planName
                    value
                    customerProductElementId
                    currentSwapState
                    calculatedValues {
                        netValue
                        paymentPlanUnallocatedValue
                        swapValue
                        cutOffDate
                    }
                }
                swapEligible
                portfolioProductId
                canTradeIn
                inCoolingOffPeriod
            }
            accessories {
                portfolioProductId
                manufacturer
                model
                category
                colour
                primaryImage
                state
                subState
                title
                canRefund
                purchaseDate
            }
            unsignedCreditAgreements {
                rawPayload
            }
            hasDevices
        }
    }
}`]);


const mobilePortfolioAndCatalogueWithPiggybank = trim`query MobilePortfolioAndCatalogueWithPiggybank {
    me {
        billingAccount(type: mobile) {
            piggybank {
                type
                canRoll
                currentBalance {
                    data(unit: GB, decimalPlaces: 2)
                }
            }
        }
        mobile {
            dueDebt
            sims {
                friendlyName
                dataPlans {
                    code
                    state
                }
                phoneNumber
                serviceInstanceIdentifier
                portfolio {
                    products {
                        code
                        charges {
                            currency
                            amount
                            recurrence
                        }
                        actions {
                            type
                            available
                        }
                    }
                }
            }
        }
    }
    mobileCatalogue {
        callsAndTextsProducts {
            id
            name
            price {
                currency
                amount
                recurrence
            }
            smsAllowance {
                amount
            }
            callsAllowance {
                minutes
            }
            upgradeProductIds
            downgradeProductIds
        }
        dataProducts(salesStatus: ALL) {
            id
            name
            price {
                currency
                amount
                recurrence
            }
            upgradeProductIds
            downgradeProductIds
            salesStatus
        }
        addonsProducts {
            id
            name
            type
            paymentType
            price {
                currency
                amount
                recurrence
            }
            upgradeProductIds
            downgradeProductIds
            redemptionPoints
        }
        simProductId
    }
}`;

const notifications = trim`query MobileNotifications {
    me {
        mobile {
            notifications(orderResourceTypes: ["MOBILE_DEVICE", "MOBILE_ACCESSORY"], includeExpired: false) {
                messageId
                description
                lastUpdatedAt
                url
            }
            sims {
                friendlyName
                serviceInstanceIdentifier
                notifications {
                    messageId
                    description
                    lastUpdatedAt
                    url
                }
            }
            handsets {
                portfolioProductId
                manufacturer
                model
                colour
                notifications {
                    messageId
                }
                swap {
                    startDate
                    endDate
                    calculatedValues {
                        cutOffDate
                    }
                }
            }
        }
    }
}`;

const mobileBillNextMobileBill = trim`query MobileBillNextMobileBill {
    me {
        billingAccount(type: mobile) {
            predictedBills(nextBill: true) {
                firstBillIssued
                total
                statementDate
                paymentDueDate
            }
        }
    }
}`;

const mobileUsage = trim`query MobileUsage {
    me {
        mobile {
            nextBill {
                usage {
                    sims(sortBy: STATUS) {
                        mobilePhoneNumber
                        serviceInstanceIdentifier
                        allowances(type: "DATA") {
                            currentPeriod {
                                daysUntilRefresh
                            }
                            remainingAllowance(unit: B)
                            accumulatedUsagePercentage
                        }
                    }
                }
            }
        }
    }
}`;

export default {
    notifications,
    mobileInventory,
    mobilePortfolioAndCatalogue,
    mobileRewardsBalance,
    mobileRewardsRollId,
    mobileRewardsRedemptions,
    mobileRewardsAmountCarriedOverFromLastMonth,
    mobileRewardsTransactions,
    mobileSummary,
    mobileSettings,
    paymentPlanStatement,
    simInventoryProductCount,
    devices,
    mobileBillNextMobileBill,
    mobileUsage,
    mobilePortfolioAndCatalogueWithPiggybank
};
