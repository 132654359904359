const get = require('lodash.get');
const { helpAccount } = require('./helpers');

const engineerTackingConfig = {
    adapters: {
        adobeAnalytics: {
            maps: {
                populators: {
                    account: event => helpAccount(get, event, 'www.sky.com/ordertracking/'),
                    pageName: () => 'help:engineer-tracking:home'
                }
            }
        }
    }
};

module.exports = engineerTackingConfig;
