import React from 'react';

import Shine from '../../../shine/shine';
import Sponsor from '../../../sponsors/sponsor';

function renderHeroTitle(title, url) {
    const classNames = url ? ' u-padding-bottom-large' : '';
    return title &&
        (<h2 key="hero-title"
            className={`hero-title${classNames}`}
            dangerouslySetInnerHTML={{ __html: title }}
        />);
}

function renderHeroSubtitle(subtitle, url) {
    if (url) {
        return (<a className="c-btn c-btn--primary" href={url}>{subtitle}</a>);
    }
    return (<h3 key="hero-tx" className="hero-tx">{subtitle}</h3>);
}

function renderHeroLinks(links) {
    if (links && Array.isArray(links)) {
        return (
            <div key="hero-links" className="u-padding-top-small hero-links">
                {
                    links.map(({ dataTracking, href, image, alt = '', text }) => {
                        return (
                            <a key={dataTracking} className="u-margin-right hero-link" data-tracking-other={dataTracking} href={href}>
                                {image ? <img src={image} alt={alt} /> : null}
                                {text || null}
                            </a>
                        );
                    })
                }
            </div>
        );
    }
}

function renderText({ title, subtitle, url, links }) {
    return (
        <div className="u-padding-bottom-large">
            {renderHeroTitle(title, url)}
            {subtitle && renderHeroSubtitle(subtitle, url)}
            {renderHeroLinks(links)}
        </div>
    );
}

function renderLogo(logo, moviesSearch) {
    return logo && (
        <div className="hero-overlay-inner table">
            <div className="table-cell center-xy">
                <div className="hero-logo-channel-wrapper" ref="channelLogo">
                    <img src={logo} className="hero-logo-channel" />
                    <Shine />
                </div>
                {moviesSearch}
            </div>
        </div>
    );
}

function renderSponsor(sponsor) {
    return sponsor && (
        <Sponsor type="hero"
            img={sponsor.image}
            href={sponsor.url}
            id={sponsor.name} />
    );
}

class HeroOverlay extends React.Component {
    render() {
        const { data, moviesSearch } = this.props;

        return (
            <div className="hero-overlay">
                {renderLogo(data.logo, moviesSearch)}
                <div className="hero-overlay-inner">
                    <div className="hero-details">
                        {renderText(data)}
                    </div>
                    {renderSponsor(data.sponsor)}
                </div>
            </div>
        );
    }
}

export default HeroOverlay;
