import { mergeDeepRight } from 'ramda';
import { ProgrammesActions } from 'shared/enums';

export default (initialData = {}) => (state = initialData, action) => {
    const { type, identifier, data } = action;

    switch (type) {
    case ProgrammesActions.UPDATE_PROGRAMME_DATA:
        return mergeDeepRight(state, { [identifier]: data });

    default:
        return state;
    }
};
