import { BoxOfficeActions } from 'shared/enums';

export default (state = { active: true }, action) => {
    switch (action.type) {
    case BoxOfficeActions.ACTIVATE_SPINNER:
        return { ...state, active: true };
    case BoxOfficeActions.DISABLE_SPINNER:
        return { ...state, active: false };
    default:
        return state;
    }
};
