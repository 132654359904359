const trimWhiteSpace = strings => strings.map(str => str.replace(/\s+/g, ' ')).join('');

const createBankAccount = `
mutation CreateBankAccount($bankDetails: CreateBankAccountInputType! ) {
    createBankAccount(bankDetails: $bankDetails ) {
        token
    }
}`;

const makePayment = `mutation MakePayment($accountNumber:String!,$paymentCardId:String!,$amount:String!,$currency:AccountCurrencyType!) {
    makePayment(accountNumber:$accountNumber,paymentCardId:$paymentCardId,amount:$amount,currency:$currency) {
        paymentResult
        warnings
        errors
        status
        subStatus
        reinstateActions {
            code
            description
            amount
            currency
       }
    }
}`;

const changePaymentMethod = `
mutation ChangePaymentMethod($accountNumber: String!, $paymentMethodId: String!, $currency: AccountCurrencyType, $accountType: AccountTypeType) {
    changePaymentMethod(accountNumber: $accountNumber, paymentMethodId: $paymentMethodId, currency: $currency, accountType: $accountType) {
        impactedBill
        warnings
        status
        subStatus
        reinstateActions {
            code
            description
            amount
            currency
       }
    }
}`;

const getCurrentPaymentMethod = trimWhiteSpace`
fragment BankAccountFields on PaymentMethodBankAccount {
  last4DigitsOfAccountNumber
  last2DigitsOfSortCode
  last4DigitsOfIban
  first4DigitsOfBic
  accountHolderName
}

  fragment PaymentCardFields on PaymentMethodPaymentCard {
    last4DigitsOfPaymentCard
    paymentCardType
    cardHolderName
    expiryDate
  }
query getCurrentPaymentMethod($accountNumber: String!) {
      me {
        parties {
          billingAccount(id: $accountNumber) {
            accountNumber
            paymentMethod {
              type
              ... BankAccountFields
              ... PaymentCardFields
            }
          }
        }
      }
}`;

const reinstateAccount = `
mutation ReinstateAccount($accountNumber: String!, $restrictionType: AccountRestrictionType!) {
  reinstateAccount(accountNumber: $accountNumber, restrictionType: $restrictionType) {
    orderId
    accountNumber
    status
    subStatus
    reinstateActions {
      code
      description
      amount
      currency
    }
    services {
      serviceInstanceId
      products
    }
  }
}`;

module.exports = {
    createBankAccount,
    makePayment,
    changePaymentMethod,
    getCurrentPaymentMethod,
    reinstateAccount
};
