module.exports = {
    config: require('config'),
    constants: require('shared/constants'),
    enums: require('shared/enums'),
    routes: {
        RouteHelper: require('shared/routes/helper').default,
        routePaths: require('shared/enums').RoutePaths
    },
    services: {
        graphql: require('client/services/graphql-service')
    },
    Tracking: require('client/tracking'),
    utils: Object.assign({}, require('client/utils'), {
        cookieHelper: require('shared/utils/cookie-helper'),
        tiles: require('shared/utils/tiles').default,
        user: require('client/utils/user'),
        WindowEvents: require('client/utils/window-events'),
        graphql: {
            customerProductsMessage: require('client/graphql/customer-products-message')
        }
    })
};
