const React = require('react');

const preventPropagation = next => event => {
    event.preventDefault();
    next();
};

module.exports = ({ onCloseBtnClick }) => {
    return (
        <div className="panel-header">
            <div className="panel-header-inner">
                <a href="#" className="panel-close close" role="button" data-tracking-label="close panel" aria-label="close panel" onClick={preventPropagation(onCloseBtnClick)}>
                    <span className="panel-close-label">Close</span>
                    <i className="panel-close-icon skycon2-cross" />
                </a>
            </div>
        </div>
    );
};
