/* eslint-disable no-restricted-imports */
// Please refactor this file to use axios instead of any other HTTP request libraries when you next get the chance to change this file.

import cookie from 'js-cookie';
import { MobileDataActions } from 'apps/mobile/constants/action-types';
import config from 'config';
import { Cookies } from 'shared/constants';
import queries from './queries';
import mutations from './mutations';
import xhr from 'xhr';

class MobileService {
    constructor(dispatch) {
        this.dispatch = dispatch;
    }

    getNotifications(endpointUrl) {
        return this.execute(endpointUrl, queries.notifications, 'MobileNotifications');
    }

    getMobileOrderTracking(endpointUrl) {
        return this.execute(endpointUrl, queries.mobileOrderTracking);
    }

    getMobileInventory(endpointUrl) {
        return this.execute(endpointUrl, queries.mobileInventory, 'MobileInventory');
    }

    getMobileSummary(endpointUrl) {
        return this.execute(endpointUrl, queries.mobileSummary, 'MobileSummary');
    }

    getMobilePortfolioAndCatalogue(endpointUrl) {
        return this.execute(endpointUrl, queries.mobilePortfolioAndCatalogue, 'MobilePortfolioAndCatalogue');
    }

    getMobileRewardsTransactions(endpointUrl) {
        return this.execute(endpointUrl, queries.mobileRewardsTransactions, 'MobileRewardsTransactions');
    }

    getPaymentPlanStatement(endpointUrl, agreementRef) {
        const query = queries.paymentPlanStatement;
        const operationName = 'PaymentPlanStatement';
        const variables = { agreementRef };

        return this.execute(endpointUrl, query, operationName, variables);
    }

    getProductCount(endpointUrl, serviceInstanceIdentifier, catalogueProductId) {
        const query = queries.simInventoryProductCount;
        const operationName = 'SimInventoryProductCount';
        const variables = { catalogueProductId, serviceInstanceIdentifier };

        return this.execute(endpointUrl, query, operationName, variables);
    }

    getMobileUsage(endpointUrl) {
        return this.execute(endpointUrl, queries.mobileUsage, 'MobileUsage');
    }

    getMobileRewardsBalance(endpointUrl) {
        return this.execute(endpointUrl, queries.mobileRewardsBalance, 'MobileRewardsBalance');
    }

    getMobileRewardsRedemptions(endpointUrl) {
        return this.execute(endpointUrl, queries.mobileRewardsRedemptions, 'MobileRewardsRedemptions');
    }

    getMobileRewardsRollId(endpointUrl) {
        return this.execute(endpointUrl, queries.mobileRewardsRollId, 'MobileRewardsRollId');
    }

    getMobileRewardsAmountCarriedOver(endpointUrl) {
        return this.execute(endpointUrl, queries.mobileRewardsAmountCarriedOverFromLastMonth, 'MobileRewardsAmountCarriedOverFromLastMonth');
    }

    getMobileSettings(endpointUrl, currentSim) {
        const operationName = 'MobileSettings';
        const variables = { serviceInstanceIdentifier: currentSim.serviceInstanceIdentifier };

        return this
            .execute(endpointUrl, queries.mobileSettings, operationName, variables)
            .then(graphqlResponse => ({ graphqlResponse }));
    }

    getToken() {
        return cookie.get(Cookies.OAUTH_TOKEN) ||
               cookie.get(Cookies.SSO_SECURE_TOKEN) ||
               cookie.get(Cookies.SSO_TOKEN) ||
               cookie.get(Cookies.SSO_LONG_TOKEN);
    }

    getUUID() {
        return cookie.get(Cookies.UUID);
    }

    getCurrentPaymentMethod(endpointUrl, accountNumber) {
        return this.execute(
            endpointUrl,
            queries.mobileCurrentPaymentMethod,
            'getCurrentPaymentMethod',
            { accountNumber });
    }

    getDevices(endpointUrl) {
        return this.execute(endpointUrl, queries.devices, 'MobileDevices');
    }

    postSignCCARequest(endpointUrl, { agreementNumber, threatMetrixSessionId, agreeToAffordabilityQuestion }) {
        return this.execute(endpointUrl, mutations.signCCA, 'SignCCA', { agreementNumber, threatMetrixSessionId, agreeToAffordabilityQuestion });
    }

    postNumberPortingRequest(endpointUrl, formData) {
        const mutation = mutations.numberPorting;
        const variables = {
            skyNumber: formData.portingSkyNumber,
            portingNumber: formData.portInNumber,
            pac: formData.portingPacNumber
        };

        return Promise.all([
            this.execute(endpointUrl, mutation, 'PortNumber', variables),
            Promise.resolve({ portInNumber: formData['number-porting-port-in-number'] })
        ]);
    }

    postDataRoamingCapRequest(endpointUrl, { capEnabled, lockToken, serviceInstanceId }) {
        const mutation = mutations.setRoamingCap;

        const variables = {
            serviceInstanceId,
            capEnabled,
            lockToken
        };

        return this.execute(endpointUrl, mutation, 'SetRoamingCap', variables);
    }

    postPaperBillingRequest(endpointUrl, { etag, accountNumber, currentPaperBillingStatus }) {
        const mutation = mutations.paperBilling;

        const variables = {
            etag,
            account: {
                accountNumber,
                billingPreferences: {
                    sendPaperBill: !currentPaperBillingStatus
                }
            }
        };

        return this.execute(endpointUrl, mutation, 'UpdateAccount', variables);
    }

    postAdultContentFilterRequest(endpointUrl, variables) {
        return this.execute(endpointUrl, mutations.updateAdultContentFilter, 'UpdateAdultContentFilter', variables);
    }

    submitDeviceSwapAction(endpointUrl, variables) {
        return this.execute(endpointUrl, mutations.submitDeviceSwapAction, 'MobileDeviceChooseSwapAction', variables);
    }

    postOrderMobileProduct(endpointUrl, { complete, productId, serviceInstanceIdentifier }) {
        const mutation = mutations.orderMobileProduct;
        const operationName = 'OrderMobileProduct';
        const variables = { productId, serviceInstanceIdentifier };
        if (complete) {
            variables.complete = complete;
        }

        return this.execute(endpointUrl, mutation, operationName, variables);
    }

    postAddMobileProductToBasket(endpointUrl, productId, serviceInstanceIdentifier) {
        const mutation = mutations.addMobileProductToBasket;
        const operationName = 'AddMobileProductToBasket';
        const variables = { productId, serviceInstanceIdentifier };

        return this.execute(endpointUrl, mutation, operationName, variables);
    }

    postCeaseMobileMixProduct(endpointUrl, productId, serviceInstanceIdentifier) {
        const query = mutations.ceaseMobileMixProduct;
        const operationName = 'CeaseMobileMixProduct';
        const variables = { productId, serviceInstanceIdentifier };

        return this.execute(endpointUrl, query, operationName, variables);
    }

    postConfirmProductOrder(endpointUrl, interactionReference) {
        return this.execute(endpointUrl, mutations.confirmProductOrder, 'ConfirmProductOrder', { interactionReference });
    }

    postRequestAdHocCCAStatement(endpointUrl, { accountNumber, agreementNumber }) {
        return this.execute(endpointUrl, mutations.requestAdHocCCAStatement, 'RequestAdHocCCAStatement', { accountNumber, agreementNumber });
    }

    requestApnSettings(endpointUrl, params) {
        return this.execute(endpointUrl, mutations.requestApnSettings, 'RequestApnSettings', params);
    }

    getMobilePortfolioAndCatalogueWithPiggybank(endpointUrl) {
        return this.execute(endpointUrl, queries.mobilePortfolioAndCatalogueWithPiggybank, 'MobilePortfolioAndCatalogueWithPiggybank');
    }

    getMobileRewardsBalanceRedemptionRollAddon(endpointUrl) {
        return this.execute(endpointUrl, queries.mobileRewardsBalance, 'MobileRewardsBalance');
    }

    requestMobileBillNextMobileBill(endpointUrl) {
        return this.execute(endpointUrl, queries.mobileBillNextMobileBill, 'MobileBillNextMobileBill');
    }

    requestNextActions(endpointUrl, { accountNumber, portfolioProductId }) {
        return this.execute(endpointUrl, mutations.initialiseMobileAccessoryReturn, 'InitialiseMobileAccessoryReturn', { accountNumber, portfolioProductId });
    }

    submitAccessoryForReturn(endpointUrl, returnDetails) {
        return this.execute(endpointUrl, mutations.returnMobileAccessory, 'ReturnMobileAccessory', { returnDetails });
    }

    requestResendPackaging(endpointUrl, { accountNumber, customerProductElementId }) {
        return this.execute(endpointUrl, mutations.requestResendPackaging, 'RequestResendPackaging', { accountNumber, customerProductElementId });
    }

    submitEditSpendCapRequest(endpointUrl, { accountNumber, serviceInstanceId, capEnabled, capValue, alertEnabled }) {
        return this.execute(endpointUrl, mutations.setMobileSimBillCap, 'SetMobileSimBillCap', { accountNumber, serviceInstanceId, capEnabled, capValue, alertEnabled });
    }
    friendlyNameSubmitRequest(endpointUrl, { accountNumber, serviceInstanceId, name }) {
        const operationName = config.featureFlags.mobile.friendlyNameProfanity ? 'SetUnprofaneFriendlyName' : 'SetFriendlyName';
        return this.execute(endpointUrl, mutations.setFriendlyName, operationName, { accountNumber, serviceInstanceId, name });
    }

    execute(endpointUrl, query, operationName = null, variables = null) {
        const body = { query };

        if (operationName) {
            body.operationName = operationName;
        }
        if (variables) {
            body.variables = variables;
        }

        return this.query({
            url: endpointUrl,
            method: 'POST',
            useXDR: true,
            headers: {
                Authorization: `Bearer ${this.getToken()}`,
                Accept: 'application/json',
                'sky-session-id': this.getUUID(),
                'x-api-token': config.tokens.mobile.graphql,
                'Content-Type': 'application/json; charset=UTF-8'
            },
            body: JSON.stringify(body)
        });
    }

    handleAuthFail() {
        this.dispatch({
            type: MobileDataActions.REQUEST_MOBILE_SUMMARY,
            data: this.genSkyportError('auth')
        });

        return this.genSkyportError('auth');
    }

    genSkyportError = reason => ({
        data: {},
        errors: [{
            message: 'Skyport Error',
            cause: {
                name: `skyport.${reason}.error`
            }
        }]
    });

    query(request) {
        return new Promise((resolve, reject) => { // eslint-disable-line promise/avoid-new
            xhr(request, (err, resp) => {
                if (err) {
                    return reject(this.genSkyportError('xhr'));
                }

                const { statusCode, body } = resp;

                if (statusCode === 401) {
                    return reject(this.handleAuthFail());
                }

                if (statusCode < 200 || statusCode > 299) {
                    return reject(this.genSkyportError('server'));
                }

                if (typeof body === 'string' || body instanceof String) {
                    try {
                        return resolve(JSON.parse(body));
                    } catch (e) {
                        return reject(this.genSkyportError('json'));
                    }
                }

                resolve(body);
            });
        });
    }
}

export default MobileService;
