import * as R from 'ramda';

const resolveSizeFromLayout = (tiles, layout) => {
    return tiles.map((tile, i) => {
        return R.merge(tile, {
            size: tile.size || (layout && layout[i % layout.length]) || null,
            index: i
        });
    });
};

const findTile = (tiles, slug) => R.find(tile => tile.slug === slug, tiles);

const resolveTilesFromTileRefs = (tileRefs, tiles) => {
    return tileRefs.map(tileRef => {
        const tile = findTile(tiles, tileRef.slug);

        if (!tile) {
            throw new Error(`Cannot find matching tile with tileRef: ${JSON.stringify(tileRef)}`);
        }

        return R.merge(tile, { size: tileRef.size });
    });
};

const getPageLayout = (page, area) => {
    return page.layout || area.layout;
};

export default {
    resolveSizeFromLayout,
    getPageLayout,
    resolveTilesFromTileRefs,
    findTile
};
