const R = require('ramda');

module.exports = (initialData = {}) => (state = initialData, { type, changedFields, gallerySlug, tileSlug }) => {
    const areParametersValid = R.not(R.isNil(gallerySlug) || R.isNil(tileSlug));

    if (type === 'UPDATE_GALLERY_TILE' && changedFields && areParametersValid) {
        const galleryIndex = state.findIndex(gallery => gallery.slug === gallerySlug);
        const galleryExists = galleryIndex > -1 && Array.isArray(state[galleryIndex].collectionTiles);

        const tileIndex = galleryExists && state[galleryIndex].collectionTiles.findIndex(tile => tile.slug === tileSlug);
        const tileExists = tileIndex > -1;

        if (galleryExists && tileExists) {
            const newTile = { ...state[galleryIndex].collectionTiles[tileIndex], ...changedFields };
            const collectionTiles = R.update(tileIndex, newTile, state[galleryIndex].collectionTiles);
            const newGallery = { ...state[galleryIndex], ...{ collectionTiles } };
            const newState = R.update(galleryIndex, newGallery, state);
            return newState;
        }
    }

    return state;
};
