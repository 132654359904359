import * as R from 'ramda';
import moment from 'moment';

import traverse from '../../shared/utils/traverse';
import dig from 'shared/utils/dig';
import { MobileCallDirection } from 'apps/mobile-bill/constants/mobile-bill-constants';

export function buildTitle(array) {
    if (array.length === 0) {
        return '';
    }
    if (array.length === 1) {
        return array[0];
    }

    let all = '';

    if (array.length === 2) {
        all = array.join(' and ');
    } else {
        const start = array.slice(0, -1);
        const end = array[array.length - 1];
        all = `${start.join(', ')} and ${end}`;
    }

    return all.charAt(0).toUpperCase() + all.slice(1).toLowerCase();
}

export function formatDataAmount(bytes) {
    const mb = bytes / 1024 / 1024;
    const gb = mb / 1014;
    const { amount, unit } = gb < 1 ? { amount: mb, unit: 'MB' } : { amount: gb, unit: 'GB' };
    return `${Math.ceil(amount * 100) / 100} ${unit}`;
}

export function createDateFormatter(format) {
    return function(dateString) {
        return moment(dateString, 'YYYY-MM-DD').format(format);
    };
}

export function createDateTimeFormatter(format) {
    return function(dateTimeString) {
        return moment(dateTimeString, 'YYYY-MM-DDTHH:mm:ss').format(format);
    };
}

export const formatDate = createDateFormatter('D MMM');
export const formatDateWithYear = createDateFormatter('D MMM YYYY');
export const formatDateWithLongMonthAndYear = createDateFormatter('D MMMM YYYY');
export const formatOrdinalDateWithYear = createDateFormatter('Do MMMM YYYY');
export const formatMonthWithYear = createDateTimeFormatter('MMMM YYYY');

export const formatMonthYear = (dateString) => {
    if (!dateString) {
        return '';
    }
    return createDateFormatter('MMM YYYY')(dateString);
};

export const formatYearMonth = (dateString) => {
    if (!dateString) {
        return '';
    }
    return createDateFormatter('YYYY-MM')(dateString);
};

export const currencySymbols = {
    GBP: '£',
    USD: '$',
    EUR: '€'
};

export function formatCurrency(number, currency = 'GBP') {
    const sign = number < 0 ? '-' : '';
    const currencySymbol = currencySymbols[currency] || '£';
    return `${sign}${currencySymbol}${Math.abs(number).toFixed(2)}`;
}

const splitDuration = durationString => R.map(R.curry(parseInt)(R.__, 10), durationString.split(':'));

export function formatDuration(durationString) {
    const [hours, minutes, seconds] = splitDuration(durationString);
    const output = [];

    if (hours > 0) {
        output.push(`${hours} hr`);
    }
    if (minutes) {
        output.push(`${minutes} min`);
    }
    if (seconds) {
        output.push(`${seconds} sec`);
    }
    return output.join(' ');
}

export function formatDurationAccessible(durationString) {
    const [hours, minutes, seconds] = splitDuration(durationString);
    const output = [];

    if (hours > 0) {
        output.push(`${hours} hour${hours > 1 && 's'}`);
    }
    if (minutes) {
        output.push(`${minutes} minute${minutes > 1 && 's'}`);
    }
    if (seconds) {
        output.push(`${seconds} second${seconds > 1 && 's'}`);
    }
    return output.join(' ');
}

export function formatTime(timeString) {
    return moment(timeString, 'HH:mm:ss').format('HH:mm');
}

export function formatMobilePhoneNumber(phoneNumberString) {
    return `${phoneNumberString.slice(0, 5)} ${phoneNumberString.slice(5, 11)}`;
}

export function pluralizeDays(numberString) {
    return numberString === '1' ? `${numberString} day` : `${numberString} days`;
}

export function formatPlace({ roaming, direction, originationPlace, destinationPlace }) {
    if (roaming) {
        if (direction === MobileCallDirection.INCOMING) {
            return `From ${destinationPlace} to ${originationPlace}`;
        }

        return `From ${originationPlace} to ${destinationPlace}`;
    }

    if (destinationPlace) {
        return destinationPlace;
    }

    return '';
}

export function getOrdinalDay(day) {
    if (day % 1) {
        return '';
    }

    const s = day % 100;
    if (s > 3 && s < 21) {
        return 'th';
    }

    switch (s % 10) {
    case 1: return 'st';
    case 2: return 'nd';
    case 3: return 'rd';
    default: return 'th';
    }
}

export const formatPrice = R.pipe(
    parseFloat,
    formatCurrency
);

export const wysiwyg = (stringOrObject, variables = {}, overrideRegex) => {
    const varToValue = messageVars => (_, capture) => dig(messageVars, ...capture.split('.'));
    const regex = overrideRegex || /{{(.+?)}}/g;

    const interpolateVars = vars => str => {
        if (typeof str !== 'string') {
            return str;
        }
        return str.replace(regex, varToValue(vars));
    };

    if (typeof stringOrObject === 'object') {
        return traverse(stringOrObject, interpolateVars(variables));
    }

    return interpolateVars(variables)(stringOrObject);
};
