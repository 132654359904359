/* eslint-disable no-restricted-modules */
// Please refactor this file to use axios instead of any other HTTP request libraries when you next get the chance to change this file.

const xhr = require('xhr');
const { filter, pipe } = require('ramda');
const queryString = require('querystring');

const queue = {};

const buildQueryString = pipe(
    filter(x => x),
    queryString.stringify,
    x => x.length > 0 ? `?${x}` : ''
);

function fetch(uri, xmlHttpReq) {
    return new Promise((resolve, reject) => xmlHttpReq({ uri, json: true },
        (error, response, body) => {
            if (error) {
                reject(error);
            } else {
                resolve(body);
            }
        })
    );
}

function fetchViaQueue(uri, queryParams = {}, xmlHttpReq) {
    const queryParamString = buildQueryString(queryParams);
    const request = fetch(`${uri}${queryParamString}`, xmlHttpReq);
    queue[uri] = request;
    return request.then(data => {
        delete queue[uri];
        return data;
    });
}

module.exports = function fetchJson(uri, queryParams, xmlHttpReq = xhr) {
    return queue[uri] || fetchViaQueue(uri, queryParams, xmlHttpReq);
};
