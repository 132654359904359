module.exports = {
    default: require('./default'),
    help: require('./help'),
    'help-articles': require('./help-articles'),
    diagnostics: require('./diagnostics'),
    mysky: require('./mysky'),
    bill: require('./bill'),
    mobile: require('./mobile-account'),
    'engineer-tracking': require('./engineer-tracking')
};
