const { merge } = require('ramda');
const get = require('lodash.get');

const defaultConfig = require('./default');
const config = require('../../../config');

const mySkyConfig = {
    adapters: {
        adobeAnalytics: {
            maps: {
                populators: merge(defaultConfig.adapters.adobeAnalytics.maps.populators, {
                    account: () => get(config, 'adobeAnalyticsAccounts.bill'),
                    pageName: event => {
                        if (event.page.name !== 'unknown') {
                            return `myaccount:${event.page.breadcrumb.join(':')}:${event.page.name}`;
                        } else {
                            return `myaccount:${event.page.breadcrumb.join(':')}`;
                        }
                    }
                }),
                variables: merge(defaultConfig.adapters.adobeAnalytics.maps.variables, {})
            }
        }
    }
};

module.exports = mySkyConfig;
