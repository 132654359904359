import Loadable from 'react-loadable';

import { Templates } from 'shared/enums';
import DefaultLoadingComponent from './default-loading-component';

const { Tile } = Templates;

const TileTemplates = {
    [Tile.ACCOUNT]: Loadable({
        loader: () => import(/* webpackChunkName: 'tile-account' */ './account/tile-account-template'),
        loading: DefaultLoadingComponent
    }),
    [Tile.CHANNEL]: Loadable({
        loader: () => import(/* webpackChunkName: 'tile-channel' */ './channel/tile-channel-template').then(m => m.default),
        loading: DefaultLoadingComponent
    }),
    [Tile.CONTENT]: Loadable({
        loader: () => import(/* webpackChunkName: 'tile-content' */ './content/tile-content-template').then(m => m.default),
        loading: DefaultLoadingComponent
    }),
    [Tile.LINK]: Loadable({
        loader: () => import(/* webpackChunkName: 'tile-link' */ './link/tile-link-template').then(m => m.default),
        loading: DefaultLoadingComponent
    }),
    [Tile.MULTILINK]: Loadable({
        loader: () => import(/* webpackChunkName: 'tile-multilink' */ './multilink/tile-multilink-template'),
        loading: DefaultLoadingComponent
    }),
    [Tile.ENGAGEMENT]: Loadable({
        loader: () => import(/* webpackChunkName: 'tile-engagement' */ './link/tile-link-template').then(m => m.default),
        loading: DefaultLoadingComponent
    }),
    [Tile.BILL]: Loadable({
        loader: () => import(/* webpackChunkName: 'tile-bill' */ './bill/tile-bill-template'),
        loading: DefaultLoadingComponent
    }),
    [Tile.TEXT]: Loadable({
        loader: () => import(/* webpackChunkName: 'tile-text' */ './text/tile-text-template'),
        loading: DefaultLoadingComponent
    }),
    [Tile.COVERIMAGE]: Loadable({
        loader: () => import(/* webpackChunkName: 'tile-cover-image' */ './cover-image/tile-cover-image-template').then(m => m.default),
        loading: DefaultLoadingComponent
    }),
    [Tile.COVERTEXT]: Loadable({
        loader: () => import(/* webpackChunkName: 'tile-cover-text' */ './cover-text/tile-cover-text-template'),
        loading: DefaultLoadingComponent
    }),
    [Tile.MOBILEBILL]: Loadable({
        loader: () => import(/* webpackChunkName: 'tiles-mobile' */ 'apps/mobile/components/tile/templates/bill/bill'),
        loading: DefaultLoadingComponent
    }),
    [Tile.PPVEVENTS]: Loadable({
        loader: () => import(/* webpackChunkName: 'tile-ppv-events' */ './ppv-events/tile-ppv-events-template').then(m => m.default),
        loading: DefaultLoadingComponent
    }),
    [Tile.SEARCHLINKS]: Loadable({
        loader: () => import(/* webpackChunkName: 'tile-search-link' */ './search-links/search-links-template'),
        loading: DefaultLoadingComponent
    }),
    [Tile.MOBILEUSAGE]: Loadable({
        loader: () => import(/* webpackChunkName: 'tiles-mobile' */ 'apps/mobile/components/tile/templates/usage/usage'),
        loading: DefaultLoadingComponent
    }),
    [Tile.MOBILEACCOUNT]: Loadable({
        loader: () => import(/* webpackChunkName: 'tiles-mobile' */ 'apps/mobile/components/tile/templates/account/account'),
        loading: DefaultLoadingComponent
    }),
    [Tile.MOBILEDATAROLLOVER]: Loadable({
        loader: () => import(/* webpackChunkName: 'tiles-mobile' */ 'apps/mobile/components/tile/templates/data-roll-over/datarollover'),
        loading: DefaultLoadingComponent
    }),
    [Tile.PIGGYBANK]: Loadable({
        loader: () => import(/* webpackChunkName: 'tiles-mobile' */ 'apps/mobile/components/tile/templates/piggybank/piggybank'),
        loading: DefaultLoadingComponent
    }),
    [Tile.MOBILEORDERTRACKING]: Loadable({
        loader: () => import(/* webpackChunkName: 'tiles-mobile' */ 'apps/mobile/components/tile/templates/order-tracking/ordertracking'),
        loading: DefaultLoadingComponent
    }),
    [Tile.MOBILENUMBERPORTING]: Loadable({
        loader: () => import(/* webpackChunkName: 'tiles-mobile' */ 'apps/mobile/components/tile/templates/number-porting/numberporting'),
        loading: DefaultLoadingComponent
    }),
    [Tile.MOBILESETTINGS]: Loadable({
        loader: () => import(/* webpackChunkName: 'tiles-mobile' */ 'apps/mobile/components/tile/templates/settings/settings'),
        loading: DefaultLoadingComponent
    }),
    [Tile.MOBILEHELP]: Loadable({
        loader: () => import(/* webpackChunkName: 'tiles-mobile' */ 'apps/mobile/components/tile/templates/help/help'),
        loading: DefaultLoadingComponent
    }),
    [Tile.MOBILEDEVICES]: Loadable({
        loader: () => import(/* webpackChunkName: 'tiles-mobile' */ 'apps/mobile/components/tile/templates/devices/devices'),
        loading: DefaultLoadingComponent
    }),
    [Tile.STOCKS]: Loadable({
        loader: () => import(/* webpackChunkName: 'tile-stocks' */ './stocks/tile-stocks-template'),
        loading: DefaultLoadingComponent
    }),
    [Tile.TWITTER]: Loadable({
        loader: () => import(/* webpackChunkName: 'tile-twitter' */ './twitter/twitter'),
        loading: DefaultLoadingComponent
    }),
    [Tile.IFRAME]: Loadable({
        loader: () => import(/* webpackChunkName: 'tile-iframe' */ './iframe/iframe-template'),
        loading: DefaultLoadingComponent
    }),
    [Tile.MEDIA]: Loadable({
        loader: () => import(/* webpackChunkName: 'tile-media' */ './media/tile-media-template'),
        loading: DefaultLoadingComponent
    }),
    [Tile.SPLIT]: Loadable({
        loader: () => import(/* webpackChunkName: 'tile-split' */ './split/tile-split-template'),
        loading: DefaultLoadingComponent
    }),
    [Tile.PRODUCTSOVERVIEW]: Loadable({
        loader: () => import(/* webpackChunkName: 'tile-products-overview' */ './customer-products/tile-products-overview-template'),
        loading: DefaultLoadingComponent
    }),
    [Tile.PRODUCT]: Loadable({
        loader: () => import(/* webpackChunkName: 'tile-product' */ './product/tile-product-template'),
        loading: DefaultLoadingComponent
    }),
    [Tile.FINDOUTMORE]: Loadable({
        loader: () => import(/* webpackChunkName: 'tile-find-out-more' */ './find-out-more/tile-find-out-more'),
        loading: DefaultLoadingComponent
    }),
    [Tile.ADDRESSCARD]: Loadable({
        loader: () => import(/* webpackChunkName: 'tiles-corporate' */ './corporate/address-card/address-card-template').then(m => m.default),
        loading: DefaultLoadingComponent
    }),
    [Tile.ROWCONTENT]: Loadable({
        loader: () => import(/* webpackChunkName: 'tiles-corporate' */ './corporate/row-content/row-content-template'),
        loading: DefaultLoadingComponent
    }),
    [Tile.CORPORATELINK]: Loadable({
        loader: () => import(/* webpackChunkName: 'tiles-corporate' */ './corporate/link/corporate-tile-link-template'),
        loading: DefaultLoadingComponent
    })
};

export default TileTemplates;
