const Tracking = require('./tracking');
const skyTagsConfig = require('./tracking/config');
const { scriptLoader } = require('./tracking/meta-tags');

function preRender(area, state, deps) {
    const trackingConfig = deps.skyTagsConfig[area] || deps.skyTagsConfig.default;

    deps.tracking.setConfig(trackingConfig);
}

module.exports = {
    preRender(area, state, overrides = {}) {
        const deps = {
            tracking: Tracking,
            skyTagsConfig,
            scriptLoader,
            ...overrides
        };

        return preRender(area, state, deps);
    }
};
