import { keyMirrorLowercase } from '../../shared/utils/key-mirror';
import ChangePaymentMethodStates from 'shared/components/molecules/change-payment-method-journey/constants/states';

export const Templates = {
    Hero: keyMirrorLowercase({
        DEFAULT: null
    }),
    Rows: keyMirrorLowercase({
        DATA: null,
        MMS: null,
        OFFER: null,
        SMS: null,
        STANDARD: null,
        VOICE: null,
        TRIPLEPLAY_STANDARD: null,
        TRIPLEPLAY_TRANSACTION: null,
        TRIPLEPLAY_BALANCE_CARRIED_FORWARD: null
    })
};

export const MobileBillPaymentStates = {
    SHOW_FORM: 'payment-show-form-state',
    SUCCESS_EXTRA_PAYMENT: 'payment-success-more-payment-required',
    SUCCESS_CHANGE_PAYMENT: 'payment-success-change-payment-method',
    SUCCESS_REINSTATE: 'payment-success-reinstate-account',
    SUCCESS: 'payment-success-state',
    ERROR: 'payment-error-state',
    ERROR_REINSTATE: 'payment-error-reinstate'
};

export const MobileBillChangePaymentStates = ChangePaymentMethodStates;

export const PaymentPlanStatus = {
    PAID: 'Paid',
    PART_PAYMENT: 'Part paid',
    PENDING: 'Pending',
    MISSED: 'Missed',
    EARLY_PAYMENT: 'Early payment',
    PAYMENT_HOLIDAY_UNBILLED: 'Payment holiday'
};

export const PaymentDueDayStates = {
    SHOW_FORM: 'change-payment-due-day-show-calendar-state',
    SHOW_SELECTED: 'change-payment-due-day-show-selected-state',
    SUCCESS: 'change-payment-due-day-success-state',
    ERROR: 'change-payment-due-day-error-state'
};

export const MobileBillAccordionActions = {
    TOGGLE_MOBILE_BILL_ACCORDION_SECTION:
        'TOGGLE_MOBILE_BILL_ACCORDION_SECTION',
    RESET_MOBILE_BILL_ACCORDION_SECTIONS: 'RESET_MOBILE_BILL_ACCORDION_SECTIONS'
};

export const MobileDataActions = {
    REQUEST_MOBILE_BILL_LIST_AND_NEXT_MOBILE_BILL:
        'REQUEST_MOBILE_BILL_LIST_AND_NEXT_MOBILE_BILL',
    REQUEST_MOBILE_BILL_LIST_AND_LAST_MOBILE_BILL:
        'REQUEST_MOBILE_BILL_LIST_AND_LAST_MOBILE_BILL',
    MOBILE_BILLING_ID_CHANGED: 'MOBILE_BILLING_ID_CHANGED',
    SET_MOBILE_BILL_LOADING: 'SET_MOBILE_BILL_LOADING',
    REQUEST_MOBILE_BILL: 'REQUEST_MOBILE_BILL',
    REQUEST_MOBILE_ACCOUNT_STATUS: 'REQUEST_MOBILE_ACCOUNT_STATUS',
    REQUEST_MOBILE_BILL_NEXT_MOBILE_BILL: 'REQUEST_MOBILE_BILL_NEXT_MOBILE_BILL'
};

export const Callouts = {
    PRO_RATA: 'PRO_RATA',
    BALANCE_CARRIED_FORWARD: 'BALANCE_CARRIED_FORWARD',
    EXTRA_CHARGE: 'EXTRA_CHARGE',
    'TERMS-AND-CONDITIONS': 'TERMS-AND-CONDITIONS'
};

export const AccordionHistory = {
    IS_FROM_CALLOUT: 'IS_FROM_CALLOUT'
};

export const MobileBillTypes = keyMirrorLowercase({
    HISTORIC: null,
    LAST: null,
    NEXT: null
});

export const TriplePlayBillActions = {
    REQUEST_TRIPLE_PLAY_BILL: 'REQUEST_TRIPLE_PLAY_BILL',
    SELECT_BILLING_ACCOUNT: 'SELECT_BILLING_ACCOUNT',
    SET_BILLING_ACCOUNT_ALIAS: 'SET_BILLING_ACCOUNT_ALIAS',
    GET_TRIPLEPLAY_BILL_FOR_BILLING_ID: 'GET_TRIPLEPLAY_BILL_FOR_BILLING_ID',
    RESET_TO_INITIAL_BILL_STATE: 'RESET_TO_INITIAL_BILL_STATE',
    GET_PREDICTED_BILLS: 'GET_PREDICTED_BILLS',
    NOTIFY_BILL_VIEW: 'NOTIFY_BILL_VIEW',
    SET_ERROR_STATE: 'SET_ERROR_STATE',
    UPDATE_NOTIFICATIONS: 'UPDATE_NOTIFICATIONS',
    GET_PAPERLESS_BILLING_SETTINGS: 'GET_PAPERLESS_BILLING_SETTINGS',
    SET_PAPERLESS_BILLING_SETTINGS: 'SET_PAPERLESS_BILLING_SETTINGS',
    GET_CALLS_FOR_BILLING_ID: 'GET_CALLS_FOR_BILLING_ID',
    GET_CURRENT_PAYMENT_METHOD_FOR_ACCOUNT:
        'GET_CURRENT_PAYMENT_METHOD_FOR_ACCOUNT',
    GET_CALLS_FOR_PREDICTED_BILL: 'GET_CALLS_FOR_PREDICTED_BILL',
    RESET_REINSTATE_ACTIONS_LOADED: 'RESET_REINSTATE_ACTIONS_LOADED',
    GET_REINSTATE_ACTIONS: 'GET_REINSTATE_ACTIONS',
    ATTEMPT_REINSTATE_CUSTOMER: 'ATTEMPT_REINSTATE_CUSTOMER',
    GET_CALL_CHARGE_ALERT_THRESHOLD: 'GET_CALL_CHARGE_ALERT_THRESHOLD',
    UPDATE_CALL_CHARGE_ALERT_THRESHOLD: 'UPDATE_CALL_CHARGE_ALERT_THRESHOLD',
    SET_APP_JOURNEY_STATUS: 'SET_APP_JOURNEY_STATUS',
    GET_ADJUSTMENT_ELIGIBILITY: 'GET_ADJUSTMENT_ELIGIBILITY',
    ATTEMPT_REINSTATE_CUSTOMER_WITH_ADJUSTMENT:
        'ATTEMPT_REINSTATE_CUSTOMER_WITH_ADJUSTMENT'
};

export const TriplePlayBillTourShapes = {
    CIRCLE: 'circle',
    OVAL: 'oval'
};

export const BillPeriod = keyMirrorLowercase({
    PREVIOUS: null,
    CURRENT: null,
    FUTURE: null
});

export const PaymentDueDayActions = {
    REQUEST_PAYMENT_DUE_DAY: 'REQUEST_PAYMENT_DUE_DAY',
    SELECT_PAYMENT_DUE_DAY: 'SELECT_PAYMENT_DUE_DAY',
    RESET_PAYMENT_DUE_DAY: 'RESET_PAYMENT_DUE_DAY',
    CHANGE_PAYMENT_DUE_DAY: 'CHANGE_PAYMENT_DUE_DAY'
};

export const CalloutActions = {
    SET_CALLOUT_DATA: 'SET_CALLOUT_DATA'
};

export const PrintOnlySectionActions = {
    REGISTER_PRINT_ONLY_SECTION: 'REGISTER_PRINT_ONLY_SECTION',
    UNREGISTER_PRINT_ONLY_SECTION: 'UNREGISTER_PRINT_ONLY_SECTION',
    MARK_PRINT_SECTION_READY: 'MARK_PRINT_SECTION_READY',
    FORCE_PRINT_MODE: 'FORCE_PRINT_MODE'
};

export const chatbotStatus = {
    OPENED: 'link_opened',
    FULL_PAYMENT: 'payment_made',
    PART_PAYMENT: 'part_payment_amount',
    PAYMENT_FAILED: 'payment_failed',
    CONT_PAY_COMPLETE: 'continuous_payment_completed',
    SYSTEM_ERROR: 'system_error',
    REINSTATE_SUCCESS: 'reinstatement_success',
    REINSTATE_FAILED: 'reinstatement_fail',
    CONT_PAY_FAILED: 'continuous_payment_failed'
};

export const REGIONS = {
    GBP: 'GBP',
    EUR: 'EUR'
};
