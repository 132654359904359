import React from 'react';
import { ResponsiveImage } from '@sky-uk/toolkit-react';

class Gallery extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedImage: 0
        };
    }

    nextImage = () => {
        const { selectedImage } = this.state;
        const { images } = this.props;

        this.setState({ selectedImage: selectedImage < images.length - 1 ? selectedImage + 1 : 0 });
    }

    previousImage = () => {
        const { selectedImage } = this.state;
        const { images } = this.props;

        this.setState({ selectedImage: selectedImage !== 0 ? selectedImage - 1 : images.length - 1 });
    }

    renderGallery() {
        return this.props.images.map((item, index) => this.renderGallerySection(item, index));
    }

    renderGallerySection(image, imageIndex) {
        return (
            <div key={imageIndex} className= {this.state.selectedImage === imageIndex ? 'c-gallery__view u-fade-in' : 'c-gallery__view'}>
                <div className="c-gallery__image">
                    <ResponsiveImage
                        cssClassName="c-tile__poster"
                        src={image.source.url}
                        alt={image.alt_text}
                    />
                    <div className="c-gallery__overlay--left" onClick={this.previousImage}>
                        <i className="skycon2-arrow-left u-chevron" />
                    </div>
                    <div className="c-gallery__overlay--right" onClick={this.nextImage}>
                        <i className="skycon2-arrow-right u-chevron" />
                    </div>
                </div>
                <div className="o-layout c-gallery__caption">
                    <div className="o-layout__item u-width-4/5">
                        { this.renderSectionCaption(image.caption) }
                    </div>
                    <div className="o-layout__item u-width-1/5 u-padding-all-none">
                        <h4 className="c-text-body u-margin-bottom-none u-padding-all-small o-layout--right c-gallery__count">{this.state.selectedImage + 1} / {this.props.images.length}</h4>
                    </div>
                </div>
            </div>
        );
    }

    renderSectionCaption = (caption) => {
        if (caption && caption.length !== 0) {
            return <h4 className="c-text-body u-margin-bottom-none u-padding-all-small">{caption}</h4>;
        }
    }

    render() {
        return (
            <div className="s-molecule c-gallery">
                { this.renderGallery() }
            </div>
        );
    }
}

export default Gallery;
