import * as R from 'ramda';
import React from 'react';
import classnames from 'classnames';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import dig from 'shared/utils/dig';
import { createDateFormatter } from 'apps/mobile-bill/formatters';
import MobileActionCreator from 'apps/mobile-bill/mobile-bill-old/actions/mobile-bill-action-creator';
import { MobileBillTypes } from 'apps/mobile-bill/enums';

const getBillingId = R.path(['mobileBill', 'selectedBill', 'billingId']);

if (process.env.WEBPACK_BUILD) {
    require('apps/mobile-bill/mobile-bill-old/stylesheets/_app.scss');
}

class HeroMobileBill extends React.Component {
    constructor(props) {
        super(props);
        this.onMobileBillingIdChanged = this.onMobileBillingIdChanged.bind(this);
        this.renderHero = this.renderHero.bind(this);
        this.renderPrintHero = this.renderPrintHero.bind(this);
    }

    componentDidMount() {
        const { routingInfo, mobileActions } = this.props;

        if (routingInfo.params.panel === 'last') {
            mobileActions.requestMobileBillListAndLastMobileBill();
            return;
        }

        mobileActions.requestMobileBillListAndNextMobileBill();
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        const { router, routingInfo } = this.props;
        const nextSelectedBillType = nextProps.mobileBill.selectedBillType;

        if (this.isNavigatingAwayFromLastBill(nextSelectedBillType) && routingInfo.params.panel === 'last') {
            router.push(routingInfo.routes[0].path);
        }
    }

    renderHero() {
        return (
            <div className="hero-content u-hidden-print">
                <div className="hero-content-inner">
                    <div className="o-layout">
                        <div className="o-layout__item">
                            <h1 className="c-heading-alpha">Your bills</h1>
                        </div>
                    </div>
                    <div className="o-layout mobile-bill-period">
                        <div className="o-layout__item">
                            <div className="mobile-bill-period__select">
                                <span className="mobile-bill-period__label">
                                    <label className="c-form-label c-form-label--mobile-billing">Bill date:</label>
                                </span>
                                <span className="mobile-bill-period__input">
                                    <div className="c-form-select c-form-select--mobile-transparent c-form-select--mobile-billing">
                                        <select className="c-form-select__dropdown"
                                            data-tracking="true"
                                            data-tracking-label="select-date"
                                            data-tracking-description="select-date"
                                            onChange={this.onMobileBillingIdChanged}
                                            value={getBillingId(this.props)} >
                                            {this.renderMobileBillOptions()}
                                        </select>
                                    </div>
                                </span>
                            </div>
                        </div>
                        <div className="o-layout__item mobile-bill-period__account-number PII">Account no. {this.props.mobileBill.accountNumber}</div>
                    </div>
                </div>
            </div>
        );
    }

    renderPrintHero() {
        const { fromDate, toDate } = dig(this.props, 'mobileBill', 'selectedBill') || {};
        const formatDate = createDateFormatter('D MMMM YYYY');

        return (
            <div className="u-padding-left">
                <h2 className="c-heading-bravo u-text-left u-visible-print">Sky Mobile</h2>
                <div className="u-visible-print c-text-lead">{formatDate(fromDate)} - {formatDate(toDate)}</div>
            </div>
        );
    }

    render() {
        return (
            <section className={classnames('o-container', this.props.heroClassNames)} data-tracking-pod="hero">
                {this.renderHero()}
                {this.renderPrintHero()}
            </section>
        );
    }

    renderMobileBillOptions() {
        const formatDate = createDateFormatter('D MMM');

        if (this.props.mobileBill && this.props.mobileBill.bills) {
            const bills = this.props.mobileBill.bills;
            return bills.map((bill, idx) => {
                return (
                    <option key={idx} value={bill.billingId}>
                        {formatDate(bill.statementDate)}
                    </option>
                );
            });
        }
    }

    onMobileBillingIdChanged(event) {
        const billingId = event.target.value;
        const bill = R.find(R.propEq('billingId', billingId), this.props.mobileBill.bills);
        const lastBillingId = this.props.mobileBill.lastBillingId;

        if (bill.loaded) {
            this.props.mobileActions.mobileBillingIdChanged({ billingId, lastBillingId });
        } else {
            this.props.mobileActions.setMobileBillLoading();
            this.props.mobileActions.requestMobileBill({ billingId, lastBillingId });
        }
    }

    isNavigatingAwayFromLastBill(nextSelectedBillType) {
        const currentlySelectedBillType = this.props.mobileBill.selectedBillType;
        return currentlySelectedBillType === MobileBillTypes.LAST && nextSelectedBillType !== MobileBillTypes.LAST;
    }
}

function mapStateToProps({ mobileBill }) {
    return {
        mobileBill
    };
}

function mapDispatchToProps(dispatch) {
    return {
        mobileActions: MobileActionCreator(dispatch)
    };
}

export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(HeroMobileBill));
