const config = require('../../config');

module.exports = {
    scriptLoader: {
        template: `
            <script type="text/javascript" src="${config.endpoints.scriptLoader}?c=sky-pages-{consumer}"></script>
        `,
        context: {
            consumer: '{page.breadcrumb[0]}'
        }
    }
};
