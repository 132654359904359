import * as R from 'ramda';
import fetch from './fetch-json';

const {
    endpoints: { retriever },
    settings: { relatedTilesCount }
} = require('../../config/public');

const makeLinkTile = ({ uuid, url, text, area, slug, theme, title, tileImage, tileTemplate, sponsor, brand, channel }) => ({
    action: 'link',
    uuid,
    url,
    text,
    area,
    slug,
    theme,
    title,
    tileImage,
    tileTemplate,
    sponsor,
    channel,
    brand
});

export const isRegularContentTile = ({ gallerySlug, slug, collectionSlug, panelTemplate, size }) => {
    const isContentPanel = ['media', 'movie'].some(template => template === panelTemplate);
    const isGalleryTile = Boolean(gallerySlug && slug);
    const isNotCollectionsTile = typeof collectionSlug === 'undefined';
    const isNotMovieSearchTile = size !== 'extrasmall';
    return (isContentPanel || isGalleryTile) && isNotCollectionsTile && isNotMovieSearchTile;
};

export const getCtasAndEpisodes = ({ uuid, serviceId: serviceIds, regionalInfo = {} }, fetchJson = fetch) => {
    const { bouquet, sub_bouquet: subBouquet } = regionalInfo;
    const queryParams = { serviceIds, bouquet, subBouquet };
    const url = `${retriever}/data/${uuid}`;
    return fetchJson(url, queryParams).then(data => {
        return data || Promise.reject();
    });
};

export const getRelatedTiles = ({ uuid, regionalInfo = {} }, fetchJson = fetch) => {
    const { bouquet, sub_bouquet: subBouquet } = regionalInfo;
    const queryParams = { total: relatedTilesCount, bouquet, subBouquet };
    const url = `${retriever}/related-tiles/${uuid}`;
    return fetchJson(url, queryParams).then(related => {
        return Array.isArray(related) ? { related: related.map(makeLinkTile) } : Promise.reject();
    });
};

export const getProgrammeIdentifier = ({ uuid, serviceId, eid }) => {
    return uuid || (serviceId && eid && (serviceId + eid));
};

export const getProgrammeContent = ({ programmes = {}, uuid, serviceId, eid }) => {
    const programmeIdentifier = getProgrammeIdentifier({ uuid, serviceId, eid });
    return programmes[programmeIdentifier] || {};
};

export const getProgrammeUuidFromTileData = ({ uuid, episodes }) => {
    if (uuid) {
        const isSeriesUuid = /^series/.test(uuid);
        const isProgrammeUuid = /^programme/.test(uuid);
        const isSeasonUuid = /^season/.test(uuid);
        const hasEpisodes = R.path(['0', 'episodes', '0', 'uuid'], episodes);
        if (isSeriesUuid && hasEpisodes) {
            return episodes[0].episodes[0].uuid;
        } else if (isProgrammeUuid) {
            return uuid.replace('programme:', '');
        } else if (isSeasonUuid) {
            return uuid.replace('season:', '');
        }
    }
};
