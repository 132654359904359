const React = require('react');
const R = require('ramda');
const classNames = require('classnames');

const { ViewModes } = require('../../../shared/enums');
const TileTypes = require('./tile-types');
const TileHandler = require('../handlers/tile-handler').default;

const createTileComponent = R.curry(({ routingInfo, channelSponsor, panelStatus, viewMode, location, resultClicked, experiments }, tileData) => {
    return (
        <TileHandler
            tile={tileData}
            index={tileData.index}
            routingInfo={routingInfo}
            channelSponsor={channelSponsor}
            viewMode={viewMode}
            panelStatus={panelStatus}
            key={`tile-${tileData.index}`}
            resultClicked={resultClicked}
            location={location}
            experiments={experiments} />
    );
});

function getDesktopRowTileLimit(size) {
    switch (size) {
    case TileTypes.EXTRASMALL:
        return 4;
    case TileTypes.SMALL:
        return 3;
    case TileTypes.MEDIUM:
        return 2;
    case TileTypes.LARGE:
        return 1;
    default:
        break;
    }
}

function getRowTileLimit(viewMode, size) {
    switch (viewMode) {
    case ViewModes.MOBILE:
        return size === TileTypes.EXTRASMALL ? 2 : 1;
    case ViewModes.TABLET:
        return size === TileTypes.LARGE ? 1 : 2;
    case ViewModes.DESKTOP:
        return getDesktopRowTileLimit(size);
    default:
        break;
    }
}

function calculateRows(viewMode, tiles) {
    if (tiles.length === 0) {
        return [];
    }

    const [row, remainder] = R.splitAt(getRowTileLimit(viewMode, tiles[0].size), tiles);
    if (remainder.length > 0) {
        return [row].concat(calculateRows(viewMode, remainder));
    } else {
        return [row];
    }
}

function tilesAndPanel(rows, props, panelData = {}) {
    const { routingInfo, viewMode, children, userProfile, panelActions, panelStatus, panelResize, location } = props;
    const createTile = createTileComponent(props);

    return rows.reduce((result, row) => {
        let resultData = result;
        if (!panelData.isAppView) {
            resultData = resultData.concat(row.map(createTile));
        }

        if (routingInfo.params.slug && R.find(R.eqProps('slug', routingInfo.params), row)) {
            resultData = resultData.concat(React.cloneElement(children, {
                key: panelData.slug,
                viewMode,
                panelData,
                userProfile,
                panelActions,
                status: panelStatus,
                resize: panelResize,
                routingInfo,
                location
            }));
        }

        return resultData;
    }, []);
}

module.exports = props => {
    const { routingInfo, tiles, viewMode, fluidContainer = true, panelClasses } = props;
    const { movieTitle } = routingInfo.params;
    const panelData = movieTitle ? R.clone(tiles.find(tile => tile.slug === movieTitle)) : R.clone(R.find(R.eqProps('slug', routingInfo.params), tiles));
    const panelDataWithPanelClasses = { ...panelData, panelClasses };

    const containerClasses = classNames(
        'c-tile__container c-tile-fluid-container',
        {
            'o-container ': fluidContainer
        }
    );

    const layoutClasses = classNames(
        'o-layout',
        {
            'o-layout--center': props.centered
        }
    );

    return (
        <div className={containerClasses}>
            <section className={layoutClasses} key="tile-container">
                {(tilesAndPanel(calculateRows(viewMode, tiles), props, panelDataWithPanelClasses))}
            </section>
        </div>
    );
};
