export const billSnippetInternals = `
  billSnippetAccounts: billingAccount(type:triplePlay) {
    preferences {
      paymentDueDay {
        currentDay
      }
    }
    inventory {
      broadband {
        status
      }
      tv {
        status
      }
      talk {
        status
      }
    }
    currency
    type
    bills(latest: true) {
      paymentDueDate
      statementDate
      total
    }
  }
  mobileSnippetAccounts: billingAccount(type: mobile) {
      type
      currency
      bills: predictedBills(nextBill: true) {
          firstBillIssued
          total
          statementDate
          paymentDueDate
      }
  }
`;

export const billSnippetQuery = `
{
  me {
    ${billSnippetInternals}
  }
}
`;

export const splitBillSnippetQuery = `
{
  me {
    billSnippetAccounts: billingAccount {
      preferences {
        paymentDueDay {
          currentDay
        }
      }
      inventory {
        broadband {
          status
        }
        tv {
          status
        }
        talk {
          status
        }
      }
      type
      bills(latest: true) {
        paymentDueDate
        statementDate
      }
    }
  }
}
`;

