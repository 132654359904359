const { easeOutCubic } = require('./easing');

function updateHeight(element, height) {
    element.style.height = `${height}px`;
}

function animateHeight(element, from, to, duration, callback) {
    let startTime;

    const tick = timestamp => {
        startTime = startTime || timestamp;
        const current = timestamp - startTime;

        if (current > duration) {
            updateHeight(element, from + to);
            return callback();
        }

        updateHeight(element, easeOutCubic(current, from, to, duration));
        window.requestAnimationFrame(tick);
    };

    window.requestAnimationFrame(tick);
    updateHeight(element, from);
}

module.exports = animateHeight;
