import React from 'react';

export default class DefaultLoadingComponent extends React.PureComponent {
    render() {
        const { pastDelay } = this.props;

        return (
            <div className="tile-loading u-text-center">
                <section className="tile-content__constrain u-text-center u-padding-all-large">
                    {pastDelay ? <strong className="c-spinner u-padding-all-large" role="progressbar"></strong> : null }
                </section>
            </div>
        );
    }
}
