const R = require('ramda');

const { BroadbandDataActions } = require('../../shared/enums');

const initialState = {};

function validateState(prop, action, state) {
    const newState = {};

    if (!prop) {
        return state;
    }

    if (!action.data && !state[prop]) {
        newState[`${prop}Errors`] = ['No data received'];
        return R.merge(state, newState);
    }

    if (action.data) {
        newState[prop] = action.data;
        if (action.data.newState) {
            newState[`${prop}Errors`] = action.data.errors;
        }

        return R.merge(state, newState);
    }
}

module.exports = (state = initialState, action) => {
    const actions = {};
    actions[BroadbandDataActions.REQUEST_ROUTER_STATUS] = 'routerStatus';
    actions[BroadbandDataActions.REQUEST_SYNC_RATE] = 'syncRate';
    actions[BroadbandDataActions.REQUEST_ACTIVATION_DATE] = 'activationDate';
    actions[BroadbandDataActions.HAS_BROADBAND] = 'hasBroadband';
    actions[BroadbandDataActions.REQUEST_BROADBAND_ACCOUNT_INFO] = 'info';
    actions[BroadbandDataActions.SEND_SLOW_SPEED_ANALYTICS] = 'sendSlowSpeedAnalytics';
    actions[BroadbandDataActions.REQUEST_CUSTOMER_TYPE] = 'getCustomerType';

    return validateState(actions[action.type], action, state);
};
