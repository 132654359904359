const { PanelActions } = require('../../shared/enums');

module.exports = {
    setStatus(status) {
        return {
            type: PanelActions.UPDATE_PANEL_STATUS,
            status
        };
    },
    resizeTriggered() {
        return {
            type: PanelActions.PANEL_RESIZE_COMPLETE
        };
    }
};
