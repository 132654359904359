import React from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'ramda';

import mapping from './templates';
import templateProvider from '../shared/template-provider';

class FooterText extends React.Component {
    render() {
        const { footerData = {}, template = '' } = this.props;

        if (isEmpty(footerData)) {
            return null;
        }

        const Template = templateProvider(mapping, template);

        if (Template) {
            return (
                <Template {...this.props} />
            );
        }

        return null;
    }
}

FooterText.propTypes = {
    template: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.func
    ])
};

export default FooterText;
