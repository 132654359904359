const { NotificationActions } = require('../../shared/enums');

const R = require('ramda');

const initialState = {};

module.exports = (state = initialState, action) => {
    const { template, props, uuid } = action;
    const setState = data => R.assoc(uuid, data, state);

    switch (action.type) {
    case NotificationActions.UPDATE:
        return setState({ ...state[uuid], template, props });
    default:
        return state;
    }
};
