const React = require('react');
const ReactDOM = require('react-dom');
const PropTypes = require('prop-types');

class Video extends React.Component {
    play() {
        this.videoElement.play();
    }

    pause() {
        this.videoElement.pause();
    }

    reload() {
        this.videoElement.load();
    }

    audioToggle() {
        this.videoElement.muted = !this.videoElement.muted;
    }

    muted() {
        return this.videoElement.muted;
    }

    componentDidMount() {
        this.videoElement = ReactDOM.findDOMNode(this);
    }

    render() {
        let sources = [];

        if (this.props.videoSources && this.props.videoSources.length > 0) {
            sources = this.props.videoSources.map(source => {
                return (
                    <source
                        src={source.url}
                        key={source.url}
                        type={source.type} />
                );
            });
        } else {
            console.warn('No video sources found'); // eslint-disable-line no-console
        }

        return (
            <video
                id={this.props.id}
                className="c-video"
                autoPlay={this.props.autoPlay}
                loop={this.props.loop}
                preload={this.props.preload}
                muted={this.props.muted}
                controls={this.props.controls}
                poster={this.props.poster}
                onPlay={this.props.handlePlay}
                ref={e => this.videoElement = e}>
                {sources}
            </video>
        );
    }
}

Video.propTypes = {
    autoPlay: PropTypes.bool,
    muted: PropTypes.bool,
    loop: PropTypes.bool,
    preload: PropTypes.string,
    controls: PropTypes.bool,
    handlePlay: PropTypes.func
};

Video.defaultProps = {
    autoPlay: true,
    muted: true,
    loop: true,
    preload: 'none',
    controls: false
};

module.exports = Video;
