import { TWITTER_REQUEST_ERROR, TWITTER_REQUEST_SUCCESS } from '../constants/action-types';
import * as R from 'ramda';

const initialState = {
    tweets: []
};

export default function(state, action) {
    var stateToUse = {}; // eslint-disable-line
    if (!state) {
        stateToUse = initialState;
    } else {
        stateToUse = state;
    }

    switch (action.type) {
    case TWITTER_REQUEST_SUCCESS:
        return R.merge(stateToUse, {
            tweets: action.data
        });
    case TWITTER_REQUEST_ERROR:
        return R.merge(stateToUse, {
            error: action.error
        });
    default:
        return stateToUse;
    }
}
