import billSnippetActionCreator from './actions/bill-snippet-action-creator';
import getReducers from './reducers';

const actions = {
    billSnippetActionCreator
};

export {
    actions,
    getReducers
};

export { default as BillSnippetManage } from './components/manage';
export { default as BillSnippetManageHeader } from './components/manage-header';
export { default as BillSnippetDashboard } from './components/dashboard';
