const moment = require('moment');

function createDateTimeFormatter(format) {
    return function(dateTimeString) {
        return moment(dateTimeString, 'YYYY-MM-DDTHH:mm:ss').format(format);
    };
}

function formatCurrency(number, currency = 'GBP') {
    const currencySymbols = {
        GBP: '£',
        USD: '$',
        EUR: '€'
    };
    const sign = number < 0 ? '-' : '';
    const currencySymbol = currencySymbols[currency] || '£';
    return `${sign}${currencySymbol}${Math.abs(number).toFixed(2)}`;
}

module.exports = { createDateTimeFormatter, formatCurrency };
