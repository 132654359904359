import * as R from 'ramda';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import classNames from 'classnames';
import cookie from 'js-cookie';
import { hot } from 'react-hot-loader';

import RouteHelper from 'shared/routes/helper';
import { Cookies } from 'shared/constants';
import { generateMetaData, setMetaTags } from 'shared/seo/meta-tags';

import utils from 'client/utils';
import Tracking from 'client/tracking';
import WindowEventsActionCreator from 'client/actions/window-events-action-creator';
import WindowEvents from 'client/utils/window-events';
import PanelActionCreator from 'client/actions/panel-action-creator';
import { shouldTrackPageUpdate } from 'client/tracking/utils';
import sharedLayouts from 'shared/components/layouts/templates';

import templateProvider from '../../../src/components/shared/template-provider';

class PagesApp extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            hasMedia: false,
            windowEvents: [],
            hasOSM: false
        };

        this.engine = require('shared/pages').default;
        this.hasMedia = this.hasMedia.bind(this);
        this.hasOSM = this.hasOSM.bind(this);
        this.trackPageView = this.trackPageView.bind(this);
        this.getPageTemplate = this.getPageTemplate.bind(this);

        this.onTabFocus = this.onTabFocus.bind(this);
        this.onBlur = this.onBlur.bind(this);
    }

    onTabFocus(evt) {
        if (!evt.target) {
            return;
        }

        if (evt.target.focusDelegate) {
            evt.target.focusDelegate.classList.add('has-focus');
        } else if (evt.target.classList) {
            evt.target.classList.add('has-focus');
        }
    }

    onBlur(evt) {
        if (!evt.target) {
            return;
        }

        if (evt.target.focusDelegate) {
            evt.target.focusDelegate.classList.remove('has-focus');
        } else if (evt.target.classList) {
            evt.target.classList.remove('has-focus');
        }
    }

    componentDidUpdate(prevProps) {
        if (shouldTrackPageUpdate(prevProps, this.props)) {
            this.trackPageView('onUpdate');
        }

        setMetaTags(generateMetaData(this.props, this.props));
    }

    componentDidMount() {
        const resizeEvent = this.props.windowActions.listenToResize(window);
        const [, handleResize] = resizeEvent;

        handleResize();

        this.setState({
            windowEvents: this.state.windowEvents.concat([resizeEvent]),
            isMobileApp: Boolean(cookie.get(Cookies.MOBILE_APP_ID))
        });

        utils.postRender(WindowEvents.emitContentLoaded.bind(WindowEvents));

        WindowEvents.addTabFocusListener(this.onTabFocus);
        WindowEvents.addBlurListener(this.onBlur);

        this.trackPageView('onMount');
    }

    componentWillUnmount() {
        this.state.windowEvents.forEach(([name, event]) => {
            window.removeEventListener(name, event);
        });

        WindowEvents.removeTabFocusListener(this.onTabFocus);
        WindowEvents.removeBlurListener(this.onBlur);
    }

    hasMedia() {
        this.setState({ hasMedia: true });
    }

    hasOSM() {
        this.setState({ hasOSM: true });
    }

    trackPageView(trackingType) {
        const area = RouteHelper.getArea(this.props);
        const { params, tiles, userProfile, galleries, experiments, adobeTarget } = this.props;
        const shouldTrack = R.pathOr(R.T, [area, 'allowDefaultPolarisTrack', trackingType], this.engine.routes);

        Tracking.setExperiments(experiments);
        Tracking.setDTMExperiments(experiments);

        if (shouldTrack(this.props)) {
            Tracking.pageLoad({ area, locationParams: params, tiles, userProfile, galleries, adobeTarget });
        }
    }

    getPageTemplate() {
        const layouts = this.engine.composeLayouts(sharedLayouts);

        return templateProvider(layouts, this.props.pageTemplate);
    }

    render() {
        const { params, routes, route, search, location, page } = this.props;
        const [{ app }] = routes;

        const routingInfo = {
            engine: this.engine,
            app: this.engine.getApplication(app),
            area: route.area,
            channel: this.props.params.channel,
            params,
            routes,
            search,
            location,
            page
        };

        const appClasses = classNames([
            'polaris-main',
            {
                'has-media': this.state.hasMedia,
                'is-mobile-app': this.state.isMobileApp,
                'has-osm': this.state.hasOSM
            }
        ]);

        const Template = this.getPageTemplate();

        return (
            <div className={appClasses}>
                <Template {...this.props} hasMedia={this.hasMedia} hasOSM={this.hasOSM} routingInfo={routingInfo} />
            </div>
        );
    }
}

const mapStateToProps = ({ view, tiles, adobeTarget, hero, footerText, userProfile, experiments, tracking, channel, panel, search, actionTiles, pageTemplate, galleries, accordion, page, tilesAlignment, metaData }) => {
    return {
        viewMode: view.viewMode,
        adobeTarget,
        tiles,
        accordion,
        hero,
        footerText,
        channelSponsor: hero.sponsor ? hero.sponsor.name : null,
        userProfile,
        experiments,
        tracking,
        channel,
        panelStatus: panel.status,
        panelResize: panel.doResize,
        search,
        actionTiles,
        page,
        pageTemplate,
        galleries,
        tilesAlignment,
        metaData
    };
};

const mapDispatchToProps = dispatch => {
    return {
        panelActions: bindActionCreators(PanelActionCreator, dispatch),
        windowActions: WindowEventsActionCreator(dispatch)
    };
};

export default hot(module)(connect(
    mapStateToProps,
    mapDispatchToProps
)(PagesApp));
