if (!global._babelPolyfill) {
    require('@babel/polyfill');
}

const env = Object.assign({}, process.env);
const trim = str => str.trim();

const get = (key, defaultVal) => (env[key] || defaultVal);
const getInt = (key, defaultVal = undefined, radix = 10) => {
    const parsed = parseInt(get(key), radix);
    return Number.isInteger(parsed) ? parsed : defaultVal;
};
const getArray = (key, defaultVal = [], separator = ',') => {
    const parsed = get(key, '');
    return parsed ? parsed.split(separator).map(trim) : defaultVal;
};

const isTruthy = (key) => (get(key) === 'true');

const getBase64Auth = (username, password) => {
    return new Buffer(`${username}:${password}`).toString('base64');
};

module.exports = {
    get,
    getInt,
    getArray,
    isTruthy,
    getBase64Auth
};
