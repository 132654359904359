import React from 'react';
import { Tile } from '@sky-uk/toolkit-react';

export default (props) => {
    const { alt_text: altText, source } = props;

    return (
        <div className="u-margin-bottom-small">
            <Tile full
                square={false}
                image={{
                    src: source.url,
                    alt: altText
                }}
            />
        </div>
    );
};
