module.exports = function(mapping, name) {
    const templateNames = Object.keys(mapping);

    if (name && (templateNames.indexOf(name) > -1)) {
        return mapping[name];
    } else {
        console.error('Template not found', name, 'in', templateNames); // eslint-disable-line no-console
        return null;
    }
};
