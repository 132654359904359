import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';

import billSnippetActionCreator from 'shared/components/organisms/bill-snippet/actions/bill-snippet-action-creator';
import { getBillURLByAccountType, getPaymentDueLabel } from 'shared/components/organisms/bill-snippet/helper';


class BillSnippetManage extends React.Component {
    static propTypes = {
        accountType: PropTypes.string
    }

    static defaultProps = {
        accountType: 'triplePlay'
    }

    componentDidMount() {
        const { billSnippetData, billSnippetActions } = this.props;
        if (!billSnippetData || !Array.isArray(billSnippetData)) {
            billSnippetActions.requestBillSnippetData();
        }
    }


    render() {
        const accountInfo = Array.isArray(this.props.billSnippetData) && this.props.billSnippetData.find(billingAccount => billingAccount.type === this.props.accountType);

        if (!accountInfo) return null;

        return (
            <React.Fragment>
                <article className="bill-snippet u-margin-top-large u-margin-bottom-large c-title-body u-text-center">
                    <div className="u-padding-bottom u-padding-top">
                        <div className="t-details">
                            <div className="u-margin-bottom-small qa-payment-due c-heading-charlie">
                                {getPaymentDueLabel(accountInfo.paymentDueDate)} <strong>{moment(accountInfo.paymentDueDate).format('Do MMMM')}</strong>
                            </div>
                            <a href={getBillURLByAccountType(accountInfo.type)} className="c-btn c-btn--secondary u-padding-left-large u-padding-right-large">View your bill</a>
                        </div>
                    </div>
                </article>
            </React.Fragment>
        );
    }
}

const mapStateToProps = ({ billSnippetData }) => {
    return {
        billSnippetData
    };
};

const mapDispatchToProps = dispatch => {
    return { billSnippetActions: billSnippetActionCreator(dispatch) };
};

export default connect(mapStateToProps, mapDispatchToProps)(BillSnippetManage);
