const React = require('react');
const Hero = require('../../../../../src/components/hero/hero').default;
const HeroCarousel = require('../../../../../src/components/heroCarousel/hero-carousel').default;
const Tiles = require('../../../../../src/components/tiles/tiles');
const FooterText = require('../../../../../src/components/footer-text/footer-text').default;
const HeroTemplates = require('../../../../enums').Templates.Hero;
const FooterTextTemplates = require('../../../../enums').Templates.FooterText;

export default props => {
    const pathname = props.location && props.location.pathname;
    const shouldDisplayH1 = pathname === '/' || pathname === '/homepage';
    return (
        <div>
            {shouldDisplayH1 ? <h1 className="u-hide-visually">Signed in homepage</h1> : null}
            {Array.isArray(props.hero) ?
                (<HeroCarousel slides={props.hero} />) :
                (<Hero
                    heroData={props.hero}
                    template={props.hero.template || HeroTemplates.DEFAULT}
                    hasMedia={props.hasMedia}
                    routingInfo={props.routingInfo}
                    panelStatus={props.panelStatus}
                    viewMode={props.viewMode} />)
            }
            <Tiles
                experiments={props.experiments}
                location={props.location}
                viewMode={props.viewMode}
                channelSponsor={props.channelSponsor}
                tiles={props.tiles}
                routingInfo={props.routingInfo}
                userProfile={props.userProfile}
                panelStatus={props.panelStatus}
                panelResize={props.panelResize}
                panelActions={props.panelActions}>
                {props.children}
            </Tiles>
            <FooterText
                footerData={props.footerText}
                template={props.footerText.template || FooterTextTemplates.DEFAULT} />
        </div>
    );
};
