import React from 'react';
import ReactDOM from 'react-dom';
import { Router, browserHistory, createMemoryHistory } from 'react-router';
import { applyMiddleware, createStore, combineReducers, compose } from 'redux';
import { Provider } from 'react-redux';
import { syncHistoryWithStore, routerReducer, routerMiddleware } from 'react-router-redux';
import thunk from 'redux-thunk';
import Loadable from 'react-loadable';

import config from 'config';
import PagesEngine from 'shared/pages';
import RouteHelper from 'shared/routes/helper';
import reducers from 'client/reducers';

import onReady from './utils/ready';
import { preRender } from './render-hooks';
import optimizelyWrapper from 'shared/ab-testing/optimizely';

if (process.env.WEBPACK_BUILD) {
    require('./main.scss');
}

const setupStore = (state, routerProps) => {
    const reduxRouterMiddleware = routerMiddleware(browserHistory);
    const devTools = window.devToolsExtension ? window.devToolsExtension() : noop => noop;

    const { routes: [{ app }] } = routerProps;
    const pagesApp = PagesEngine.getApplication(app);

    return createStore(
        combineReducers({
            ...reducers({}),
            routing: routerReducer
        }),
        state,
        compose(
            pagesApp.getStoreEnhancer(),
            applyMiddleware(
                ...pagesApp.getMiddleware(),
                reduxRouterMiddleware,
                thunk
            ),
            devTools
        )
    );
};

const setupHistory = (store, routerProps) => {
    if (store.getState().staticRouter === true) {
        return createMemoryHistory(routerProps.location.pathname);
    }

    return syncHistoryWithStore(browserHistory, store);
};

const renderApp = async ({ rootElement, store, history, routes, postRender }) => {
    await Loadable.preloadReady();
    ReactDOM.hydrate(
        (
            <Provider store={store}>
                <Router history={history} routes={routes} />
            </Provider>
        ),
        rootElement,
        postRender
    );
};

const boot = (rootElement, reactRoutes, { state, routerProps }) => {
    const store = setupStore(state, routerProps);
    const history = setupHistory(store, routerProps);
    preRender(RouteHelper.getArea(routerProps), store.getState());
    return new Promise((resolve) => {
        renderApp({
            rootElement,
            store,
            history,
            routes: reactRoutes,
            postRender: () => resolve()
        });
    });
};

const bootOnReady = () => {
    const dataElement = document.getElementById('initialData');
    const rootElement = document.getElementById('polaris');
    const initialData = JSON.parse(decodeURIComponent(dataElement.text));
    const { apps } = initialData;

    optimizelyWrapper.update(config.optimizelyDatafileURL);

    PagesEngine.ensureLoaded(apps).then(onReady()).then(() => {
        return boot(
            rootElement,
            PagesEngine.getReactRoutesForApps(apps, window.location.hostname, initialData.state),
            initialData
        );
    }).catch((error) => {
        console.error('Something went wrong loading apps:', error);
    });
};

export default {
    boot,
    bootOnReady,
    renderApp
};
