import React from 'react';
import PropTypes from 'prop-types';
import { Icon } from '@sky-uk/toolkit-react';

import { formatNumberTypeNameAndNumbers } from '../bill-info-block/helper';

export default class AccountInfoItem extends React.PureComponent {
    static propTypes = {
        numberTypeName: PropTypes.string,
        numbers: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])),
        iconSource: PropTypes.string,
        suppressPluralisation: PropTypes.bool
    }

    render() {
        const { numberTypeName, numbers, iconSource, suppressPluralisation, children, className } = this.props;
        if ((!numbers || numbers.filter(Boolean).length === 0) && !children) return null;
        return (
            <div className={`account-info-block__unit ${className}`}>
                <div className="icon-content-pair">
                    <div className="account-info-icon-wrapper u-padding-all-small icon-content-pair__icon">
                        <Icon cssClassName="account-info-icon" svgSource={ iconSource } size="20" />
                    </div>
                    <div className="icon-content-pair__content">
                        <span className="u-hide-visually PII" data-test-id="qa-account-info">{formatNumberTypeNameAndNumbers({ numberTypeName, numbers, suppressPluralisation })}</span>
                        {numberTypeName && <h2 className="u-margin-bottom-none c-text-lead" aria-hidden="true">{numberTypeName}{!suppressPluralisation && numbers.length > 1 && 's'}:</h2>}
                        { children || <p className="u-text-bold u-margin-bottom-none PII" aria-hidden="true">{numbers.join(', ')} </p> }
                    </div>
                </div>
            </div>
        );
    }
}
