const React = require('react');
const ReactDOM = require('react-dom');
const classNames = require('classnames');
const Shine = require('../../../shine/shine');
const HeroMedia = require('../../media/hero-media').default;
const HeroOverlay = require('../../overlay/default/hero-overlay').default;
const { ViewModes } = require('../../../../../shared/enums');

const WindowEvents = require('../../../../../client/utils/window-events');

class DefaultHero extends React.Component {
    constructor(props) {
        super(props);

        this.updateShine = this.updateShine.bind(this);
    }

    updateShine() {
        const height = ReactDOM.findDOMNode(this).offsetHeight;
        const x = Math.min((window.pageYOffset / height) * 120, 50);
        this.refs.shine.updateTopPosition(x - 10, 50);
        this.refs.shine.updateBottomPosition(110 - x, 50);
    }

    componentWillUnmount() {
        WindowEvents.removeScrollListener(this.updateShine);
    }

    componentDidMount() {
        WindowEvents.addScrollListener(this.updateShine);
    }

    render() {
        const { heroData, onImageError, heroClassNames, hasMedia, panelStatus, routingInfo, moviesSearch, viewMode } = this.props;
        const data = (viewMode === ViewModes.MOBILE) && heroData.mobile || heroData;

        if (heroData.mobile) {
            data.image = heroData.image;
        }

        const heroInnerClassNames = classNames('hero-inner',
            { 'hero-inner--square': viewMode === ViewModes.MOBILE && Boolean(heroData.mobile) }
        );

        return (
            <section className={heroClassNames} data-tracking-pod="hero">
                <div className={heroInnerClassNames}>
                    <div className="hero-media-wrapper">
                        <HeroMedia data={data}
                            hasMedia={hasMedia}
                            panelStatus={panelStatus}
                            onImageError={onImageError}
                            viewMode={viewMode}
                        />
                    </div>
                    <HeroOverlay
                        data={data}
                        routingInfo={routingInfo}
                        moviesSearch={moviesSearch}
                        viewMode={viewMode} />
                </div>
                <Shine ref="shine" />
            </section>
        );
    }
}

module.exports = DefaultHero;
