const cookie = require('js-cookie');
const { Cookies } = require('../../shared/constants');

const sessionToken = (cookieLibrary = cookie) => {
    return cookieLibrary.get(Cookies.OAUTH_TOKEN) || cookieLibrary.get(Cookies.SSO_SECURE_TOKEN) || cookieLibrary.get(Cookies.SSO_TOKEN);
};

const sessionTokenIncludingLongLived = (cookieLibrary = cookie) => {
    return sessionToken(cookieLibrary) || cookieLibrary.get(Cookies.SSO_LONG_TOKEN);
};

const getUuid = (cookieLibrary = cookie) => {
    return cookieLibrary.get(Cookies.UUID);
};

module.exports = {
    sessionToken,
    sessionTokenIncludingLongLived,
    getUuid
};
