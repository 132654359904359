/* eslint-disable no-restricted-modules */
// Please refactor this file to use axios instead of any other HTTP request libraries when you next get the chance to change this file.

const xmlHttpReq = require('xhr');
const cookie = require('js-cookie');

const { Cookies: { SSO_SECURE_TOKEN, SSO_TOKEN, SSO_LONG_TOKEN, OAUTH_TOKEN } } = require('../../shared/constants');
const config = require('../../config');

const GRAPHQL_ENDPOINT = config.endpoints.graphql;

const graphQLService = {
    getEndpoint() {
        return GRAPHQL_ENDPOINT;
    },

    getToken() {
        return cookie.get(OAUTH_TOKEN) ||
            cookie.get(SSO_SECURE_TOKEN) ||
            cookie.get(SSO_TOKEN) ||
            cookie.get(SSO_LONG_TOKEN);
    },

    // DEPRECATED: Method is deprecated, please use executeGet or executePost instead - will remove soon
    executeQuery(graphqlQuery, graphqlToken, variables, method = 'GET', xhr = xmlHttpReq) {
        const url = `${GRAPHQL_ENDPOINT}?query=${encodeURIComponent(graphqlQuery)}${
            variables ? `&variables=${JSON.stringify(variables)}` : ''
        }`;
        return this.query({
            url,
            method,
            useXDR: true,
            headers: {
                Authorization: `Bearer ${this.getToken()}`,
                Accept: 'application/json',
                'x-api-token': graphqlToken,
                'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
            }
        }, xhr);
    },

    executePost(graphqlQuery, graphqlToken, operationName = null, variables = null, xhr = xmlHttpReq, correlationId) {
        const body = { query: graphqlQuery };

        if (operationName) {
            body.operationName = operationName;
        }
        if (variables) {
            body.variables = variables;
        }

        return this.query({
            url: GRAPHQL_ENDPOINT,
            method: 'POST',
            useXDR: true,
            headers: {
                Authorization: `Bearer ${this.getToken()}`,
                Accept: 'application/json',
                'x-api-token': graphqlToken,
                'Content-Type': 'application/json; charset=UTF-8',
                'Sky-Domain': 'trading',
                ...(correlationId ? { 'x-sky-call-id': correlationId } : {})
            },
            body: JSON.stringify(body)
        }, xhr);
    },

    executeGet(graphqlQuery, graphqlToken, xhr = xmlHttpReq) {
        return this.query({
            url: `${GRAPHQL_ENDPOINT}?query=${encodeURIComponent(graphqlQuery)}`,
            method: 'GET',
            useXDR: true,
            headers: {
                Authorization: `Bearer ${this.getToken()}`,
                Accept: 'application/json',
                'x-api-token': graphqlToken,
                'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
            }
        }, xhr);
    },

    query(request, xhr = xmlHttpReq) {
        return new Promise((resolve, reject) => {
            xhr(request, (error, response, body) => {
                if (error || !(response.statusCode >= 200 && response.statusCode <= 299) || !body) {
                    const errorToReturn = new Error(`GraphQL Request failed - response code ${response && response.statusCode}, response body: ${(response && response.body) && response.body}`);
                    errorToReturn.details = response;
                    return reject(error || errorToReturn);
                }

                resolve(JSON.parse(body));
            });
        });
    }
};

module.exports = graphQLService;
