/* eslint camelcase: 0*/
import * as R from 'ramda';
import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import ArticleText from './components/article-text';
import ArticleImage from './components/article-image';
import ArticleVideo from './components/article-video';
import ArticleGallery from 'shared/components/molecules/gallery';

const defaultRenderers = {
    text: ArticleText,
    image: ArticleImage,
    video: ArticleVideo,
    gallery: ArticleGallery
};

class ArticleLayout extends React.Component {
    getRenderers() {
        return R.merge(defaultRenderers, this.props.renderers || {});
    }

    renderSections(sections) {
        return sections.map((section, index) => {
            const type = Object.keys(section).find((key) => {
                return section[key].enabled;
            });

            const Renderer = this.getRenderers()[type];

            if (Renderer) {
                return (
                    <div key={`section-${index}`} className={`section-${index}`}>
                        <Renderer {...section[type]} />
                    </div>
                );
            } else {
                return null;
            }
        });
    }

    render() {
        const { sections } = this.props;

        return (
            <div className={classnames(this.props.cssClassName)}>
                {sections && this.renderSections(sections)}
                {this.props.children}
            </div>
        );
    }
}

ArticleLayout.propTypes = {
    sections: PropTypes.array,
    cssClassName: PropTypes.string
};

export default ArticleLayout;
