
import PolarisDefault from './default';
import HeroMaintenanceMessage from './hero-message/maintenance';
import Article from './article';

import { Templates } from 'shared/enums';

const Polaris = Templates.Page;

export default {
    [Polaris.DEFAULT]: PolarisDefault,
    [Polaris.MAINTENANCE]: HeroMaintenanceMessage,
    [Polaris.ARTICLE]: Article
};
