/**
 * When given the TriplePlay inventory object from a user's billing account (usually retrieved from GraphQL)
 * produces a string that reflects the account's current TriplePlay product combination.
 */

const isActive = status => {
    return status === 'ACTIVE' || status === 'PENDING_CANCEL' || status === 'AWAITING_ACTIVATION';
};

const hasTV = tv => {
    return tv && isActive(tv.status);
};

const hasBroadband = broadband => {
    return broadband && isActive(broadband.status);
};

const hasTalk = talk => {
    return talk && isActive(talk.status);
};

const fullBundle = ({ tv, broadband, talk }) => {
    return {
        match: hasTV(tv) && hasBroadband(broadband) && hasTalk(talk),
        message: 'TV, Broadband & Talk'
    };
};

const tvAndBroadbandBundle = ({ tv, broadband, talk }) => {
    return {
        match: hasTV(tv) && hasBroadband(broadband) && !hasTalk(talk),
        message: 'TV & Broadband'
    };
};

const tvAndTalkBundle = ({ tv, broadband, talk }) => {
    return {
        match: hasTV(tv) && !hasBroadband(broadband) && hasTalk(talk),
        message: 'TV & Talk'
    };
};

const broadbandAndTalkBundle = ({ tv, broadband, talk }) => {
    return {
        match: !hasTV(tv) && hasBroadband(broadband) && hasTalk(talk),
        message: 'Broadband & Talk'
    };
};

const tvOnlyBundle = ({ tv, broadband, talk }) => {
    return {
        match: hasTV(tv) && !hasBroadband(broadband) && !hasTalk(talk),
        message: 'TV'
    };
};

const broadbandOnlyBundle = ({ tv, broadband, talk }) => {
    return {
        match: !hasTV(tv) && hasBroadband(broadband) && !hasTalk(talk),
        message: 'Broadband'
    };
};

const talkOnlyBundle = ({ tv, broadband, talk }) => {
    return {
        match: !hasTV(tv) && !hasBroadband(broadband) && hasTalk(talk),
        message: 'Talk'
    };
};

const bundleList = products => {
    return [
        fullBundle(products),
        tvAndBroadbandBundle(products),
        tvAndTalkBundle(products),
        broadbandAndTalkBundle(products),
        tvOnlyBundle(products),
        broadbandOnlyBundle(products),
        talkOnlyBundle(products)
    ];
};

const customerProductsMessage = products => {
    if (products) {
        let productToDisplay = null;

        bundleList(products).forEach(bundle => {
            if (bundle.match) {
                productToDisplay = bundle.message;
            }
        });

        return productToDisplay;
    }
};

module.exports = customerProductsMessage;
