const React = require('react');
const _ = require('ramda');
const Tracking = require('../../../client/tracking');
const Video = require('../video/video');
const { ViewModes } = require('../../../shared/enums');
const { isBrowser, getViewMode } = require('../../../client/utils');

class VideoPlayer extends React.Component {
    constructor(props) {
        super(props);
        this.handlePlay = this.handlePlay.bind(this);
    }

    shouldComponentUpdate(nextProps) {
        return _.equals(nextProps.videoSources, this.props.videoSources);
    }

    componentDidUpdate() {
        if (!this.props.controls && this.determineAutoplay()) {
            this.playVideo();
        } else {
            this.pauseVideo();
        }
    }

    playVideo() {
        this.refs.video.play();
    }

    pauseVideo() {
        this.refs.video.pause();
    }

    trackVideoPlayed() {
        Tracking.videoPlayed(this.props.routingInfo.params.slug);
    }

    handlePlay() {
        this.props.tracking && this.trackVideoPlayed();
    }

    createId(id) {
        const pos = id.indexOf(':') + 1;
        if (pos > 0) {
            return `video-${id.substring(pos)}`;
        } else {
            return `video-${id}`;
        }
    }

    determineAutoplay() {
        const { autoPlay } = this.props;

        if (typeof autoPlay === 'boolean') {
            return autoPlay;
        }

        if (isBrowser()) {
            return getViewMode(window) === ViewModes.MOBILE ? autoPlay.mobile : autoPlay.desktop;
        }
        return autoPlay.desktop;
    }

    render() {
        return (
            <div className="video-player">
                <Video
                    id={this.createId(this.props.id)}
                    className="video"
                    ref="video"
                    type={this.props.type}
                    autoPlay={this.determineAutoplay()}
                    muted={this.props.muted}
                    controls={this.props.controls && this.determineAutoplay() ? this.props.controls : true}
                    loop={this.props.loop}
                    poster={this.props.poster}
                    preload={this.props.preload}
                    width="100%" height="100%"
                    handlePlay={this.handlePlay}
                    videoSources={this.props.videoSources} />
            </div>
        );
    }
}

VideoPlayer.defaultProps = {
    autoPlay: false,
    controls: true,
    muted: false,
    loop: false,
    preload: 'none'
};

module.exports = VideoPlayer;
