import * as R from 'ramda';
import React from 'react';
import { connect } from 'react-redux';
import MobileActionCreator from '../actions/mobile-action-creator';

class MobileSimSelector extends React.Component {
    constructor(props) {
        super(props);
        this.onSimChanged = this.onSimChanged.bind(this);
    }

    onSimChanged(event) {
        const serviceInstanceIdentifier = event.target.value;
        const sim = R.find(
            R.propEq('serviceInstanceIdentifier', serviceInstanceIdentifier),
            this.props.mobileSummaryData.sims
        );
        this.props.mobileActions.simChanged(sim);
    }

    simName = (sim = {}) => {
        const { friendlyName, phoneNumber } = sim;
        if (phoneNumber === 'Cancelled') {
            return 'SIM Cancelled';
        }
        return friendlyName || phoneNumber;
    }

    renderSims(sims, suppressLabels) {
        return sims.map((sim, idx) => {
            const label = sim.status === 'ACTIVE' ? 'You\'re Viewing' : 'Mobile';
            return <option aria-label={`You are viewing ${this.simName(sim)}`} className={`PII qa-current-sim-${idx}`} key={idx} value={sim.serviceInstanceIdentifier}>{!suppressLabels && `${label}: `}{this.simName(sim)}</option>;
        });
    }

    render() {
        const { mobileSummaryData, mobileClass = '', suppressLabels = false } = this.props;
        const sims = mobileSummaryData.sims || [];
        const { currentSim = {} } = mobileSummaryData;
        const label = currentSim.status === 'ACTIVE' ? 'You\'re Viewing' : 'Mobile';

        if (mobileSummaryData.multiSim) {
            return (
                <div className={ `c-form-select c-form-select--mobile-hero ${mobileClass}` }>
                    <select className="c-form-select__dropdown PII" onChange={this.onSimChanged} value={currentSim.serviceInstanceIdentifier}>
                        {this.renderSims(sims, suppressLabels)}
                    </select>
                </div>
            );
        }

        return (
            <p aria-label={`You are viewing ${this.simName(currentSim)}`} className={ `${mobileClass} qa-current-sim` }>{!suppressLabels && `${label}: `}<strong className="PII" aria-hidden="true">{this.simName(currentSim)}</strong></p>
        );
    }
}

function mapStateToProps({ mobileSummaryData }) {
    return {
        mobileSummaryData
    };
}

function mapDispatchToProps(dispatch) {
    return {
        mobileActions: MobileActionCreator(dispatch)
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(MobileSimSelector);
