const utils = require('../utils');
const { ViewActions } = require('../../shared/enums');

const resize = window => {
    return {
        type: ViewActions.VIEW_MODE_CHANGE,
        viewMode: utils.getViewMode(window)
    };
};

const listenToWindowEvent = (name, mapEventToAction, filter, window, dispatch) => {
    const handleEvent = event => {
        if (filter(event)) {
            dispatch(mapEventToAction(window));
        }
    };

    window.addEventListener(name, handleEvent);

    return [name, handleEvent];
};

module.exports = dispatch => {
    return {
        listenToResize: window => {
            return listenToWindowEvent('resize', resize, () => true, window, dispatch);
        }
    };
};
