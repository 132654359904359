const { keyMirrorPrefix, keyMirrorLowercase } = require('./utils/key-mirror');

module.exports = {
    RoutePaths: {
        PANEL: ':slug',
        SUB_SLUG: ':subSlug',
        EPISODES: 'episodes',
        THRESHOLD: 'threshold',
        SEASON: 'season-:season',
        EPISODE: 'episode-:episode',
        MORE_LIKE_THIS: 'more-like-this',
        MORE_INFO: 'more-info',
        NOT_FOUND: '*'
    },
    Templates: {
        Page: keyMirrorLowercase({
            DEFAULT: null,
            ARTICLE: null,
            HELP: null,
            HUB: null,
            MOVIES: null,
            MAINTENANCE: null,
            MOBILEBILL: null,
            TOOLS: null,
            'CORPORATE/GENERIC-PAGE': null,
            TITLE: null,
            'BLACKJACK/INDEX': null,
            'BLACKJACK/PAGE': null,
            MYBROADBAND: null,
            MYBROADBANDLOGGEDOUT: null,
            MOBILEFEATURES: null,
            SIGNIN: null
        }),
        Panel: keyMirrorLowercase({
            MEDIA: null,
            LIST: null,
            BUTTONGROUP: null,
            ENGAGEMENT: null,
            MOBILEACCOUNT: null,
            MOBILEBILL: null,
            MOBILEDISABLED: null,
            MOBILEMANAGEDATAMIX: null,
            MOBILENUMBERPORTING: null,
            MOBILEORDERTRACKING: null,
            MOBILEPIGGYBANK: null,
            PIGGYBANK: null,
            MOBILEDEVICES: null,
            MOBILESETTINGS: null,
            MOVIE: null,
            COLLECTION: null,
            OFFERS: null,
            VIDEOCONTENT: null,
            SPLIT: null,
            MYBROADBANDSPEED: null,
            MYBROADBAND: null,
            CONTENT: null,
            ANNOUNCEMENT: null,
            ACCOUNTDASHBOARD: null,
            PAYMENT: null,
            CHANGEPAYMENT: null,
            VIEWINGCARDS: null,
            MOBILEBGLINK: null,
            PACKAGE: null,
            PACKAGE_NEW: null,
            CHANGEDUEDAY: null,
            TALKSHIELD: null,
            CARDPAIRING: null,
            STS: null,
            SWITCHER: null,
            PPVEVENTS: null,
            DEVICEMANAGEMENT: null
        }),
        Hero: keyMirrorLowercase({
            DEFAULT: null,
            MOBILE: null,
            MOBILEBILL: null,
            MYSKYLOGGEDOUT: null,
            SEARCH: null,
            BILL: null
        }),
        FooterText: keyMirrorLowercase({
            DEFAULT: null,
            MOBILE: null,
            MOBILEBILL: null,
            MYSKYLOGGEDOUT: null,
            SEARCH: null,
            BILL: null
        }),
        Banner: keyMirrorLowercase({
            MYBROADBAND: null
        }),
        Tile: keyMirrorLowercase({
            ACCOUNT: null,
            BILL: null,
            IMAGEBUTTON: null,
            CHANNEL: null,
            CONTENT: null,
            LINK: null,
            MULTILINK: null,
            ENGAGEMENT: null,
            TEXT: null,
            COVERIMAGE: null,
            COVERTEXT: null,
            MOBILEACCOUNT: null,
            MOBILEBILL: null,
            MOBILEDATAROLLOVER: null,
            PIGGYBANK: null,
            MOBILENUMBERPORTING: null,
            MOBILEORDERTRACKING: null,
            MOBILEUSAGE: null,
            MOBILESETTINGS: null,
            MOBILEHELP: null,
            MOBILEDEVICES: null,
            PPVEVENTS: null,
            SEARCHLINKS: null,
            OFFER: null,
            STOCKS: null,
            TWITTER: null,
            IFRAME: null,
            MEDIA: null,
            SPLIT: null,
            MYBROADBANDSPEED: null,
            PRODUCTSOVERVIEW: null,
            PRODUCT: null,
            FINDOUTMORE: null,
            TILEHERO: null,
            ADDRESSCARD: null,
            ROWCONTENT: null,
            CORPORATELINK: null
        }),
        Bill: {
            Rows: keyMirrorLowercase({
                DATA: null,
                MMS: null,
                OFFER: null,
                SMS: null,
                STANDARD: null,
                VOICE: null,
                TRIPLEPLAY_STANDARD: null,
                TRIPLEPLAY_TRANSACTION: null,
                TRIPLEPLAY_BALANCE_CARRIED_FORWARD: null
            })
        }
    },
    Themes: {
        Panel: keyMirrorLowercase({
            TABS: null,
            NAV: null,
            LIGHT: null
        })
    },
    PanelActions: {
        UPDATE_PANEL_STATUS: 'UPDATE_PANEL_STATUS',
        PANEL_RESIZE_COMPLETE: 'PANEL_RESIZE_COMPLETE'
    },
    TileActions: {
        UPDATE_TILE: 'UPDATE_TILE',
        REPLACE_TITLE_PAGE_RELATED_TILES: 'REPLACE_TITLE_PAGE_RELATED_TILES'
    },
    GalleryActions: {
        UPDATE_GALLERY_TILE: 'UPDATE_GALLERY_TILE'
    },
    MovieSearchActions: {
        FETCH_MOVIE_DATA: 'FETCH_MOVIE_DATA',
        REQUEST_MOVIE_DATA: 'REQUEST_MOVIE_DATA',
        RECEIVE_MOVIE_DATA: 'RECEIVE_MOVIE_DATA',
        ACTIVATE_SEARCH_MODE: 'ACTIVATE_SEARCH_MODE',
        DEACTIVATE_SEARCH_MODE: 'DEACTIVATE_SEARCH_MODE'
    },
    BroadbandDataActions: {
        REQUEST_ROUTER_STATUS: 'REQUEST_ROUTER_STATUS',
        REQUEST_SYNC_RATE: 'REQUEST_SYNC_RATE',
        REQUEST_ACTIVATION_DATE: 'REQUEST_ACTIVATION_DATE',
        HAS_BROADBAND: 'HAS_BROADBAND',
        REQUEST_BROADBAND_ACCOUNT_INFO: 'REQUEST_BROADBAND_ACCOUNT_INFO',
        SEND_SLOW_SPEED_ANALYTICS: 'SEND_SLOW_SPEED_ANALYTICS',
        REQUEST_CUSTOMER_TYPE: 'REQUEST_CUSTOMER_TYPE'
    },
    AccountDataActions: {
        REQUEST_CUSTOMER_ACCOUNT: 'REQUEST_CUSTOMER_ACCOUNT',
        REQUEST_MOBILE_ACCOUNT_NUMBER: 'REQUEST_MOBILE_ACCOUNT_NUMBER',
        REQUEST_SKY_CUSTOMER_SINCE_DATE: 'REQUEST_SKY_CUSTOMER_SINCE_DATE'
    },
    OfferDataActions: {
        REQUEST_TOP_OFFER: 'REQUEST_TOP_OFFER'
    },
    OptinDataActions: {
        GET_LOCATION_DATA: 'GET_LOCATION_DATA',
        POST_OPTIN_DATA: 'POST_OPTIN_DATA'
    },
    ViewActions: {
        VIEW_MODE_CHANGE: 'VIEW_MODE_CHANGE'
    },
    WidgetStateActions: keyMirrorPrefix('WidgetStateAction', {
        INITIALISE: null,
        UPDATE: null
    }),
    NotificationActions: keyMirrorPrefix('NoticicationAction', {
        UPDATE: 'UPDATE'
    }),
    GlobalActions: keyMirrorPrefix('GlobalAction', {
        CONTENT_LOADED: null,
        RESIZE: null,
        RESIZE_END: null,
        SCROLL: null,
        SCROLL_END: null,
        HERO_HIDDEN: null,
        FOCUS: null,
        TAB_FOCUS: null,
        BLUR: null,
        ORIENTATION_CHANGE: null
    }),
    PaymentActions: keyMirrorPrefix('PaymentAction', {
        SUBMIT_ONE_OFF_PAYMENT: null,
        RESET_PAYMENT_PANEL: null,
        PAYMENT_ERROR: null,
        REQUEST_PAYMENT_METHOD: null,
        RESET_CHANGE_PAYMENT_METHOD_STATE: null,
        CHANGE_PAYMENT_METHOD_UPDATING_STATE: null,
        PAYMENT_REQUEST_CREATE_BANK_ACCOUNT: null,
        PAYMENT_REQUEST_CHANGE_PAYMENT_METHOD: null,
        CHANGE_PAYMENT_METHOD_ERROR_STATE: null,
        CHANGE_PAYMENT_METHOD_LOAD_SKYPAY: null,
        CHANGE_PAYMENT_METHOD_SHOW_SKYPAY: null,
        PAYMENT_REQUEST_REINSTATE_ACCOUNT: null
    }),
    PanelStatus: keyMirrorLowercase({
        CLOSED: null,
        OPEN: null
    }),
    TileAction: keyMirrorLowercase({
        LINK: null,
        PANEL: null
    }),
    ViewModes: keyMirrorLowercase({
        MOBILE: null,
        TABLET: null,
        DESKTOP: null
    }),
    Page: keyMirrorLowercase({
        DEFAULT: null,
        EPISODES: null,
        'MORE-LIKE-THIS': null
    }),
    MediaFormat: keyMirrorLowercase({
        COVER: null,
        '16-9': null,
        HERO: null
    }),
    CorporateMediaCentreActions: {
        SHOW_MORE_ARTCLES: 'SHOW_MORE_ARTCLES',
        FILTER_CLICK: 'FILTER_CLICK',
        CLEAR_FILTER: 'CLEAR_FILTER',
        MORE_ARTICLE_REQUEST: 'MORE_ARTICLE_REQUEST',
        MORE_ARTICLE_RECEIVED: 'MORE_ARTICLE_RECEIVED',
        TOGGLE_OPEN_PANEL: 'TOGGLE_OPEN_PANEL'
    },
    CustomerAccountActions: {
        REQUEST_PACKAGE_DETAILS: 'REQUEST_PACKAGE_DETAILS',
        REQUEST_OVERVIEW: 'REQUEST_OVERVIEW',
        CHANGE_VIEW: 'CHANGE_VIEW',
        REQUEST_REGIONAL_BOUQUETS: 'REQUEST_REGIONAL_BOUQUETS'
    },
    GenesysChatActions: keyMirrorPrefix('GenesysChatActions', {
        SET_STATUS: null,
        LOADED_FAILED: null,
        LOADED: null,
        AVAILABILITY: null
    }),
    ContentApiActions: {
        CLEAR_DATA: 'CLEAR_DATA',
        REQUEST_DATA: 'REQUEST_DATA',
        REQUEST_DATA_SUCCESS: 'REQUEST_DATA_SUCCESS',
        REQUEST_DATA_ERROR: 'REQUEST_DATA_ERROR'
    },
    RnsFeedActions: {
        CLEAR_RNS_DATA: 'CLEAR_RNS_DATA',
        REQUEST_RNS_DATA: 'REQUEST_RNS_DATA',
        REQUEST_RNS_DATA_SUCCESS: 'REQUEST_RNS_DATA_SUCCESS',
        REQUEST_DATA_RNS_ERROR: 'REQUEST_DATA_RNS_ERROR'
    },
    ContentSwitchActions: {
        UPDATE_SWITCH: 'UPDATE_SWITCH'
    },
    TwitterServiceActions: {
        CLEAR_TWEETS: 'CLEAR_TWEETS',
        REQUEST_TWEETS: 'REQUEST_TWEETS',
        REQUEST_TWEETS_SUCCESS: 'REQUEST_TWEETS_SUCCESS',
        REQUEST_TWEETS_ERROR: 'REQUEST_TWEETS_ERROR'
    },
    CorporateSearchActions: {
        REQUEST_SEARCH_DATA: 'REQUEST_SEARCH_DATA',
        REQUEST_SEARCH_DATA_SUCCESS: 'REQUEST_SEARCH_DATA_SUCCESS'
    },
    BillSnippetActions: {
        REQUEST_BILL_SNIPPET_DATA: 'REQUEST_BILL_SNIPPET_DATA',
        REQUEST_SPLIT_BILL_SNIPPET_DATA: 'REQUEST_SPLIT_BILL_SNIPPET_DATA'
    },
    MetaDataActions: {
        UPDATE: 'UPDATE_META_DATA'
    },
    ProgrammesActions: {
        UPDATE_PROGRAMME_DATA: 'UPDATE_PROGRAMME_DATA'
    },
    BoxOfficeActions: keyMirrorPrefix('BoxOfficeAction', {
        ACTIVATE_SPINNER: null,
        DISABLE_SPINNER: null,
        POPULATE_USER_DATA: null,
        PPV_RESET: null,
        UPDATE_EMAIL_ADDRESS: null,
        LOGGED_IN: null
    }),
    SecureVTwoParams: {
        accountType: {
            CORE: 'CORE',
            MOBILE: 'MOBILE'
        },
        consumerType: {
            MOTO: 'MOTO',
            ECOM: 'ECOM'
        },
        organisationUnit: {
            SKY: 'SKY',
            NOWTV: 'NOWTV'
        }
    },
    TrafficSource: {
        DIRECT: 'Direct',
        SEARCH_ENGINE: 'Search Engine',
        CAMPAIGN: 'Campaign'
    }
};
