import { featureFlags } from 'config';

const addMobileProductToBasket = `mutation AddMobileProductToBasket($productId: String!, $serviceInstanceIdentifier: String!) {
  orderMobileProduct(productId: $productId, serviceInstanceIdentifier: $serviceInstanceIdentifier, completeOrder: false) {
    interactionReference
    basket {
      service {
        name
        charges {
          currency
          amount
          recurrence
          offer{
            type
            discount
            duration
            originalPrice
          }
        }
      }
    }
  }
}`;

const ceaseMobileMixProduct = `mutation CeaseMobileMixProduct($productId: String!, $serviceInstanceIdentifier: String!) {
  ceaseMobileProduct(productId: $productId, serviceInstanceIdentifier: $serviceInstanceIdentifier) {
    orderReference
  }
}`;

const confirmProductOrder = `mutation ConfirmProductOrder($interactionReference: String!) {
  completeMobileProductOrder(interactionReference: $interactionReference) {
    orderReference
  }
}`;

const numberPorting = `
    mutation PortNumber($skyNumber:String!,$portingNumber:String!,$pac:String!) {
        portNumber(skyNumber:$skyNumber,portingNumber:$portingNumber,pac:$pac) {
            status
            expectedPortInDate
        }
    }
`;

const updateAdultContentFilter = `
   mutation UpdateAdultContentFilter($serviceInstanceId: String!, $token: String, $enabled: Boolean!) {
       updateAdultContentFilter(serviceInstanceId: $serviceInstanceId, token: $token, enabled: $enabled) {
           enabled
           confirmedOver18
       }
   }
`;

const setRoamingCap = `
    mutation SetRoamingCap($serviceInstanceId: String!, $capEnabled: Boolean!, $lockToken: String!) {
        setRoamingCap(serviceInstanceId: $serviceInstanceId, capEnabled: $capEnabled, lockToken: $lockToken) {
            currencyId
            value
            capEnabled
            lockToken
        }
    }
`;

const setMobileSimBillCap = `
  mutation SetMobileSimBillCap(
    $accountNumber: String!,
    $serviceInstanceId: String!,
    $capEnabled: Boolean!,
    $capValue: Float,
    $alertEnabled: Boolean!
  ) {
    setMobileSimBillCap(
      accountNumber: $accountNumber,
      serviceInstanceId: $serviceInstanceId,
      capEnabled: $capEnabled,
      capValue: $capValue,
    ) {
      capEnabled
      capValue
    }
    setMobileSimAlertNotifications(
      accountNumber: $accountNumber,
      serviceInstanceId: $serviceInstanceId,
      alertEnabled: $alertEnabled
    ) {
      alertEnabled
    }
  }
`;

const setFriendlyName = (() => featureFlags.mobile.friendlyNameProfanity
    ? `mutation SetUnprofaneFriendlyName(
    $accountNumber: String!,
    $serviceInstanceId: String!,
    $name: String!
  ) {
    setUnprofaneFriendlyName(
      accountNumber: $accountNumber,
      serviceInstanceId: $serviceInstanceId,
      name: $name,
    )
  }`
    : `mutation SetFriendlyName(
    $accountNumber: String!,
    $serviceInstanceId: String!,
    $name: String!
  ) {
    setFriendlyName(
      accountNumber: $accountNumber,
      serviceInstanceId: $serviceInstanceId,
      name: $name,
    )
  }`)();

const orderMobileProduct = `mutation OrderMobileProduct($complete: Boolean!, $productId: String!, $serviceInstanceIdentifier: String) {
    orderMobileProduct(completeOrder: $complete, productId: $productId, serviceInstanceIdentifier: $serviceInstanceIdentifier) {
        orderReference
    }
}`;

const paperBilling = `mutation UpdateAccount($etag: String!, $account: AccountInputType) {
    updateAccount(eTag: $etag, account: $account) {
        eTag
        success
        errorCode
    }
}`;

const signCCA = `mutation SignCCA($agreementNumber: String!, $threatMetrixSessionId: String!, $agreeToAffordabilityQuestion: Boolean!) {
    signCreditAgreementWithAffordability(agreementNumber: $agreementNumber, threatMetrixSessionId: $threatMetrixSessionId, agreeToAffordabilityQuestion: $agreeToAffordabilityQuestion) {
        agreementNumber
        status
    }
}`;

const requestAdHocCCAStatement = `mutation RequestAdHocCCAStatement($accountNumber:String!,$agreementNumber:String!) {
  requestAdHocCCAStatement(accountNumber: $accountNumber, agreementNumber: $agreementNumber) {
    status
  }
}`;

const requestApnSettings = `mutation RequestApnSettings($accountNumber: String!, $telephoneNumber: String!) {
  pushOtaSettings(accountNumber: $accountNumber, telephoneNumber: $telephoneNumber) {
    success
  }
}
`;

const initialiseMobileAccessoryReturn = `mutation InitialiseMobileAccessoryReturn($accountNumber: String!, $portfolioProductId: String!) {
  initialiseMobileAccessoryReturn(accountNumber: $accountNumber, portfolioProductId: $portfolioProductId) {
    avAccessoryId
    snapshotId
    options
  }
}
`;

const returnMobileAccessory = `mutation ReturnMobileAccessory($returnDetails: MobileAccessoryReturnDetails!) {
  returnMobileAccessory(returnDetails: $returnDetails) {
    success
  }
}
`;

const submitDeviceSwapAction = `mutation MobileDeviceChooseSwapAction($customerProductElementId: String!, $action: MobileDeviceChooseSwapActions!) {
  mobileDeviceChooseSwapAction(customerProductElementId: $customerProductElementId, action: $action) {
      action
      status
  }
}
`;

const requestResendPackaging = `mutation RequestResendPackaging($accountNumber: String!, $customerProductElementId: String!) {
  resendPhonePackaging(accountNumber: $accountNumber, customerProductElementId: $customerProductElementId) {
    success
  }
}
`;

export default {
    addMobileProductToBasket,
    ceaseMobileMixProduct,
    confirmProductOrder,
    numberPorting,
    orderMobileProduct,
    paperBilling,
    setRoamingCap,
    signCCA,
    updateAdultContentFilter,
    requestAdHocCCAStatement,
    requestApnSettings,
    initialiseMobileAccessoryReturn,
    returnMobileAccessory,
    submitDeviceSwapAction,
    requestResendPackaging,
    setMobileSimBillCap,
    setFriendlyName
};
