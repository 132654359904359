function shouldTrackPageUpdate(prevProps, props) {
    const conditions = [
        prevProps.params.channel !== props.params.channel,
        prevProps.params.slug !== props.params.slug,
        prevProps.params.tab !== props.params.tab,
        prevProps.params.subSlug !== props.params.subSlug,
        prevProps.routes.length !== props.routes.length,
        prevProps.routes[0].path !== props.routes[0].path
    ];
    return conditions.some(condition => condition);
}

module.exports = {
    shouldTrackPageUpdate
};
