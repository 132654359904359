export const formatNumberTypeNameAndNumbers = ({ numberTypeName, numbers, suppressPluralisation }) => {
    if (!numberTypeName || suppressPluralisation) return '';
    return numbers && numbers.length > 1 ? `${numberTypeName}s are ${numbers.join(', ')}` : `${numberTypeName} is ${numbers}`;
};

export const renderNumberTypeCopy = ({ numbers, numberTypeName, suppressPluralisation }) => {
    if (numbers && numbers.length > 1 && !suppressPluralisation) return `${numberTypeName}s: `;
    return `${numberTypeName}: `;
};

export const joinNumbers = ({ numbers }) => {
    return numbers && numbers.join(', ');
};
