const customerAccountService = require('../services/customer-account-service');
const { CustomerAccountActions } = require('../../shared/enums');

const CustomerAccountActionCreator = (dispatch, service = customerAccountService) => {
    return {
        requestPackageDetails() {
            const dispatchLoadAction = data => {
                dispatch({
                    type: CustomerAccountActions.REQUEST_PACKAGE_DETAILS,
                    data
                });
            };

            return service
                .getPackageDetails()
                .then(dispatchLoadAction)
                .catch(dispatchLoadAction);
        },
        requestOverview() {
            const dispatchLoadAction = data => {
                dispatch({
                    type: CustomerAccountActions.REQUEST_OVERVIEW,
                    data
                });
            };

            return service
                .getOverview()
                .then(dispatchLoadAction)
                .catch(dispatchLoadAction);
        },
        changeView(data) {
            dispatch({
                type: CustomerAccountActions.CHANGE_VIEW,
                data
            });
        },
        requestRegionalBouquets() {
            const dispatchLoadAction = data => {
                dispatch({
                    type: CustomerAccountActions.REQUEST_REGIONAL_BOUQUETS,
                    data
                });
            };

            return service
                .getRegionalBouquetInfo()
                .then(dispatchLoadAction)
                .catch(dispatchLoadAction);
        }
    };
};

module.exports = CustomerAccountActionCreator;
