const _ = require('ramda');
const debounce = require('lodash.debounce');
const throttle = require('lodash.throttle');

const { isBrowser } = require('./index');
const onReady = require('./ready');

const EventEmitter = require('events');
const GlobalActions = require('../../shared/enums').GlobalActions;

const WindowEvents = _.merge(EventEmitter.prototype, {

    // EMITTERS

    emitContentLoaded(evt) {
        this.emit(GlobalActions.CONTENT_LOADED, evt);
    },

    emitHeroHidden(evt) {
        this.emit(GlobalActions.HERO_HIDDEN, evt);
    },

    emitResize(evt) {
        this.emit(GlobalActions.RESIZE, evt);
    },

    emitResizeEnd(evt) {
        this.emit(GlobalActions.RESIZE_END, evt);
    },

    emitScroll(evt) {
        this.emit(GlobalActions.SCROLL, evt);
    },

    emitScrollEnd(evt) {
        this.emit(GlobalActions.SCROLL_END, evt);
    },

    emitTabFocus(evt) {
        this.emit(GlobalActions.TAB_FOCUS, evt);
    },

    emitBlur(evt) {
        this.emit(GlobalActions.BLUR, evt);
    },

    emitOrientationChange(evt) {
        this.emit(GlobalActions.ORIENTATION_CHANGE, evt);
    },

    // LISTENERS

    addContentLoadedListener(callback) {
        this.on(GlobalActions.CONTENT_LOADED, callback);
    },

    removeContentLoadedListener(callback) {
        this.removeListener(GlobalActions.CONTENT_LOADED, callback);
    },

    addHeroHiddenListener(callback) {
        this.on(GlobalActions.HERO_HIDDEN, callback);
    },

    removeHeroHiddenListener(callback) {
        this.removeListener(GlobalActions.HERO_HIDDEN, callback);
    },

    addResizeListener(callback) {
        this.on(GlobalActions.RESIZE, callback);
    },

    removeResizeListener(callback) {
        this.removeListener(GlobalActions.RESIZE, callback);
    },

    addResizeEndListener(callback) {
        this.on(GlobalActions.RESIZE_END, callback);
    },

    removeResizeEndListener(callback) {
        this.removeListener(GlobalActions.RESIZE_END, callback);
    },

    addScrollListener(callback) {
        this.on(GlobalActions.SCROLL, callback);
    },

    removeScrollListener(callback) {
        this.removeListener(GlobalActions.SCROLL, callback);
    },

    addScrollEndListener(callback) {
        this.on(GlobalActions.SCROLL_END, callback);
    },

    removeScrollEndListener(callback) {
        this.removeListener(GlobalActions.SCROLL_END, callback);
    },

    addTabFocusListener(callback) {
        this.on(GlobalActions.TAB_FOCUS, callback);
    },

    removeTabFocusListener(callback) {
        this.removeListener(GlobalActions.TAB_FOCUS, callback);
    },

    addBlurListener(callback) {
        this.on(GlobalActions.BLUR, callback);
    },

    removeBlurListener(callback) {
        this.removeListener(GlobalActions.BLUR, callback);
    },

    addOrientationChangeListener(callback) {
        this.on(GlobalActions.ORIENTATION_CHANGE, callback);
    },

    removeOrientationChangeListener(callback) {
        this.removeListener(GlobalActions.ORIENTATION_CHANGE, callback);
    }
});

if (isBrowser()) {
    const registerWindowEventListeners = () => {
        let tabFocus = false;

        window.addEventListener('resize', evt => {
            WindowEvents.emitResize(evt);
        });

        window.addEventListener('resize', debounce(evt => {
            WindowEvents.emitResizeEnd(evt);
        }, 500));

        window.addEventListener('scroll', throttle(evt => {
            WindowEvents.emitScroll(evt);
        }, 100));

        window.addEventListener('scroll', debounce(evt => {
            WindowEvents.emitScrollEnd(evt);
        }), 200);

        window.addEventListener('orientationchange', evt => {
            WindowEvents.emitOrientationChange(evt);
        });

        window.addEventListener('keydown', evt => {
            tabFocus = (evt.keyCode === 9);
        });

        window.addEventListener('mousedown', () => {
            tabFocus = false;
        });

        window.document.body.addEventListener('focus', evt => {
            if (tabFocus) {
                WindowEvents.emitTabFocus(evt);
            }
        }, true);

        window.document.body.addEventListener('blur', evt => {
            WindowEvents.emitBlur(evt);
        }, true);

        WindowEvents.setMaxListeners(100);
    };

    onReady().then(registerWindowEventListeners).catch(() => console.log('Unable to register window events'));
}

module.exports = WindowEvents;
