const R = require('ramda');

const { ViewActions, ViewModes } = require('../../shared/enums');

const initialState = {
    viewMode: ViewModes.MOBILE
};

module.exports = (initialData = initialState) => (state = initialData, action) => {
    switch (action.type) {
    case ViewActions.VIEW_MODE_CHANGE:
        return R.merge(state, {
            viewMode: action.viewMode
        });
    default:
        return state;
    }
};
