import moment from 'moment';
import { path, merge } from 'ramda';

const { enums } = require('../src-requires');

const { AccountDataActions } = enums;

const getMobileAccountNumber = ({ data }) => path(['data', 'me', 'mobileBillingAccount', 0, 'accountNumber'], data);

const getDateFromDuration = duration => moment().subtract(duration).toDate();

const getCustomerStartDate = ({ data }) => {
    const duration = path(['data', 'me', 'parties', 0, 'customerLoyalty', 'tenure', 'duration'], data);
    if (!duration || !duration.years || duration.months === undefined || duration.months === null || !duration.days) return null; // months can be zero which is a falsy value

    return getDateFromDuration(duration);
};

const getValue = (valuePath, action) => {
    const value = valuePath(action);
    if (value) return { value };

    return { errors: (action.data && action.data.errors) || ['No data received'] };
};

const accountData = action => {
    return {
        mobileAccount: {
            data: () => ({ mobileAccountNumber: getValue(getMobileAccountNumber, action) })
        },
        skyCustomerSinceDate: {
            data: () => ({ skyCustomerSinceDate: getCustomerStartDate(action) })
        }
    };
};

const getResponse = (state, action, source) => merge(state, source.data());

export default (initialState = {}) => (state = initialState, action) => {
    switch (action.type) {
    case AccountDataActions.REQUEST_MOBILE_ACCOUNT_NUMBER:
        return getResponse(state, action, accountData(action).mobileAccount);
    case AccountDataActions.REQUEST_SKY_CUSTOMER_SINCE_DATE:
        return getResponse(state, action, accountData(action).skyCustomerSinceDate);
    default:
        return state;
    }
};
