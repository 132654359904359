function defineValues(obj, converter) {
    const result = {};
    Object.keys(obj).forEach(key => {
        result[key] = converter(key);
    });

    return result;
}

const utils = {
    keyMirrorLowercase: obj => {
        return defineValues(obj, key => {
            return key.toLowerCase();
        });
    },

    keyMirrorPrefix: (prefix, obj) => {
        return defineValues(obj, key => {
            return `${prefix}:${key}`;
        });
    },

    keyMirror: obj => defineValues(obj, key => key)
};

module.exports = utils;
