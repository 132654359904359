import React from 'react';

export default class DefaultLoadingComponent extends React.PureComponent {
    render() {
        const { pastDelay } = this.props;

        return (
            <div className="panel-loading u-text-center">
                <section className="panel-content__constrain u-text-center u-padding-all-large">
                    {pastDelay ? <strong className="c-spinner u-padding-all-large" role="progressbar"></strong> : ' '}
                </section>
            </div>
        );
    }
}
