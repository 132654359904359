import React from 'react';

const CarouselLeftArrow = ({ onClick }) => (
    <button id="hero-carousel-left-arrow" className="c-image-carousel__button" onClick={onClick} aria-label="slide left">
        <span className="isvg loaded icon ">
            <svg width="18" height="17" viewBox="0 0 14 26" xmlns="http://www.w3.org/2000/svg">
                <title>Left</title><desc>Left chevron</desc>
                <path d="M11.857.277l-.216.211L.328 11.8a1.12 1.12 0 0 0-.328.794c0 .3.117.582.328.795L11.721 24.78l.14.138c.184.181.373.269.596.278.194.008.492-.173.815-.496.21-.211.413-.434.603-.663.047-.057.088-.109.125-.156l-.557-.556L3.11 12.992l-.397-.398.397-.397L13.448 1.86l.544-.544a8.544 8.544 0 0 0-.728-.818c-.323-.324-.62-.505-.816-.497-.22.01-.408.097-.591.277z" fill="#111" fillRule="evenodd"></path>
            </svg>
        </span>
    </button>
);

const CarouselRightArrow = ({ onClick }) => (
    <button id="hero-carousel-right-arrow" className="c-image-carousel__button c-image-carousel__button--right" onClick={onClick} aria-label="slide right">
        <span className="isvg loaded icon ">
            <svg width="18" height="17" viewBox="0 0 14 26" xmlns="http://www.w3.org/2000/svg">
                <title>Right</title><desc>Right chevron</desc>
                <path d="M2.143 24.92l.216-.212 11.313-11.311a1.12 1.12 0 0 0 .328-.795c0-.3-.117-.582-.328-.795L2.279.417l-.14-.14C1.955.098 1.766.01 1.543 0 1.35-.008 1.051.173.728.496.518.707.315.93.125 1.159c-.047.057-.088.11-.125.156l.557.557L10.89 12.204l.397.398-.397.397L.552 23.337l-.544.544a8.544 8.544 0 0 0 .728.818c.323.324.62.505.816.497.22-.009.408-.097.591-.277z" fill="#111" fillRule="evenodd"></path>
            </svg>
        </span>
    </button>
);


const CarouselIndicator = ({ index, activeIndex, onClick }) => (
    <li>
        <a
            className={
                index === activeIndex ? 'c-hero-carousel__indicator c-hero-carousel__indicator--active' : 'c-hero-carousel__indicator'
            }
            onClick={onClick}
        />
    </li>
);

const CarouselSlide = ({ index, activeIndex, slide: { title, subtitle, image, url } }) => (
    <li
        className={
            index === activeIndex ? 'c-hero-carousel__slide c-hero-carousel__slide--active' : 'c-hero-carousel__slide'
        }
    >
        <div className="c-hero-carousel-slide__content">
            <div className="c-hero c-hero--homepage-carousel c-hero--overlap"
                style={ { backgroundImage: `url(${image.url})` } } >
                <div className="c-hero__caption">
                    <div className="o-container">
                        <p className="c-heading-alpha u-margin-bottom">{title}</p>
                        {url ? (<a className="c-btn c-btn--primary u-margin-bottom" href={url}>{subtitle}</a>) : (<h3 key="hero-tx" className="hero-tx">{subtitle}</h3>)
                        }
                    </div>
                </div>
            </div>
        </div>
    </li>
);

class Carousel extends React.Component {
    state = {
        activeIndex: 0,
        transitionTimeout: null
    };

    componentDidMount() {
        this.restartTimeout();
    }

    restartTimeout = () => {
        const { transitionTimeout } = this.state;

        clearTimeout(transitionTimeout);

        const heroImageTimeoutID = setTimeout(this.goToNextSlide, 5000);

        this.setState({ transitionTimeout: heroImageTimeoutID });
    }

    goToSlide = (index) => {
        this.setState({ activeIndex: index }, this.restartTimeout);
    }

    goToPrevSlide = (e) => {
        e.preventDefault();

        const { slides } = this.props;
        let index = this.state.activeIndex;
        const slidesLength = slides.length;

        if (index < 1) {
            index = slidesLength;
        }

        this.goToSlide(index - 1);
    }

    goToNextSlide = (e) => {
        if (e) {
            e.preventDefault();
        }

        const { slides } = this.props;
        let index = this.state.activeIndex;
        const slidesLength = slides.length - 1;

        if (index === slidesLength) {
            index = -1;
        }

        this.goToSlide(index + 1);
    }

    render() {
        const { slides } = this.props;
        const { activeIndex } = this.state;

        return (
            <div className="c-hero-carousel">
                <ul>
                    {
                        slides.map((slide, index) => (
                            <CarouselSlide
                                key={index}
                                index={index}
                                slide={slide}
                                activeIndex={activeIndex}
                            />
                        ))
                    }
                </ul>
                <div className="c-hero-carousel__controller-wrap">
                    <div className="c-hero-carousel__controller" >
                        <CarouselLeftArrow onClick={this.goToPrevSlide} />
                        <div className="c-hero-carousel__indicator-wrapper">
                            <ul className="c-hero-carousel__indicators">
                                {
                                    slides.map((_, index) => (
                                        <CarouselIndicator
                                            key={index}
                                            index={index}
                                            activeIndex={activeIndex}
                                            isActive={activeIndex === index}
                                            onClick={() => this.goToSlide(index)}
                                        />
                                    ))
                                }
                            </ul>
                        </div>
                        <CarouselRightArrow onClick={this.goToNextSlide} />
                    </div>
                </div>
            </div>
        );
    }
}

export default Carousel;
