import React from 'react';
import { Tabs, TabArticle } from '@sky-uk/toolkit-react';

class MobileHubNav extends React.Component {
    shouldComponentUpdate() {
        return false;
    }

    render() {
        const navTabInfo = [
            {
                title: 'TV',
                link: '/myaccount/tv'
            },
            {
                title: 'Broadband & Talk',
                link: '/myaccount/bb-and-talk'
            },
            {
                title: 'Mobile',
                link: ''
            },
            {
                title: 'My Details',
                link: '/myaccount/my-details'
            },
            {
                title: 'Message Centre',
                link: 'https://messages.sky.com/message-centre'
            }
        ];

        return (
            <Tabs selectedIndex={navTabInfo.map(({ title }) => title).indexOf('Mobile')} cssClassName={`mysky-main-tabs ${this.props.showRedesign && 'mysky-header-redesign-tabs'}`}>
                {navTabInfo.map((tab, idx) => (
                    <TabArticle key={idx} title={tab.title} href={tab.link} />
                ))}
            </Tabs>
        );
    }
}

export default MobileHubNav;
