const { get, isTruthy, getInt } = require('./helpers');

const getInstanceName = () => {
    const nimbusDeployment = get('VCAP_APPLICATION', undefined);
    if (nimbusDeployment) {
        try {
            const applicationInfo = JSON.parse(nimbusDeployment);
            return `${applicationInfo.application_name}.${get('CF_INSTANCE_INDEX')}`;
        } catch (e) {
            console.error('Failed to construct Nimbus instance name', e); // eslint-disable-line no-console
            return 'ds-sky-pages';
        }
    }

    return 'development';
};


module.exports = (typeof window !== 'undefined' && window.polarisClientConfig) || {
    NODE_ENV: process.env.NODE_ENV,
    instanceName: getInstanceName(),
    endpoints: {
        adobeAnalytics: get('ADOBE_ANALYTICS_ENDPOINT', 'metrics.sky.com'),
        adobeHeartbeat: get('ADOBE_HEARTBEAT_ENDPOINT', 'bskyb.hb.omtrdc.net'),
        secureAdobeAnalytics: get('ADOBE_ANALYTICS_ENDPOINT', 'smetrics.sky.com'),
        wheresMyTech: get('WHERES_MY_TECH_URL', 'https://w.etadirect.com'),
        helpProductCookieDomain: get('SKY_HELP_PRODUCT_DOMAIN', 'sky.com'),
        defaultTimeout: getInt('DEFAULT_TIMEOUT', 2000),
        searchTimeout: getInt('SEARCH_TIMEOUT', getInt('DEFAULT_TIMEOUT', 2000)),
        skySearchApiTimeout: getInt('SKY_SEARCH_API_TIMEOUT', 10000),
        regionalInfoTimeout: getInt('REGIONAL_INFO_TIMEOUT', 300),
        skyId: get('SKY_ID_ENDPOINT', 'https://skyid.sky.com'),
        epgServices: get('EPG_SERVICES_ENDPOINT', 'https://secure-epgservices.sky.com'),
        epgServicesProxy: get('EPG_SERVICES_PROXY_ENDPOINT', 'https://cloud.sky.com/epgproxy'),
        offers: get('OFFERS_ENDPOINT', 'https://buy.sky.com'),
        graphql: get('GRAPHQL_ENDPOINT', 'https://skyport.sky.com'),
        skyIdRango: get('SKY_ID_RANGO', 'https://skyidapp.sky.com/signin/service'),
        skyIdRangoGeneric: get('SKY_ID_RANGO_ENDPOINT', 'https://skyidapp.sky.com'),
        maggieServer: get('MAGGIE_SERVER', 'https://production-riri.herokuapp.com/maggie'),
        seaggieServer: get('SEAGGIE_SERVER', 'https://production-riri.herokuapp.com/seaggie'),
        skySearchApiBaseUrl: get('SKY_SEARCH_API_BASE_URL', 'https://sky-search-api.cf.sky.com'),
        helpProxy: get('HELPPROXY_URL', 'https://stage-helpproxy.cf.stage-paas.bskyb.com/helpproxy'),
        custard: get('CUSTARD_API_ENDPOINT', 'https://prod-custard-v2.herokuapp.com/api'),
        baggie: get('BAGGIE_ENDPOINT', 'https://secure.sky.com/baggie'),
        scriptLoader: get('SCRIPT_LOADER_ENDPOINT', 'https://www.sky.com/script-loader/v1.x/sky-loader.js'),
        skyPay: get('SKY_PAY_ENDPOINT', 'https://pay.sky.com/skypay'),
        contentApi: get('CONTENT_API_ENDPOINT', 'https://blackjack-content-api.herokuapp.com'),
        bill: get('BILL_ENDPOINT', '/myaccount/bill'),
        dtm: get('DTM_ENDPOINT', ''),
        placeHolder: get('PLACEHOLDER_ENDPOINT', 'https://www.sky.com/services/image-placeholder'),
        imagePlaceholder: get('IMAGE_PLACEHOLDER', 'https://dm8eklel4s62k.cloudfront.net/images/sky-logo-b90e8c9.jpg'),
        threatMetrix: get('THREAT_METRIX_ENDPOINT', 'https://idstatus.sky.com'),
        mobileDocumentGenerator: get('MOBILE_DOCUMENT_GENERATOR_ENDPOINT', 'http://prod-skymob-document-generator.cf.paas.bskyb.com'),
        stark: get('STARK_URL', 'https://stark.sky.com'),
        nimbusStark: get('NIMBUS_STARK_URL', 'https://stark.cf.sky.com'),
        retriever: get('RETRIEVER_SERVER', 'https://production-retriever.herokuapp.com'),
        shopUrl: get('SHOP_URL', 'https://www.sky.com/shop'),
        tacticalCmsServer: get('TACTICAL_CMS_SERVER', 'https://production-polaris-editor.herokuapp.com'),
        entityServer: get('ENTITY_SERVER', 'http://entity.search.sky.com/entity'),
        hawkApi: get('EPG_URL', 'https://awk.epgsky.com/hawk/linear'),
        preregService: get('PRE_REG_SERVICE', 'https://secure.sky.com/prereg-microservice/soundbox'),
        deregService: get('DE_REG_SERVICE', 'https://secure.sky.com/prereg-microservice/soundbox-deregister'),
        ftthPreregService: get('FTTH_PRE_REG_SERVICE', 'https://secure.sky.com/prereg-microservice/ftth'),
        ftthDeregService: get('FTTH_DE_REG_SERVICE', 'https://secure.sky.com/prereg-microservice/ftth-deregister'),
        helpSearch: get('HELP_SEARCH_FRONTEND_ENDPOINT', 'https://www.sky.com/help/search'),
        helpSearchApi: get('HELP_SEARCH_API', 'https://help-search-api-stage.herokuapp.com'),
        pdfService: get('PDF_SERVICE', 'https://www.sky.com/myaccount/bill/pdf-service'),
        asmProxy: get('ASM_PROXY_ENDPOINT', 'https://asm-proxy.cf.sky.com'),
        channelLogoURL: get('EPG_CHANNEL_LOGO_CDN_URL', 'https://d2n0069hmnqmmx.cloudfront.net'),
        myPhotoApi: get('MYPHOTO_API', 'https://prod-my-photo-api.herokuapp.com'),
        employeeResource: get('EMPLOYEE_RESOURCE_URL', 'https://mcsskylab.onmicrosoft.com/da-employee-api'),
        helpLiveChat: get('HELP_LIVE_CHAT_API', '//advisor.sky.com/v1.x/chat.js'),
        stsServe: get('STS_SERVE_URL', 'https://www.sky.com/help/sts/api/serve'),
        errorLookupApi: get('ERROR_LOOKUP_API', 'https://help-search-api-stage.herokuapp.com/errors'),
        coolingOff: get('COOLING_OFF_URL', 'https://secure.sky.com/secureapi-cooling-off'),
        numberPoolingEndpoint: get('NUMBER_POOLING_ENDPOINT', 'https://TBC.COM'),
        rescheduleAppointments: get('RESCHEDULE_APPOINTMENTS_ENDPOINT', 'https://secure.sky.com/reschedule-appointments'),
        serviceRanch: get('SERVICE_RANCH_ENDPOINT', 'https://secure.sky.com/service-ranch'),
        mobileHub2Path: get('MOBILE_HUB2_PATH', 'https://www.sky.com/myaccount/mobile'),
        pyrApiBaseUrl: get('PYR_API_BASE_URL', 'https://www.sky.com'),
        rewardsAndExtras: get('REWARDS_AND_EXTRAS_URL', 'https://www.sky.com/myaccount/rewards-and-extras'),
        engagementContentApi: get('ENGAGEMENT_CONTENT_API_SERVER', 'https://static.skyassets.com/content-api'),
        adobeTargetURL: get('ADOBE_TARGET_URL', 'https://britishskybroadcasti.tt.omtrdc.net/rest/v2/batchmbox/'),
        myDetailsRedirectURL: get('MY_DETAILS_REDIRECT_URL', 'https://www.sky.com/mydetails'),
        browserLoggingURL: get('BROWSER_LOGGING_URL', 'https://www.sky.com/logs')
    },
    tokens: {
        mysky: {
            graphql: get('MYSKY_GRAPHQL_TOKEN'),
            mobileGraphql: get('MYSKY_MOBILE_GRAPHQL_TOKEN')
        },
        mobile: {
            graphql: get('MOBILE_GRAPHQL_TOKEN')
        },
        digitalEngagement: {
            graphql: get('DIGITAL_ENGAGEMENT_GRAPHQL_TOKEN')
        },
        mobileReturns: {
            graphql: get('MOBILE_RETURNS_GRAPHQL_TOKEN')
        },
        broadband: {
            graphql: get('BROADBAND_GRAPHQL_TOKEN')
        },
        bill: {
            graphql: get('BILL_GRAPHQL_TOKEN'),
            logging: get('BILL_LOGGING_TOKEN')
        },
        alertService: {
            authorisation: get('CORP_Authentication', 'CorpAuth 54961fe33be2e5b7e3b9f4908d7a479d')
        },
        accessories: {
            graphql: get('ACCESSORIES_GRAPHQL_TOKEN')
        },
        ftthprereg: {
            graphql: get('FTTH_PREREG_GRAPHQL_TOKEN')
        },
        tvPin: {
            graphql: get('TV_PIN_GRAPHQL_TOKEN')
        },
        payPerView: {
            graphql: get('PPV_GRAPHQL_TOKEN')
        },
        cardPairing: {
            graphql: get('CARD_PAIRING_GRAPHQL_TOKEN')
        },
        help: {
            graphql: get('HELP_GRAPHQL_TOKEN')
        },
        helpSar: {
            graphql: get('HELP_SAR_GRAPHQL_TOKEN')
        },
        myDetails: {
            graphql: get('MY_DETAILS_GRAPHQL_TOKEN')
        },
        deviceManagement: {
            graphql: get('DEVICE_MANAGEMENT_GRAPHQL_TOKEN')
        },
        billSnippet: {
            graphql: get('BILL_SNIPPET_GRAPHQL_TOKEN')
        },
        pageData: {
            graphql: get('PAGE_DATA_GRAPHQL_TOKEN')
        },
        vipMarketingEnrolment: {
            graphql: get('VIP_MARKETING_ENROLMENT_GRAPHQL_TOKEN')
        }
    },
    featureFlags: {
        newMySkyAppInEditorialPages: isTruthy('NEW_MY_SKY_APP_IN_EDITORIAL_PAGES'),
        soundBoxPurchaseApp: isTruthy('SOUNDBOX_PURCHASE_APP'),
        newHelpAnalytics: isTruthy('NEW_HELP_ANALYTICS'),
        mobileBillFooterLiveChat: isTruthy('MOBILE_BILL_FOOTER_LIVE_CHAT'),
        mobileMySkyNavigation: isTruthy('MOBILE_MY_SKY_NAVIGATION'),
        mobileBillDisableTransformPredictedBill: isTruthy('MOBILE_BILL_DISABLE_TRANSFORM_PREDICTED_BILL'),
        mobileReturnsRepairJourney: isTruthy('MOBILE_RETURNS_REPAIR_JOURNEY'),
        mobileV2: isTruthy('MOBILE_HUB_V2'),
        payment: isTruthy('PAYMENT'),
        changePayment: isTruthy('CHANGE_PAYMENT'),
        changeDueDay: isTruthy('PAYMENT_DUE_DAY'),
        madLogin: isTruthy('MAD_LOGIN'),
        mySkyPhoneVerificationJourney: isTruthy('MYSKY_SHOW_PHONE_VERIFICATION'),
        mySkyInvalidBanner: isTruthy('MY_SKY_INVALID_BANNER'),
        mobileCCA: isTruthy('MOBILE_CCA'),
        dtm: isTruthy('DTM'),
        edrEnabled: isTruthy('EDR_ENABLED'),
        relatedTiles: isTruthy('RELATED_TILES'),
        reconsentMarketingBanner: isTruthy('RECONSENT_MARKETING_BANNER'),
        talkShield: {
            showStats: isTruthy('TALKSHIELD_SHOW_STATS'),
            unknownNumberIsAllowed: isTruthy('TALKSHIELD_ALLOW_UNKNOWN_NUMBERS')
        },
        flowUiFeature: isTruthy('FLOW_UI_FEATURE'),
        bill: {
            disableFutureTab: isTruthy('BILL_DISABLE_FUTURE_TAB'),
            enableStoreTimeline: isTruthy('BILL_ENABLE_STORE_TIMELINE'),
            enableCompassionPackJourneyTwo: isTruthy('BILL_ENABLE_COMPASSION_PACK_J2'),
            compassionPackReferrerCheck: isTruthy('COMPASSION_PACK_REFERRER_CHECK'),
            helpSectionContactUsPods: isTruthy('HELP_SECTION_CONTACT_US_PODS'),
            browserLoggingEnabled: isTruthy('BILL_BROWSER_LOGGING_ENABLED'),
            throttleMobileDoublePayments: isTruthy('BILL_THROTTLE_MOBILE_DOUBLE_PAYMENTS')
        },
        asmServices: {
            enableAuthoriseJourney: isTruthy('ASM_ENABLE_AUTHORISE_JOURNEY')
        },
        mydetails: {
            enableToggles: isTruthy('ENABLE_MY_DETAILS_TOGGLES'),
            enableReconsent: isTruthy('ENABLE_MY_DETAILS_RECONSENT')
        },
        helpStsPanelSplit: getInt('HELP_STS_PANEL_SPLIT', 100),
        goalAchievementPhaseTwoArticles: isTruthy('GOAL_ACHIEVEMENT_PHASE_2_ARTICLES'),
        speedTabs: isTruthy('SPEED_TABS'),
        mobile: {
            friendlyNameProfanity: isTruthy('FRIENDLY_NAME_PROFANITY'),
            notifications: {
                disableAll: isTruthy('DISABLE_ALL_MOBILE_NOTIFICATIONS'),
                disableSwap: isTruthy('DISABLE_ALL_SWAP_NOTIFICATIONS'),
                disableFreeUnlimitedCallsAndTexts: isTruthy('DISABLE_MOBILE_NOTIFICATIONS_FREE_CALLS_TEXTS'),
                disableSpendCap: isTruthy('DISABLE_MOBILE_NOTIFICATIONS_SPEND_CAP')
            },
            redirectMobileAccount: isTruthy('REDIRECT_MOBILE_ACCOUNT'),
            newDevicesPanel: isTruthy('MOBILE_NEW_DEVICES_PANEL'),
            swapTwo: isTruthy('MOBILE_SWAP_TWO'),
            swapHandover: isTruthy('MOBILE_SWAP_HANDOVER'),
            swapReorderPackaging: isTruthy('MOBILE_SWAP_REORDER_PACKAGING'),
            newPiggybankPanel: isTruthy('MOBILE_NEW_PIGGYBANK_PANEL'),
            showBillPDFLink: isTruthy('MOBILE_SHOW_BILL_PDF_LINK'),
            showSpendCap: isTruthy('MOBILE_SHOW_SPEND_CAP'),
            showSwapFeaturedProduct: isTruthy('MOBILE_SHOW_SWAP_FEATURED_PRODUCT'),
            showPiggybankAccessoriesCTA: isTruthy('MOBILE_SHOW_PIGGYBANK_ACCESSORIES_CTA'),
            showSamsungUpsell: isTruthy('MOBILE_SHOW_SAMSUNG_UPSELL'),
            mobileHub2: isTruthy('MOBILE_HUB_TWO'),
            newPayOffCca: isTruthy('MOBILE_NEW_PAY_OFF_CCA')
        },
        ftthPreregistration: isTruthy('FTTH_PREREGISTRATION'),
        tvGuideEpisodes: isTruthy('TVGUIDE_EPISODES'),
        deviceManagement: {
            newApp: isTruthy('DEVICE_MANAGEMENT_NEW_APP')
        },
        tvGuide: {
            graphQL: isTruthy('TVGUIDE_GRAPHQL')
        },
        manage: {
            enableWinback: isTruthy('MANAGE_ENABLE_WINBACK')
        },
        advertiseUltrafastBroadband: isTruthy('ADVERTISE_ULTRAFAST_BROADBAND'),
        goalAchievementPhase2Flows: isTruthy('GOAL_ACHIEVEMENT_PHASE_2_FLOWS'),
        wifiGuaranteeEngineerBooking: isTruthy('WIFI_GUARANTEE_ENGINEER_BOOKING'),
        showWindowsInSkyGoInstaller: isTruthy('SKY_GO_INSTALLER_SHOW_WINDOWS'),
        showHelpCovidBanner: isTruthy('FEATURE_HELP_COVID_BANNER'),
        compassionPackJourneyEnabled: isTruthy('COMPASSION_PACK_JOURNEY_ENABLED'),
        fullFatNewsModularBlocks: isTruthy('FULL_FAT_NEWS_MODULAR_BLOCKS'),
        quickLinksCardFeature: get('QUICK_LINKS_CARD_FEATURE', 'true') === 'true' // default as on
    },
    settings: {
        adfsResponseType: get('ADFS_RESPONSE_TYPE', 'id_token code'),
        relatedTilesCountInTab: getInt('RELATED_TILES_COUNT', 4),
        relatedTilesCount: getInt('RELATED_TILES_COUNT', 6),
        favSpinnerTimeout: getInt('FAV_SPINNER_TIMEOUT', 30000),
        sportsGalleryCount: getInt('SPORTS_GALLERY_COUNT', 10),
        watchChannelContentTtl: getInt('WATCH_CHANNEL_CONTENT_TTL', 30),
        watchChannelBackgroudRefreshInterval: getInt('WATCH_CHANNEL_BACKGROUND_REFRESH_INTERVAL', 15 * 60),
        optimizelyLogLevel: getInt('OPTIMIZELY_LOG_LEVEL', 4), // See: https://github.com/optimizely/node-sdk/blob/master/lib/utils/enums/index.js
        billMaintenance: isTruthy('BILL_MAINTENANCE'),
        mobileMaintenance: isTruthy('MOBILE_MAINTENANCE'),
        cbsMaintenance: isTruthy('CBS_MAINTENANCE'),
        helpSearchResultsPerPage: get('HELP_SEARCH_RESULTS_PER_PAGE', 8),
        bill: {
            ukGatekeeperId: get('BILL_GATEKEEPER_ID', 'blt0ad7d1f0c80ea9ab'),
            roiGatekeeperId: get('ROI_BILL_GATEKEEPER_ID', 'bltf16bb29f07c8fb74'),
            previousBillCount: get('PREVIOUS_BILL_COUNT', 24),
            compassionPackAdjustmentCode: get('COMPASSION_PACK_ADJUSTMENT_CODE', 'CC351')
        },
        helpProxySecretString: get('KANA_SECRET', 'westcrosshouse'),
        helpLinkBaseUrl: get('HELP_LINK_BASE_URL', 'https://www.sky.com/help'),
        contactContentCrossOrigin: get('CONTACT_CONTENT_CROSS_ORIGIN'),
        contactContentCrossHeaders: get('CONTACT_CONTENT_CROSS_HEADERS'),
        helpExpertOptiBuckets: getInt('HELP_EXPERT_OPTI_BUCKETS', 10)
    },
    adobeAnalyticsAccounts: {
        mysky: get('MYSKY_ADOBE_ANALYTICS_ACCOUNTS', 'bskybskyhcincldev'),
        bill: get('BILL_ADOBE_ANALYTICS_ACCOUNTS', 'bskybmyaccountdev'),
        ooyala: get('OOYALA_ADOBE_ANALYTICS_ACCOUNTS', 'bskybdtmskycomdev')
    },
    adobeTarget: {
        sessionAge: get('ADOBE_TARGET_SESSION_COOKIE_AGE', 30 * 60 * 1000),
        adobeReportSuite: get('ADOBE_REPORT_SUITE', 'bskybdtmskycomprod'),
        adobeReportingUrl: get('ADOBE_REPORTING_URL', 'https://smetrics.sky.com/b/ss/bskybdtmskycomprod/10/TA-1.0'),
        engagementComponent: {
            myAccountHeader: {
                tv: {
                    mbox: get('MYACCOUNT_TV_ENGAGEMENT_HEADER_MBOX', 'skycom.myaccount.tv.header.banner'),
                    fallbackKey: get('MYACCOUNT_TV_ENGAGEMENT_HEADER_FALLBACK_ADOBE_KEY', 'promote_default')
                }
            },
            myAccountTile: {
                mbox: get('MYACCOUNT_TV_ENGAGEMENT_TILE_MBOX', 'skycom.myaccount.tv.engagement.tile'),
                fallbackKey: get('MYACCOUNT_TV_ENGAGEMENT_TILE_FALLBACK_ADOBE_KEY', 'Catch_All')
            },
            billBanner: {
                mbox: get('BILL_ENGAGEMENT_BANNER_MBOX', 'skycom.myaccount.bill.engagement.banner'),
                fallbackKey: get('BILL_ENGAGEMENT_BANNER_FALLBACK_ADOBE_KEY', 'promote_offers_and_upgrades')
            }
        }
    },
    abTestingBoundaries: {
        myskyLoginBoundary: getInt('MYSKY_LOGIN_BOUNDARY', 0),
        helpSearchRolloutPercentage: getInt('HELP_SEARCH_ROLLOUT_PERCENTAGE', 0)
    },
    threatMetrix: {
        orgId: get('THREAT_METRIX_ORG_ID', 'nd1lmtff'),
        pageIds: {
            mobileAccount: '20'
        }
    },
    optimizelyDatafileURL: get('OPTIMIZELY_DATAFILE_URL', 'https://cdn.optimizely.com/json/8224896016.json'),
    ooyalaAdobeHeartbeat: {
        marketingCloudOrgId: get('OOYALA_ADOBE_HEARTBEAT_MARKETING_CLOUD_ID', '0ABA4673527831C00A490D45@AdobeOrg'),
        publisherId: get('OOYALA_ADOBE_HEARTBEAT_PUBLISHER_ID', '0ABA4673527831C00A490D45@AdobeOrg')
    },
    links: {
        madAccountSuspendedRedirect: get('MAD_ACCOUNT_SUSPENDED_REDIRECT', 'https://www.sky.com'),
        madLinkAccountRedirect: get('MAD_LINK_ACCOUNT_REDIRECT', 'https://skyid.sky.com/serviceSignup/myskyauthorised'),
        broadbandDiagnosticsROIRedirect: get('BROADBAND_DIAGNOSTICS_ROI_REDIRECT', 'https://www.sky.com/help/diagnostics/sky-broadband-diagnostic/sky-broadband-diagnostic')
    },
    environments: {
        contentApi: get('CONTENT_API_ENV', get('NODE_ENV', 'development')),
        helpLiveChat: get('HELP_LIVE_CHAT_ENV', 'production'),
        engagementContentApiRealm: get('ENGAGEMENT_CONTENT_API_REALM', 'engagement'),
        engagementContentEnv: get('ENGAGEMENT_CONTENT_API_ENV', 'production'),
        engagementContentApiKey: get('ENGAGEMENT_CONTENT_API_KEY', ''),
        engagementContentAccessToken: get('ENGAGEMENT_CONTENT_ACCESS_TOKEN', '')
    },
    googleReCaptcha: {
        scriptUrl: 'https://www.google.com/recaptcha/api.js'
    },
    credentials: {
        asmServices: {
            asmProxyAuthToken: get('ASM_PROXY_AUTHTOKEN')
        }
    },
    googleMaps: {
        apiKey: get('GOOGLE_MAPS_API_KEY', 'AIzaSyBYsBJJCcEltBGmYWG1tZjUQjve6HtlAlg')
    },
    ooyala: {
        pCode: get('OOYALA_PCODE', ''),
        playerBrandingId: get('OOYALA_PLAYER_BRANDING_ID', 'f4e073f0cfe1461f8709aebc5822eea0'),
        assets: get('OOYALA_ASSETS', [
            'https://www.sky.com/assets2/ooyala/ooyala-asset-4.30.15.min.js',
            'https://player.ooyala.com/static/v4/stable/4.30.15/skin-plugin/html5-skin.min.css'
        ])
    },
    engineerVisit: {
        baseCost: getInt('ENGINEER_VISIT_BASE_COST', 65),
        premiumCost: getInt('ENGINEER_VISIT_PREMIUM_SURCHARGE', 15),
        openReachBaseCost: getInt('ENGINEER_VISIT_OPERNREACH_BASE_COST', 65),
        openReachPremiumCost: getInt('ENGINEER_VISIT_OPENREACH_PREMIUM_COST', 15)
    },
    tvEngineerVisit: {
        uk: {
            baseCost: getInt('TV_ENGINEER_VISIT_BASE_COST', 65),
            premiumCost: getInt('TV_ENGINEER_VISIT_PREMIUM_SURCHARGE', 15)
        },
        roi: {
            baseCost: getInt('TV_ROI_ENGINEER_VISIT_BASE_COST', 60),
            premiumCost: getInt('TV_ROI_ENGINEER_VISIT_PREMIUM_SURCHARGE', 15)
        }
    },
    contactOptions: {
        livepersonReadyTimeout: getInt('CONTACT_OPTIONS_LIVEPERSON_READY_TIMEOUT', 5000, 10)
    }
};
