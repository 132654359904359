const R = require('ramda');

const { ContentApiActions, RnsFeedActions } = require('../../shared/enums');

module.exports = polarisData => {
    const initialData = Object.assign({
        corporate: {},
        'core-content': {},
        'my-packages': {},
        'card-pairing': {},
        'core-content-v2': {}
    }, polarisData);

    return (store = initialData, action = { type: null, payload: null }) => {
        const { type, payload } = action;

        switch (type) {
        case ContentApiActions.CLEAR_DATA:
            return R.merge(store, {
                [payload.realm]: R.merge(store[payload.realm], {
                    [payload.contentType]: R.merge(store[payload.realm][payload.contentType], {
                        last: false,
                        data: null
                    })
                })
            });

        case ContentApiActions.REQUEST_DATA:
            return R.merge(store, {
                [payload.realm]: R.merge(store[payload.realm], {
                    [payload.contentType]: R.merge(store[payload.realm] ? store[payload.realm][payload.contentType] : {}, {
                        loading: true
                    })
                })
            });

        case ContentApiActions.REQUEST_DATA_SUCCESS: {
            let data = payload.data;
            if (Array.isArray(data)) {
                data = store[payload.realm][payload.contentType].data ? [...store[payload.realm][payload.contentType].data, ...payload.data] : [...payload.data];
            }
            return R.merge(store, {
                [payload.realm]: R.merge(store[payload.realm], {
                    [payload.contentType]: R.merge(store[payload.realm][payload.contentType], {
                        loading: false,
                        last: (payload.data.length < payload.limit),
                        tags: payload.tags || [],
                        query: payload.query || false,
                        data
                    })
                })
            });
        }

        case ContentApiActions.REQUEST_DATA_ERROR: {
            return R.merge(store, {
                [payload.realm]: R.merge(store[payload.realm], {
                    [payload.contentType]: R.merge(store[payload.realm][payload.contentType], {
                        loading: false,
                        tags: payload.tags || [],
                        query: payload.query || false,
                        error: payload.error
                    })
                })
            });
        }

        case RnsFeedActions.CLEAR_RNS_DATA:
            return R.merge(store, {
                [payload.realm]: R.merge(store[payload.realm], {
                    [payload.contentType]: R.merge(store[payload.realm][payload.contentType], {
                        last: false,
                        data: null
                    })
                })
            });

        case RnsFeedActions.REQUEST_RNS_DATA:
            return R.merge(store, {
                [payload.realm]: R.merge(store[payload.realm], {
                    [payload.contentType]: R.merge(store[payload.realm][payload.contentType], {
                        loading: true
                    })
                })
            });

        case RnsFeedActions.REQUEST_RNS_DATA_SUCCESS:
            return R.merge(store, {
                [payload.realm]: R.merge(store[payload.realm], {
                    [payload.contentType]: R.merge(store[payload.realm][payload.contentType], {
                        loading: false,
                        last: payload.totalPages <= payload.page,
                        page: payload.page,
                        query: payload.query || false,
                        data: store[payload.realm][payload.contentType].data ? [...store[payload.realm][payload.contentType].data, ...payload.data] : [...payload.data]
                    })
                })
            });

        default:
            return store;
        }
    };
};
