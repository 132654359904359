import config from 'config';

const PreviousBillingAmount = config.settings && config.settings.bill.previousBillCount;

const triplePlayBillContents = `
  billingId
  paymentDueDate
  statementDate
  fromDate
  toDate
  total
  isLatest
  paymentMethod
  details {
    ... on BillDetailsTriplePlay {
      total
      amountCarriedFwdFromPrevBill
      amountCarriedFwdToNextBill
      parkedChargesTotal
      sections {
        additionalCharges {
          type
          name
          total
          items {
            ...billOneOffChargeFields
          }
        }
        additionalProductsAndServices {
          type
          name
          total
          items {
            ...billOneOffChargeFields
          }
        }
        adjustments {
          type
          name
          total
          items {
            type
            description
            amount
            transactionDate
            fromDate
            toDate
          }
        }
        buyAndKeep {
          type
          name
          total
          items {
            ...billOneOffChargeFields
          }
        }
        currentPeriod {
          type
          name
          total
          fromDate
          toDate
          subscriptionCharges {
            type
            name
            total
            items {
              ...billSubscriptionChargeFields
            }
          }
          disconnectionCredits {
            type
            name
            total
            items {
              type
              description
              amount
              category
            }
          }
        }
        payments {
          type
          name
          total
          items {
            type
            description
            amount
            transactionDate
            paymentDueDate
            isContinuous
            transactionType
          }
        }
        pendingCollections {
          name
          total
          items {
            description
            amount
            collectionDate
          }
        }
        priorOrPartialPeriods {
          type
          name
          total
          periods {
            type
            name
            total
            fromDate
            toDate
            subscriptionCharges {
              type
              name
              total
              items {
                ...billSubscriptionChargeFields
              }
            }
            disconnectionCredits {
              type
              name
              total
              items {
                ...disconnectionCreditFields
              }
            }
          }
        }
        rolledUpCharges {
          type
          name
          total
          items {
            type
            description
            amount
            undiscountedAmount
            rollUpType
            chargeCollection {
              type
              description
              amount
              undiscountedAmount
              charges {
                ...billOneOffChargeFields
              }
            }
          }
        }
        skyBoxOffice {
          type
          name
          total
          items {
            type
            description
            amount
            undiscountedAmount
            bookingDate
            viewingDate
            viewingCard
            offers {
              amount
              description
            }
          }
        }
        talkUsage {
          type
          name
          total
          items {
            type
            description
            amount
            count
          }
        }
      }
    }
  }`;

const billFragments = `
fragment billOneOffChargeFields on BillItemOneOffCharge {
  type
  description
  amount
  category
  transactionDate
  undiscountedAmount
  offers {
    amount
    description
  }
}
fragment disconnectionCreditFields on BillItemDisconnectionCredit {
  type
  description
  amount
  category
}
fragment billSubscriptionChargeFields on BillItemSubscriptionCharge {
  type
  description
  amount
  category
  undiscountedAmount
  productLineId
  firstTimeBilled
  offers {
    amount
    description
    fromDate
    toDate
  }
}`;

export const initialFetchQuery = `
{
  me {
    parties {
      customerDetails {
        title
        firstName
        lastName
        emailAddresses(primary: true) {
          email
        }
        customerType {
          staff
        }
      }
      portfolio {
        mobile
      }
      billingAccount(type: triplePlay) {
        inventory {
          broadband {
            status
            CRN
            UAN
          }
          tv {
            status,
            skyQCustomer
          }
          talk {
            status
          }
        }
        accountNumber
        UAN
        alias
        eTag
        currency
        status
        subStatus
        reinstateActions {
          code
          description
          amount
          currency
        }
        paymentMethod {
          type
        }
        installationAddress {
          postCode
          houseName
          houseNumber
          street
          town
          county
          locality
        }
        billingAddress {
          postCode
          houseName
          houseNumber
          street
          town
          county
          locality
        }
        previousBills: bills(n:${PreviousBillingAmount}) {
          billingId
          statementDate
          fromDate
          toDate
        }
        latestBill: bills(latest: true) {
          ${triplePlayBillContents}
        }
      }
    }
  }
}
  ${billFragments}
`;

export const changeBillingAccountMutation = `
  mutation updateCustomerAccount($eTag: String!, $account: AccountInputType!) {
    updateAccount(eTag: $eTag, account: $account) {
      success
      errorCode
      eTag
    }
  }`;

export const tripleplayBillForId = `
  query TripleplayBillForId($accountNumber: String!, $billingId: String!) {
    me {
      parties {
        billingAccount(id: $accountNumber) {
          bills(billingId: $billingId) {
            ${triplePlayBillContents}
          }
        }
      }
    }
  }
  ${billFragments}
`;

export const getPredictedBills = `
  query getPredictedBills($accountNumber: String!) {
    me {
      parties {
        billingAccount(id: $accountNumber) {
          predictedBills {
            ${triplePlayBillContents}
          }
        }
      }
    }
  }
  ${billFragments}
`;

export const checkLoggedInQuery = `
  {
    me {
      username
    }
  }
`;

export const getPaperlessBillSettings = `
query getPaperlessBillSettings($accountNumber: String!) {
  me {
    parties {
      billingAccount(id: $accountNumber) {
        preferences {
          etag
          paperBillEnabled {
            enabled
            charges {
              amount
              currency
            }
          }
        }
      }
    }
  }
}
`;

const callsFragment = `
details {
  ... on BillDetailsTriplePlay {
    sections {
      talkUsage {
        total
        items {
          description
          calls {
            originatingNumber
            callList {
              amount
              numberCalled
              duration
              destination
              callDateTime
            }
          }
        }
      }
    }
  }
}`;

export const getCallsForBillingId = `
query getCallsForBillingId($accountNumber: String!, $billingId: String!) {
  me {
    parties {
      billingAccount(id: $accountNumber) {
        bills(billingId: $billingId) {
          ${callsFragment}
        }
      }
    }
  }
}
`;

export const getCallsForPredictedBill = `
query getCallsForPredictedBill($accountNumber: String!) {
  me {
    parties {
      billingAccount(id: $accountNumber) {
        predictedBills(billNo: 1) {
          ${callsFragment}
        }
      }
    }
  }
}
`;

export const getReinstateActions = `
query getReinstateActions($accountNumber: String!) {
  me {
    parties {
      billingAccount(id: $accountNumber) {
        status
        subStatus
        reinstateActions {
          code
          description
          amount
          currency
        }
      }
    }
  }
}
`;


export const attemptReinstateCustomer = `
mutation reinstateAccount($accountNumber: String!) {
  reinstateAccountEco(accountNumber: $accountNumber) {
    status
    subStatus
    productOrders {
      productId
      action
      status
      timescale
    }
    reinstateActions {
      code
      description
      amount
      currency
    }
  }
}
`;


export const getCallChargeAlertThreshold = `
query getCallChargeAlertThreshold($accountNumber: String!) {
  me {
    parties {
      billingAccount(id: $accountNumber) {
        callChargeAlertAmount
      }
    }
  }
}
`;

export const updateCallChargeAlertThreshold = `
mutation updateCallChargeAlertThreshold($accountNumber: String!, $amount: String!){
  updateCallChargeAlertThreshold(accountNumber: $accountNumber, amount: $amount) {
    success
  }
}
`;
