function replaceWhiteSpace(query) {
    return query.replace(/\s+/g, ' ');
}

const mobileBillFragments = `fragment billSectionPeriod on BillSectionPeriod {
    fromDate
    toDate
    subscriptionCharges {
        items {
            amount
            description
            offers {
                amount
                description
            }
            undiscountedAmount
        }
    }
    disconnectionCredits {
        items {
            amount
            description
        }
    }
    total
    prorated
}
fragment billItem on BillItem {
    amount
    description
}
fragment billDescription on BillDescription {
    billingId
    paymentDueDate
    fromDate
    statementDate
    toDate
    firstBillIssued
    total
    details {
        ... on BillDetailsMobile {
            prorated
            amountCarriedFwdFromPrevBill
            upfrontPayments {
                total
            }
            sections {
                additionalProductsAndServices {
                    items {
                        amount
                        description
                        offers {
                            amount
                            description
                        }
                        undiscountedAmount
                    }
                    total
                }
                handsets {
                    items {
                        amount
                        description
                        offers {
                            amount
                            description
                        }
                        transactionDate
                        undiscountedAmount
                    }
                    name
                    total
                }
                handsetsTotal
                tablets {
                    items {
                        amount
                        description
                        offers {
                            amount
                            description
                        }
                        transactionDate
                        undiscountedAmount
                    }
                    name
                    total
                }
                tabletsTotal
                accessories {
                    items {
                        amount
                        description
                        offers {
                            amount
                            description
                        }
                        transactionDate
                        undiscountedAmount
                    }
                    name
                    total
                }
                accessoriesTotal
                additionalCharges {
                    items {
                        amount
                        description
                        offers {
                            description
                        }
                        transactionDate
                        undiscountedAmount
                    }
                    total
                }
                adjustments {
                    items {
                        amount
                        description
                        transactionDate
                    }
                    total
                }
                payments {
                    items {
                        amount
                        description
                        transactionDate
                    }
                    total
                }
                simsSummary {
                    sims {
                        mobilePhoneNumber
                        friendlyName
                        total
                        sections {
                            additionalProductsAndServices {
                                items {
                                    amount
                                    description
                                    transactionDate
                                }
                                total
                            }
                            priorOrPartialPeriods {
                                periods {
                                    ...billSectionPeriod
                                }
                            }
                            currentPeriod {
                                ...billSectionPeriod
                            }
                            mobileUsage {
                                total
                                voice {
                                    total
                                    usageGroups {
                                        total
                                        description
                                        items {
                                            ...billItem
                                            date
                                            destinationNumber
                                            destinationPlace
                                            duration
                                            originationNumber
                                            originationPlace
                                            time
                                            callDirection
                                        }
                                    }
                                }
                                sms {
                                    total
                                    usageGroups {
                                        total
                                        description
                                        items {
                                            ...billItem
                                            date
                                            destinationNumber
                                            destinationPlace
                                            originationNumber
                                            originationPlace
                                            time
                                            callDirection
                                        }
                                    }
                                }
                                mms {
                                    total
                                    usageGroups {
                                        total
                                        description
                                        items {
                                            ...billItem
                                            date
                                            destinationNumber
                                            destinationPlace
                                            originationNumber
                                            originationPlace
                                            time
                                            callDirection
                                        }
                                    }
                                }
                                data {
                                    total
                                    usageGroups {
                                        total
                                        description
                                        items {
                                            ...billItem
                                            date
                                            dataAmount(unit: B)
                                            roamingLocation
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}`;

const mobilePaymentPlans = `
    mobile {
        paymentPlans {
            agreementRef
            status
        }
    }
`;

const mobileBill = replaceWhiteSpace(`
${mobileBillFragments}

query MobileBill($billingId: String!) {
    me {
        billingAccount(type: mobile) {
            bills(billingId: $billingId) {
                ...billDescription
            }
        }
        ${mobilePaymentPlans}
    }
}`);

const mobileBillListAndLastMobileBill = replaceWhiteSpace(`
${mobileBillFragments}
query MobilBillListAndLast {
    me {
        billingAccount(type: mobile) {
            accountNumber
            lastBills: bills(n: 1) {
                ...billDescription
            }
            bills {
                billingId
                paymentDueDate
                statementDate
            }
            withinOnboardingPeriod
        }
        ${mobilePaymentPlans}
    }
}`);

const mobileBillListAndNextMobileBill = replaceWhiteSpace(`
${mobileBillFragments}
query MobileBillListAndNext {
    me {
        billingAccount(type: mobile) {
            accountNumber
            predictedBills(nextBill: true) {
                ...billDescription
            }
            lastBills: bills(n: 1) {
                details {
                    ... on BillDetailsMobile {
                        amountCarriedFwdToNextBill
                        sections {
                            pendingCollections {
                                total
                            }
                        }
                    }
                }
            }
            bills {
                billingId
                paymentDueDate
                statementDate
            }
            withinOnboardingPeriod
        }
        ${mobilePaymentPlans}
    }
}`);

const mobileAccountStatus = replaceWhiteSpace(`query MobileBillAccountStatus {
    me {
        billingAccount {
          billingAddress {
            houseNumber
            houseName
            street
            town
            county
            postCode
          }
          paymentMethod {
            type
          }
        }
        mobile {
            accountNumber
            status
            subStatus
            reinstateActions {
              code
              description
              amount
              currency
            }
            sims {
                status
                subStatus
            }
            handsetsOnly,
            hasHandsets
        }
        customerDetails {
            title
            firstName
            lastName
        }
    }
}`);

const currentPaymentDueDay = `
query getCurrentPaymentDueDate($accountNumber: String!) {
  me {
    parties {
      billingAccount(id:$accountNumber) {
        preferences {
          etag
          paymentDueDay {
            currentDay
            canUpdate
          }
        }
      }
    }
  }
}`;

const changePaymentDueDay = `mutation ChangePaymentDueDay($accountNumber:String!,$eTag:String!,$dueDay:Int!) {
    changePaymentDueDay(accountNumber:$accountNumber,eTag:$eTag,dueDay:$dueDay) {
        nextPaymentDueDay
        nextPaymentDueDate
    }
}`;

const mobileBillNextMobileBill = `query MobileBillNext {
    me {
        billingAccount(type: mobile) {
            predictedBills(nextBill: true) {
                firstBillIssued
                total
                statementDate
            }
            preferences {
                etag
                paperBillEnabled {
                    enabled
                }
            }
        }
        ${mobilePaymentPlans}
    }
}`;

module.exports = {
    mobileAccountStatus,
    mobileBill,
    mobileBillListAndNextMobileBill,
    mobileBillListAndLastMobileBill,
    currentPaymentDueDay,
    changePaymentDueDay,
    mobileBillNextMobileBill
};
